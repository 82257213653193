import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./MirrorQuestionsOverview.module.scss";
import { MirrorQuestionsOverviewProps } from "./MirrorQuestionsOverview.props";

export const MirrorQuestionsOverview = ({ mirrorQuestions }: MirrorQuestionsOverviewProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.MirrorQuestionsOverview}>
			{!mirrorQuestions.length && (
				<p className="small mb-0 color-support">{t("FILES.MIRROR_QUESTIONS.EMPTY.MESSAGE")}</p>
			)}

			{mirrorQuestions?.map((mirrorQuestion, idx) => {
				return (
					<div key={idx} className={styles.Question}>
						{mirrorQuestion}
					</div>
				);
			})}
		</div>
	);
};
