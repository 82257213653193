import { useMatomo } from "@datapunt/matomo-tracker-react";
import * as Sentry from "@sentry/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";

import { Loader } from "@Core/components/UI";
import { DataLayer } from "@Core/constants/ENUMS";
import { useCoreStores } from "@Core/stores";

import { Page } from "@PNI/components/Layouts";
import { IArticle, PreprArticleService } from "@PNI/services";

import { ArticlesDetailBody, ArticlesDetailFooter, ArticlesDetailHeader } from "./internal/components";

const ArticlesDetailPage = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { id } = useParams<any>();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [article, setArticle] = useState<IArticle>();
	const { trackEvent } = useMatomo();
	const { UserStore } = useCoreStores();

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);
				setError(false);
				setArticle(undefined);

				const response = await PreprArticleService.getArticle(id);

				if (response && response.Artikel) {
					setArticle(response.Artikel);

					trackEvent({
						name: response.Artikel.title,
						action: DataLayer.ARTICLE_READ,
						category: String(UserStore.getCompanyId),
					});

					trackEvent({
						name: response.Artikel.theme_pni[0].body,
						action: DataLayer.THEME_READ,
						category: String(UserStore.getCompanyId),
					});

					response.Artikel.keywords_pni.map((keyword) => {
						trackEvent({
							name: keyword.body,
							action: DataLayer.KEYWORD_READ,
							category: String(UserStore.getCompanyId),
						});
					});
				} else {
					history.push("/");
				}
			} catch (e) {
				setError(true);
				Sentry.captureException(error);
			} finally {
				setLoading(false);
			}
		})();
	}, [id]);

	const breadcrumbs = [{ to: `/artikelen`, name: t("ARTICLES.TITLE") }, { name: article?.title }];

	return (
		<Page breadcrumbs={breadcrumbs}>
			{loading && <Loader width={50} color="#56bbad" className="mt-md" />}

			{error && <div className="col-lg-12 mb-md">{t("DASHBOARD.ARTICLES.ERROR")}</div>}

			{article && (
				<>
					<ArticlesDetailHeader article={article} />

					<ArticlesDetailBody article={article} />

					<ArticlesDetailFooter article={article} />
				</>
			)}
		</Page>
	);
};

export default observer(ArticlesDetailPage);
