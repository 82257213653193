import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Modal } from "@Core/components/Layouts";
import { Block, Button, Circle } from "@Core/components/UI";
import { useDialog } from "@Core/hooks";

import { usePNIStores } from "@PNI/stores";

export const DashboardOmissionExportResults = observer(() => {
	const { t } = useTranslation();
	const { isVisible, toggle } = useDialog();
	const { ExportStore } = usePNIStores();

	const downloadCSV = async () => {
		toggle();

		try {
			await ExportStore.getCSV();
			toggle();
			toast.success(t("DASHBOARD.OMISSION_EXPORT.FILES.SUCCESS_DOWNLOAD"));
		} catch (e) {
			toggle();
			toast.warning(t("DASHBOARD.OMISSION_EXPORT.FILES.ERROR_DOWNLOAD"));
		}
	};

	return (
		<Block name={t("DASHBOARD.OMISSION_EXPORT.FILES.TITLE")}>
			<Circle quaternary dashed r={100} x={-3} y={35} />
			<Circle tertiary dashed r={84} x={75} y={0} />
			<Circle primary r={60} x={50} y={102} />

			{ExportStore.hasLoaded ? (
				<>
					<p>
						<strong>
							{t("DASHBOARD.OMISSION_EXPORT.FILES.REGISTRATIONS", { count: ExportStore.registrations })}
						</strong>
					</p>

					<p>{t("DASHBOARD.OMISSION_EXPORT.FILES.DESCRIPTION")}</p>

					<Button
						disabled={ExportStore.registrations === 0}
						className="pl-0"
						tertiary
						link
						iconLeft="fa-table"
						onClick={downloadCSV}
					>
						{t("DASHBOARD.OMISSION_EXPORT.FILES.BUTTON_DOWNLOAD")}
					</Button>
				</>
			) : (
				<p>{t("DASHBOARD.OMISSION_EXPORT.FILES.SELECT_FILTER")}</p>
			)}

			<Modal title={t("DASHBOARD.OMISSION_EXPORT.FILES.DOWNLOAD_TITLE")} isVisible={isVisible}>
				{t("DASHBOARD.OMISSION_EXPORT.FILES.DOWNLOAD_DESCRIPTION")}
			</Modal>
		</Block>
	);
});
