import i18next from "i18next";
import merge from "lodash.merge";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next/";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import "./App.scss";

import { Platform } from "@Core/constants/ENUMS";
import { useReactron, useSentry } from "@Core/hooks";
import data from "@Core/locales";
import { MaintenancePage } from "@Core/pages";
import { useCoreStores } from "@Core/stores";

import dataLNI from "@LNI/locales";
import dataOOM from "@OOM/locales";
import dataPNI from "@PNI/locales";

i18next.init({
	lng: "nl",
	resources: data,
	debug: false,
	react: {
		wait: false,
		bindI18n: "languageChanged loaded",
		nsMode: "default",
	},
});

const Maintenance = () => {
	const [hasLoaded, setHasLoaded] = useState(false);
	const Core = useCoreStores();

	useSentry();
	useReactron();

	useEffect(() => {
		const getPlatformLocales = (platform: Platform) => {
			switch (platform) {
				case Platform.PNI:
					return dataPNI;
				case Platform.LNI:
					return dataLNI;
				case Platform.OOM:
					return dataOOM;
			}
		};

		i18next.loadResources(() => merge(data, getPlatformLocales(Core.getPlatform)));

		setHasLoaded(true);
	}, [Core.getPlatform]);

	return (
		<I18nextProvider i18n={i18next}>
			{hasLoaded && (
				<BrowserRouter>
					<Switch>
						<Route path="/" exact component={MaintenancePage} />
						<Redirect to="/" />
					</Switch>
				</BrowserRouter>
			)}
		</I18nextProvider>
	);
};

export default observer(Maintenance);
