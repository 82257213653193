import { flow, Instance, isValidReference, types } from "mobx-state-tree";

import { AssignmentModel, ChallengeModel } from "@LNI/models";
import { ChallengeService } from "@LNI/services";

const ChallengeStore = types
	.model({
		challenges: types.array(ChallengeModel),
		challenge: types.maybeNull(types.safeReference(ChallengeModel)),
		assignment: types.maybeNull(types.safeReference(AssignmentModel)),
	})
	.actions((self) => ({
		load: flow(function* () {
			const challenges = yield ChallengeService.getChallenges();
			self.challenges = challenges;
		}),

		getChallenge: flow(function* (challange: string, assignment?: string) {
			self.assignment = null;

			self.challenge = challange as any;

			if (!isValidReference(() => self.challenge, true)) {
				self.challenge = null;
				throw new Error();
			}

			if (self.challenge?.assignments.length === 0) {
				const response = yield ChallengeService.getAssignments(self.challenge.id);
				self.challenge.assignments = response;
			}

			if (self.challenge && self.challenge?.assignments && assignment) {
				self.assignment = assignment as any;

				if (!isValidReference(() => self.assignment, true)) {
					self.assignment = null;
					throw new Error();
				}
			}
		}),
	}))
	.views((self) => ({
		get hasChallenges(): boolean {
			return self.challenges.length > 0;
		},
	}));

export interface ChallengeStoreInstance extends Instance<typeof ChallengeStore> {}

export default ChallengeStore;
