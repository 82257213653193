import { Document, Font, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { useTranslation } from "react-i18next/";
import LazyLoad from "react-lazyload";
import slugify from "slugify";

import AzoSansBold from "@Core/assets/fonts/AzoSansBold.ttf";
import AzoSansRegular from "@Core/assets/fonts/AzoSansRegular.ttf";
import image_questionnaire_completed from "@Core/assets/images/illustrations/questionnaire_completed.jpg";
import { Block, Button } from "@Core/components/UI";
import { Platform } from "@Core/constants/ENUMS";
import { UserQuestionnaireStepInstance } from "@Core/models";
import { useCoreStores } from "@Core/stores";
import { parseHTML } from "@Core/utilities";

import LNI from "@LNI/assets/images/logo.png";
import OOM from "@OOM/assets/images/logo.png";
import PNI from "@PNI/assets/images/logo.png";

import styles from "./QuestionnaireCompleted.module.scss";

type Props = {
	title: string;
};

const PDF = () => {
	const { getPlatform, QuestionnaireStore } = useCoreStores();

	Font.register({
		family: "Azo Sans",
		fonts: [{ src: AzoSansRegular }, { src: AzoSansBold, fontWeight: 700 }],
	});

	const styles = StyleSheet.create({
		Page: {
			fontFamily: "Azo Sans",
			paddingVertical: 90,
			paddingHorizontal: 20,
			backgroundColor: "#fafbff",
		},

		Header: {
			position: "absolute",
			top: 30,
			left: 40,
			width: "auto",
			height: 35,
		},

		Footer: {
			position: "absolute",
			bottom: "30px",
			left: 0,
			right: 0,
			color: "#8d9eb8",
			fontSize: "10px",
			fontWeight: 300,
			textAlign: "center",
		},

		QuestionnaireTitle: { paddingLeft: 20, color: "#2b4982", fontSize: "16px", fontWeight: 700, marginBottom: 5 },
		QuestionnaireInformation: { paddingLeft: 20, color: "#2e384d", fontSize: "12px", marginBottom: 30 },

		Question: { borderRadius: "8px", backgroundColor: "#fff", marginBottom: 20, padding: 20 },
		QuestionTitle: { color: "#2e384d", fontSize: "14px", fontWeight: "bold" },
		QuestionSubtitle: { marginTop: 5, color: "#8d9eb8", fontSize: "10px" },
		QuestionAnswer: { lineHeight: 1.4, marginTop: 10, color: "#2b4982", fontSize: "12px" },
	});

	const logo = () => {
		switch (getPlatform) {
			case Platform.PNI:
				return PNI;
			case Platform.LNI:
				return LNI;
			case Platform.OOM:
				return OOM;
		}
	};

	if (QuestionnaireStore.questionnaire?.progressed_steps)
		return (
			<Document>
				<Page size="A4" style={styles.Page} wrap>
					<Image fixed style={styles.Header} src={logo()} />

					<Text
						fixed
						style={styles.Footer}
						render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
					/>

					<Text style={styles.QuestionnaireTitle}>
						{QuestionnaireStore.questionnaire.questionnaire.title}
					</Text>

					<Text style={styles.QuestionnaireInformation}>
						{QuestionnaireStore.questionnaire.questionnaire.text}
					</Text>

					{QuestionnaireStore.questionnaire?.progressed_steps.map((step: UserQuestionnaireStepInstance) => {
						const answers = step.question?.getAnswerLabel();

						if (answers) {
							return (
								<View key={step.id} style={styles.Question} wrap={false}>
									<Text style={styles.QuestionTitle}>
										{step.question?.title.replace(/(\*\*)/g, "") || ""}
									</Text>

									<Text style={styles.QuestionSubtitle}>{step.question?.text || ""}</Text>

									{answers.map((answer: any, key: number) => {
										return (
											<Text key={key} style={styles.QuestionAnswer}>
												{`${answers.length > 1 ? "• " : ""} ${answer}`}
											</Text>
										);
									})}
								</View>
							);
						}
					})}
				</Page>
			</Document>
		);

	return <></>;
};

const QuestionnaireCompleted = ({ title }: Props) => {
	const { t } = useTranslation();
	const { QuestionnaireStore } = useCoreStores();

	if (QuestionnaireStore.questionnaire?.questionnaire.thanks_text) {
		return (
			<Block name={t("DEFAULT.COMPLETED")}>
				<div className={styles.QuestionnaireCompleted}>
					<div className={styles.Content}>
						<h4>{title}</h4>
						{parseHTML(QuestionnaireStore.questionnaire?.questionnaire.thanks_text)}

						{QuestionnaireStore.questionnaire?.questionnaire.allow_download && (
							<PDFDownloadLink
								document={<PDF />}
								fileName={`${slugify(
									QuestionnaireStore.questionnaire?.questionnaire.title.toLowerCase(),
								)}.pdf`}
							>
								{({ loading }) => (
									<Button primary className="mt-md">
										{loading ? "..." : t("SHARED.DOWNLOAD_RESULTS")}
									</Button>
								)}
							</PDFDownloadLink>
						)}
					</div>

					<LazyLoad>
						<div className={styles.Image}>
							<img src={image_questionnaire_completed} alt={title} />
						</div>
					</LazyLoad>
				</div>
			</Block>
		);
	}

	return <></>;
};

export default QuestionnaireCompleted;
