import groupBy from "lodash.groupby";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IFieldProps } from "@Core/interfaces";
import { UserQuestionnaireAnswerInstance, UserQuestionnaireQuestionInstance } from "@Core/models";

import styles from "./SingleDropdown.module.scss";

type Props = {
	id: string;
	name: string;
	field: IFieldProps;

	disabled: boolean;
	question: UserQuestionnaireQuestionInstance;
	placeholder?: string;
};

const SingleDropdown = ({ field, disabled, question }: Props) => {
	const { t } = useTranslation();

	const nonGroupedAnswers = useMemo(() => {
		return question.answers.filter((answer) => !answer.group);
	}, [question.answers]);

	const groupedAnswers = useMemo(() => {
		return groupBy(
			question.answers.filter((answer) => !!answer.group),
			"group",
		);
	}, [question.answers]);

	const groupedAnswersGroups = Object.keys(groupedAnswers);

	return (
		<div className={styles.Select}>
			<select disabled={disabled} id={`Q${question.id}`} {...field}>
				<option disabled value="">
					{t("FORMS.DROPDOWN_DEFAULT")}
				</option>

				{nonGroupedAnswers.length > 0 &&
					nonGroupedAnswers.map((answer) => (
						<option key={answer.id} value={answer.id}>
							{answer.text}
						</option>
					))}

				{groupedAnswersGroups.length > 0 &&
					groupedAnswersGroups.map((groupedAnswersGroup) => {
						return (
							<optgroup key={groupedAnswersGroup} label={groupedAnswersGroup}>
								{groupedAnswers[groupedAnswersGroup].map((groupedAnswer) => {
									return (
										<option key={groupedAnswer.id} value={groupedAnswer.id}>
											{groupedAnswer.text}
										</option>
									);
								})}
							</optgroup>
						);
					})}
			</select>
		</div>
	);
};

export default SingleDropdown;
