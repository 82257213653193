import { Instance, SnapshotIn, types } from "mobx-state-tree";

export const TextModel = types.model({
	id: types.identifier,
	label: types.string,
	body: types.string,
	format: types.string,
});

export interface TextInstance extends Instance<typeof TextModel> {}
export type TextSnapshot = SnapshotIn<typeof TextModel>;
