import { flatten } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next/";

import { CardRow } from "@Core/components/UI";
import { FileInstance } from "@Core/models";
import { formatDate } from "@Core/utilities";

type Props = {
	files: FileInstance[];
	length?: number;
};

const FileList = ({ files, length }: Props) => {
	const { t } = useTranslation();

	const rows = useMemo(
		() =>
			flatten(
				files.map((file: FileInstance) => {
					const data = [
						`${t("FILES.DURATION")} ${t("SHARED.DAYS", { count: file.duration })}`,
						t("FILES.RECEIVED", { date: formatDate("" + file.created_at) }),
					];

					if (file.supervisor_questionnaire) {
						return (
							<CardRow
								key={file.id + file.supervisor_questionnaire.id}
								largeIcon
								icon="fa-folder-open"
								title={`${file.employee?.getName || "-"} - ${
									file.supervisor_questionnaire.questionnaire?.title
								}`}
								dataStrings={data}
								to={`/dossiers/${file.id}/${file.supervisor_questionnaire.user_questionnaire?.id}`}
							/>
						);
					}
				}),
			),
		[files],
	);

	if (files.length > 0) {
		return <>{!!length ? rows.slice(0, length) : rows}</>;
	} else {
		return <CardRow icon="fa-file" small description={"" + t("FILES.LOAD_NONE")} dotted disabledLight />;
	}
};

export default observer(FileList);
