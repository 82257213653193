import { flow, Instance, types } from "mobx-state-tree";

import { CoreRootStore } from "@Core/stores";

import { CompanyRoleModel, CompanyRoleSnapshot, EligibleRoleInstance, EligibleRoleModel } from "@OOM/models";
import RoleService from "@OOM/services/RoleService";

const RoleStore = types
	.model({
		companyRoles: types.array(CompanyRoleModel),
		selectedRole: types.maybe(types.reference(CompanyRoleModel)),
		eligibleRoles: types.array(EligibleRoleModel),
		isLoading: false,
		isFinished: false,
	})
	.actions((self) => {
		const actions: { [key: string]: any } = {
			load: flow(function* () {
				// reset
				self.selectedRole = undefined;
				self.companyRoles.replace([]);
				self.eligibleRoles.replace([]);

				const companyId = CoreRootStore.UserStore.getCompanyId;

				if (companyId) {
					const response = yield Promise.all([
						RoleService.getAllCompanyRoles(companyId),
						RoleService.getAllEligableRoles(companyId),
					]);

					if (response[0] && response[0].length > 0) {
						self.companyRoles = response[0];
					}

					if (response[1] && response[1].length > 0) {
						self.eligibleRoles = response[1];
					}
				}
			}),

			loadRole: flow(function* (companyRoleId: number) {
				if (self.companyRoles.length <= 0) {
					yield actions.load();
				}

				const role = self.companyRoles.find((role) => role.id === companyRoleId);

				if (!role) return;

				self.selectedRole = role;

				const response = yield RoleService.getRole(companyRoleId);

				if (response) {
					role.invite_tokens = response.invite_tokens;
				}
			}),

			setCompanyRoles: (roles: CompanyRoleSnapshot[]) => {
				self.companyRoles.replace(roles.map((role) => CompanyRoleModel.create(role)));
			},
			setEligibleRoles: (roles: EligibleRoleInstance[]) => {
				self.eligibleRoles.replace(roles);
			},
		};

		return actions;
	})
	.views((self) => ({
		getById: (companyRoleId: number) => {
			return self.companyRoles.find((role) => role.id === companyRoleId);
		},
	}));

export interface RoleStoreInstance extends Instance<typeof RoleStore> {}

export default RoleStore;
