import axios from "@Core/axios";

class InboxService {
	public getMessages = async () => {
		const response = await axios.get("inbox");

		return response.data;
	};

	public readMessage = async (messageId: number) => {
		const response = await axios.post(`inbox/${messageId}`);

		return response.data;
	};
}

export default new InboxService();
