import cn from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { useHistory } from "react-router-dom";

import { Icon, Step } from "@Core/components/UI";
import { capitalize } from "@Core/utilities";

import { Button } from "../Button/Button";
import styles from "./CardRow.module.scss";

type Props = {
	icon?: string;
	type?: string;

	title?: string;
	description?: string;

	to?: string;
	onClick?: () => void;

	small?: boolean;
	large?: boolean;
	largeIcon?: boolean;

	dotted?: boolean;
	disabled?: boolean;
	disabledLight?: boolean;
	finished?: boolean;

	className?: string;

	dataStrings?: string[];
	checkmarkText?: {
		title: string;
		icon: string;
	};
	buttonPrimary?: {
		title: string;
		onClick: () => void;
	};
	buttonLink?: {
		title: string;
		onClick: () => void;
	};
	buttonPrimaryLast?: {
		title: string;
		onClick: () => void;
	};
	step?: {
		done: number;
		total: number;
	};
};

const CardRow = ({
	icon,
	type,
	title,
	description,
	to,
	onClick,
	small,
	large,
	largeIcon,
	disabled,
	disabledLight,
	finished,
	dotted,
	dataStrings,
	checkmarkText,
	buttonPrimary,
	buttonLink,
	buttonPrimaryLast,
	step,
}: Props) => {
	const history = useHistory();

	const classes = cn([styles.Wrapper], {
		[styles.Small]: small,
		[styles.Default]: !small && !large,
		[styles.Large]: large,
		[styles.Disabled]: disabled,
		[styles.DisabledLight]: disabledLight,
		[styles.Finished]: finished,
		[styles.Dotted]: dotted,
	});

	const handleClick = () => {
		if (!disabled && !disabledLight) {
			if (to) {
				history.push(to);
			}

			if (onClick) {
				onClick();
			}
		}
	};

	return (
		<div onClick={handleClick} className={`${classes}`}>
			{(icon || type) && (
				<div className={`${styles.Column} align-self-center`}>
					{icon && <Icon success={finished} large={large || largeIcon} small={small} icon={`far ${icon}`} />}
					{type && <div className={styles.Type}>{type}</div>}
				</div>
			)}

			<div className={`${styles.Column}`}>
				<div className={styles.Content}>
					{title && <h4 className={styles.Title} dangerouslySetInnerHTML={{ __html: title }} />}
					{description && <div className={styles.Description}>{description}</div>}
				</div>
			</div>

			<div className="mr-auto" />
			{dataStrings &&
				dataStrings.map((string, id) => {
					return (
						<div
							className={`${styles.Data} text-center nowrap mb-md-xs`}
							key={id}
							dangerouslySetInnerHTML={{ __html: string }}
						/>
					);
				})}
			{step && (
				<div className={styles.Step}>
					<Step step={step.done} steps={step.total} />
				</div>
			)}
			{checkmarkText && (
				<div className={styles.Button}>
					<Button iconLeft={checkmarkText.icon}>{checkmarkText.title}</Button>
				</div>
			)}
			{buttonPrimary && (
				<div className={styles.Button}>
					<Button
						onClick={buttonPrimary.onClick}
						primary={finished}
						tertiary={!finished}
						iconRight="fa-long-arrow-right"
					>
						<span dangerouslySetInnerHTML={{ __html: buttonPrimary.title }} />
					</Button>
				</div>
			)}
			{buttonLink && (
				<div className={`${styles.Button} `}>
					<Button
						onClick={buttonLink.onClick}
						primary={finished}
						tertiary={!finished}
						link
						iconRight="fa-long-arrow-right"
					>
						{buttonLink.title}
					</Button>
				</div>
			)}
			{buttonPrimaryLast && (
				<div className={styles.Button}>
					<Button
						onClick={buttonPrimaryLast.onClick}
						primary={finished}
						tertiary={!finished}
						iconRight="fa-long-arrow-right"
					>
						<span dangerouslySetInnerHTML={{ __html: buttonPrimaryLast.title }} />
					</Button>
				</div>
			)}
		</div>
	);
};

export default observer(CardRow);
