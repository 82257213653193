import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { UserQuestionnaireStatus } from "@Core/constants/ENUMS";

import { BaseModel } from "../BaseModel";
import { QuestionnaireModel } from "../QuestionnaireModel";

export const UserQuestionnairesAdditionalModel = BaseModel.props({
	id: types.identifierNumber,
	questionnaire_id: types.number,
	aom_file_questionnaire: types.maybeNull(
		types.model({
			aom_file_id: types.number,
			user_questionnaire: types.model({
				id: types.number,
				status: types.enumeration<UserQuestionnaireStatus>(Object.values(UserQuestionnaireStatus)),
			}),
		}),
	),
	questionnaire: types.maybeNull(QuestionnaireModel),
});

export interface UserQuestionnairesAdditionalInstance extends Instance<typeof UserQuestionnairesAdditionalModel> {}
export type UserQuestionnairesAdditionalSnapshot = SnapshotIn<typeof UserQuestionnairesAdditionalModel>;
