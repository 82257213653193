import React from "react";

import { IFieldProps } from "@Core/interfaces";

import styles from "./Text.module.scss";

type Props = {
	id: string;
	field: IFieldProps;

	disabled?: boolean;
	placeholder?: string;
	icon?: string;
};

const Text = ({ id, field, disabled, placeholder, icon }: Props) => {
	return (
		<div className={styles.Field}>
			<input
				id={id}
				type="text"
				className={styles.Input}
				{...field}
				disabled={disabled}
				placeholder={placeholder}
			/>
			{icon && <i className={`${styles.Icon} far fa-${icon}`}></i>}
		</div>
	);
};

export default Text;
