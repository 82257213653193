import { subDays } from "date-fns";
import { applySnapshot, flow, Instance, types } from "mobx-state-tree";

import { ExportType } from "@Core/constants/ENUMS";

import { ExportService } from "@PNI/services";

const ExportStore = types
	.model("ExportStore", {
		type: types.maybe(types.enumeration<ExportType>(Object.values(ExportType))),
		companies: types.array(types.string),
		from: subDays(new Date(), 7),
		to: new Date(),

		registrations: 0,

		hasLoaded: false,
	})
	.actions((self) => ({
		getRegistrations: flow(function* () {
			if (self.companies && self.companies.length > 0 && self.from && self.to) {
				const response = yield ExportService.getRegistrations(
					self.type || ExportType.FILES,
					self.companies,
					self.from,
					self.to,
				);

				if (response) {
					self.registrations = response.registration_count;
					self.hasLoaded = true;
				}
			}
		}),

		getCSV: flow(function* () {
			yield ExportService.getCSV(self.type || ExportType.FILES, self.companies, self.from, self.to);
		}),

		setCompanies(companies: string[]) {
			applySnapshot(self.companies, companies);
		},

		setType(type: ExportType) {
			self.type = type;
		},

		setTo(to: Date) {
			self.to = to;
		},

		setFrom(from: Date) {
			self.from = from;
		},
	}));

export interface ExportStoreInstance extends Instance<typeof ExportStore> {}

export default ExportStore;
