import cn from "classnames";
import { toDate } from "date-fns";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import LazyLoad from "react-lazyload";
import { toast } from "react-toastify";

import moment from "@Core/assets/images/illustrations/moment.png";
import socks from "@Core/assets/images/illustrations/socks.png";
import Form from "@Core/components/Form";
import { Modal } from "@Core/components/Layouts";
import { Block, Button, Circle, Countdown, Step } from "@Core/components/UI";
import { useDialog } from "@Core/hooks";
import Yup from "@Core/validation";

import { useLNIStores } from "@LNI/stores";

import styles from "./Moment.module.scss";

const MomentNone = () => {
	const { t } = useTranslation();
	return (
		<>
			<div className={styles.Wrapper}>
				<h4>{t("MOMENTS.NO_MOMENT")}</h4>

				<LazyLoad>
					<img src={moment} alt={t("MOMENTS.CONTINUOUS_LEARNING")} />
				</LazyLoad>

				<Circle primary dashed x={-10} y={70} r={39} />

				<Circle secondary x={100} y={60} r={48} />
				<Circle tertiary dashed x={105} y={105} r={52} />
			</div>
		</>
	);
};

const MomentPreferences = () => {
	const { t } = useTranslation();
	const { isVisible, toggle } = useDialog();
	const { MomentStore } = useLNIStores();

	const onSubmit = async (values: { moment_interval: string }) => {
		await MomentStore.setPreferences(values.moment_interval);
		await MomentStore.getMoment();
	};

	const onSuccess = () => {
		toggle();
		toast.success(t("MOMENTS.SIGNED_UP"));
	};

	const getPopup = () => {
		const formOptions = {
			"": t("FORMS.VALUES_MOMENTS.SELECT"),
			two_weekly: t("FORMS.VALUES_MOMENTS.BI_WEEKLY"),
			monthly: t("FORMS.VALUES_MOMENTS.MONTHLY"),
		};

		return (
			<Modal title={t("MOMENTS.CHOOSE_YOUR_MOMENT")} image={moment} large isVisible={isVisible} hide={toggle}>
				<p>{t("MOMENTS.CHOOSE_MOMENT_DESCRIPTION")}</p>

				<Form.Form
					identifier="MOMENT_FORM"
					initialValues={{ moment_interval: "" }}
					validationSchema={Yup.object().shape({
						moment_interval: Yup.string().required(),
					})}
					onSubmit={onSubmit}
					onSuccess={onSuccess}
				>
					<Form.Item
						id="moment_interval"
						name={t("MOMENTS.CHOOSE_FREQUENCY")}
						render={(props) => <Form.Select {...props} options={formOptions} />}
					/>

					<Button primary type="submit" iconRight="fa-long-arrow-right">
						{t("MOMENTS.SIGNUP")}
					</Button>
				</Form.Form>
			</Modal>
		);
	};

	return (
		<>
			<div className={styles.Wrapper}>
				<h4>{t("MOMENTS.CONTINUOUS_LEARNING_DESCRIPTION")}</h4>

				<LazyLoad>
					<img src={moment} alt={t("MOMENTS.CONTINUOUS_LEARNING")} />
				</LazyLoad>

				<Button className={styles.Button} onClick={toggle} primary iconLeft="fa-thumbs-up">
					{t("MOMENTS.ACCEPT_CONTINUOUS_LEARNING")}
				</Button>

				<Circle primary dashed x={-10} y={70} r={39} />

				<Circle secondary x={100} y={60} r={48} />
				<Circle tertiary dashed x={105} y={105} r={52} />
			</div>

			{getPopup()}
		</>
	);
};

const MomentCurrent = () => {
	const { t } = useTranslation();
	const { MomentStore } = useLNIStores();

	const onCompletion = async () => {
		await MomentStore.getMoment();

		if (MomentStore.moment) {
			toast.success(t("MOMENTS.RECIEVED_NEW_MOMENT"));
		} else {
			toast.error(t("MOMENTS.RECIEVED_NO_MOMENT"));
		}
	};

	if (MomentStore.moment.pivot.created_at && MomentStore.moment.next_moment_date) {
		const from = toDate(new Date(MomentStore.moment.pivot.created_at));
		const to = toDate(new Date(MomentStore.moment.next_moment_date));

		return (
			<>
				<div className={styles.Aspect}>
					<div
						className={cn([styles.Wrapper], {
							[styles.Success]: MomentStore.moment.isPivotDone,
						})}
					>
						<Circle secondary x={-10} y={100} r={120} />
						<Circle secondary x={5} y={70} r={16} />

						<Circle secondary x={100} y={70} r={48} />
						<Circle tertiary dashed x={105} y={64} r={84} />

						<Circle secondary x={95} y={90} r={16} />
						<Circle secondary x={105} y={95} r={16} />

						<div className={styles.Header}>
							<Countdown from={from} to={to} onCompletion={() => onCompletion()} />

							<div className={styles.Points}>
								<strong>10</strong>
								{t("MOMENTS.POINTS")}
							</div>
						</div>

						<h2>{MomentStore.moment.title}</h2>

						<Button
							to={`/momenten/${MomentStore.moment.id}`}
							className={styles.Button}
							primary
							iconLeft={t(`ASSIGNMENT.${MomentStore.moment.type.toUpperCase()}.ICON`)}
						>
							{MomentStore.moment.isDone
								? t(`ASSIGNMENT.${MomentStore.moment.type.toUpperCase()}.AGAIN`)
								: t(`ASSIGNMENT.${MomentStore.moment.type.toUpperCase()}.START`)}
						</Button>
					</div>
				</div>
			</>
		);
	}

	return null;
};

const Moment = () => {
	const { t } = useTranslation();
	const { isVisible, toggle } = useDialog();
	const { MomentStore } = useLNIStores();

	const getPopup = () => (
		<Modal title={t("MOMENTS.REWARD_POPUP_TITLE")} image={socks} large isVisible={isVisible} hide={toggle}>
			<p>{t("MOMENTS.REWARD_POPUP_DESCRIPTION_1")}</p>
			<p>{t("MOMENTS.REWARD_POPUP_DESCRIPTION_2")}</p>
			<p>{t("MOMENTS.REWARD_POPUP_DESCRIPTION_3")}</p>
			<p>{t("MOMENTS.REWARD_POPUP_DESCRIPTION_4")}</p>
		</Modal>
	);

	return (
		<Block name={t("MOMENTS.MY_MOMENT")}>
			{/* Load points when signed up for Mensely Moments */}
			{MomentStore.hasPreferences && (
				<div className={styles.Progress}>
					<Step
						step={
							MomentStore.preferences?.moment_score ? parseInt(MomentStore.preferences?.moment_score) : 0
						}
						steps={100}
						points
					/>

					<div className={styles.HappySocks}>
						<i onClick={toggle} className="far fa-info-circle"></i>
					</div>

					{getPopup()}
				</div>
			)}

			<Block small success={MomentStore.moment?.isDone}>
				<div className={styles.Aspect}>
					{/* Not signed up for Mensely Moments yet... */}
					{!MomentStore.hasPreferences && <MomentPreferences />}

					{/* Signed up for Mensely Moments and does have a moment... */}
					{MomentStore.hasPreferences && MomentStore.hasMoment && MomentStore.moment && <MomentCurrent />}

					{/* Signed up for Mensely Moments and does not have a moment... */}
					{MomentStore.hasPreferences && !MomentStore.hasMoment && <MomentNone />}
				</div>
			</Block>
		</Block>
	);
};

export default observer(Moment);
