import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { AccountType } from "@Core/constants/ENUMS";

export const BaseUserModel = types
	.model({
		id: types.number,
		account_type: types.enumeration<AccountType>(Object.values(AccountType)),
		first_name: types.string,
		last_name: types.string,
		email: types.string,
		bio: types.maybeNull(types.string),
		phone_number: types.string,
		pending_files: types.maybeNull(types.number),
		avatar: types.maybeNull(types.string),
	})
	.views((self) => ({
		get getName() {
			return `${self.first_name} ${self.last_name}`;
		},
	}));

export interface UserInstance extends Instance<typeof BaseUserModel> {}
export type UserSnapshot = SnapshotIn<typeof BaseUserModel>;
