import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseModel } from "./BaseModel";
import { BaseUserModel } from "./BaseUserModel";
import { ContactModel } from "./ContactModel";

export const CompanyModel = BaseModel.props({
	name: types.string,
	slug: types.string,
	hr_can_send_questionnaires: types.maybeNull(types.boolean),
	logo_path: types.string,
	pending_files: types.maybeNull(types.number),
	licence_code: types.maybe(types.string),
	contacts: types.maybeNull(types.array(ContactModel)),
	company_doctors: types.maybeNull(types.array(BaseUserModel)),
	pobs: types.maybeNull(types.array(BaseUserModel)),
	advisors: types.maybeNull(types.array(BaseUserModel)),
	permissions: types.maybeNull(
		types.model({
			lni: false,
			lni_settings: types.maybeNull(
				types.model({
					moments_enabled: false,
					advisor_enabled: false,
				}),
			),
			eni: false,
			eni_settings: types.maybeNull(
				types.model({
					moments_enabled: false,
				}),
			),
			aom: false,
			oom: false,
			pni: false,
			pni_settings: types.maybeNull(
				types.model({
					vos_enabled: false,
				}),
			),
		}),
	),
}).views((self) => ({
	get advisor() {
		if (self.advisors && self.advisors[0]) {
			return self.advisors[0];
		}
	},

	get pob() {
		if (self.pobs && self.pobs[0]) {
			return self.pobs[0];
		}
	},

	get company_doctor() {
		if (self.company_doctors && self.company_doctors[0]) {
			return self.company_doctors[0];
		}
	},

	get getContacts() {
		return [
			...(self.advisors || []),
			...(self.company_doctors || []),
			...(self.pobs || []),
			...(self.contacts || []),
		];
	},
}));

export interface CompanyInstance extends Instance<typeof CompanyModel> {}
export type CompanySnapshot = SnapshotIn<typeof CompanyModel>;
