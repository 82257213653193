import React from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import Form from "@Core/components/Form";
import Yup from "@Core/validation";

import Props from "./RegisterForm.props";

export const RegisterForm = ({ token, email, onSubmit, onSuccess }: Props) => {
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		// Accountgegevens
		email: Yup.string().required(),
		phone_number: Yup.string().phone().required(),
		password: Yup.string().password().required(),
		password_confirmation: Yup.string().password("password"),

		// Persoonlijke gegevens
		first_name: Yup.string().required(),
		last_name: Yup.string().required(),

		// Privacy
		privacy: Yup.bool().oneOf([true], t("REGISTER.REGISTER.HAS_TO_ACCEPT_TERMS")),
	});

	const onFailure = () => {
		toast.error(t("REGISTER.REGISTER.INCORRECT_FIELDS"));
	};

	return (
		<Form.Form
			identifier="REGISTER_FORM"
			initialValues={{
				token: token,
				email: email,
			}}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			onFailure={onFailure}
			submit={t("REGISTER.REGISTER.CREATE_ACCOUNT")}
		>
			<Form.Group name={t("REGISTER.REGISTER.ACCOUNT_DATA")}>
				<Form.Item
					id="email"
					name={t("REGISTER.REGISTER.EMAIL")}
					render={(props) => <Form.Text disabled {...props} icon="envelope" />}
				/>

				<Form.Item
					id="phone_number"
					name={t("REGISTER.REGISTER.PHONE")}
					description={t("REGISTER.REGISTER.PHONE_DESCRIPTION")}
					render={(props) => <Form.Text {...props} placeholder="0612345678" icon="phone" />}
				/>

				<Form.Item
					id="password"
					name={t("REGISTER.REGISTER.PASSWORD")}
					description={t("FORMS.STRING_PASSWORD_DESCRIPTION")}
					render={(props) => <Form.Password {...props} />}
				/>

				<Form.Item
					id="password_confirmation"
					name={t("REGISTER.REGISTER.CONFIRM_PASSWORD")}
					render={(props) => <Form.Password {...props} />}
				/>
			</Form.Group>

			<Form.Group name={t("REGISTER.REGISTER.PERSONAL_DATA")}>
				<Form.Item
					id="first_name"
					name={t("REGISTER.REGISTER.FIRST_NAME")}
					render={(props) => <Form.Text {...props} icon="user-alt" />}
				/>

				<Form.Item
					id="last_name"
					name={t("REGISTER.REGISTER.LAST_NAME")}
					render={(props) => <Form.Text {...props} icon="user-alt" />}
				/>
			</Form.Group>

			<p>{t("REGISTER.REGISTER.DATA_USAGE")}</p>

			<Form.Item
				id="privacy"
				hidden
				render={(props) => (
					<Form.Checkbox
						{...props}
						options={[
							{
								id: "false",
								name: t("REGISTER.REGISTER.I_AGREE"),
							},
						]}
					/>
				)}
			/>
		</Form.Form>
	);
};
