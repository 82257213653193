import { format } from "date-fns/esm";
import qs from "querystring";

import axios from "@Core/axios";
import { LIMIT_PER_PAGE } from "@Core/constants/DEFAULT";

class RoleService {
	public getAllCompanyRoles = async (companyId: number) => {
		const data = {
			companyId,
		};

		const response = await axios.get(`oom/roles?${qs.encode(data)}`);

		return response.data;
	};

	public getAllEligableRoles = async (companyId: number) => {
		const data = {
			companyId,
		};

		const response = await axios.get(`oom/roles/eligible?${qs.encode(data)}`);

		return response.data;
	};

	public getRole = async (companyRoleId: number) => {
		const response = await axios.get(`oom/roles/${companyRoleId}`);

		return response.data;
	};

	public sendQuestionnaire = async (companyRoleId: number, emails: string[]) => {
		const data = {
			emails,
		};

		const response = await axios.post(`oom/roles/${companyRoleId}/send`, data);

		return response;
	};

	public assignRoleToCompany = async (companyId: number, roleId: number) => {
		const data = {
			companyId,
			roleId,
		};

		const response = await axios.post(`oom/roles`, data);

		return response;
	};

	public createInviteToken = async (companyRoleId: number, limit: number, expiryDate: Date) => {
		const data = {
			limit,
			expire_date: format(expiryDate, "dd-LL-yyyy").toString(),
		};

		const response = await axios.post(`oom/roles/${companyRoleId}/invitations`, data);

		return response;
	};

	public redeemInviteToken = async (token: string) => {
		const response = await axios.post(`oom/invites/redeem/${token}`);

		return response.data;
	};
}

export default new RoleService();
