import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";

import { Block, Circle, Step } from "@Core/components/UI";
import { useCoreStores } from "@Core/stores";

import { AssignmentList } from "@LNI/components/HOC";
import { Page } from "@LNI/components/Layouts";
import { useLNIStores } from "@LNI/stores";

import styles from "./ChallengePage.module.scss";

const ChallengePage = () => {
	const { t } = useTranslation();
	const { challenge } = useParams();
	const history = useHistory();

	const { setConfetti } = useCoreStores();
	const { ChallengeStore } = useLNIStores();

	const breadcrumbs = [{ to: "/", name: t("CHALLENGES.CHALLENGES") }, { name: ChallengeStore.challenge?.title }];

	useEffect(() => {
		window.scrollTo(0, 0);
	});

	// Load on page init
	useEffect(() => {
		(async function load() {
			try {
				if (ChallengeStore.hasChallenges) {
					await ChallengeStore.getChallenge(challenge);
				}
			} catch (err) {
				history.push(`/`);
			}
		})();
	}, [ChallengeStore, ChallengeStore.hasChallenges, challenge, history]);

	// Confetti
	useEffect(() => {
		if (ChallengeStore.challenge?.progress.total === ChallengeStore.challenge?.progress.done) {
			setConfetti(true);
		}
	}, [ChallengeStore.challenge, setConfetti]);

	const getCompleted = () => {
		if (ChallengeStore.challenge?.progress.total === ChallengeStore.challenge?.progress.done) {
			return (
				<div className="row">
					<div className="col-lg-12">
						<Block name="" className={styles.Completed}>
							<Circle secondary dashed x={85} y={45} r={120} />
							<Circle primary x={5} y={35} r={150} />

							<div className="row justify-content-center">
								<div className="col-lg-6 pt-md pb-md">
									<h2>Gefeliciteerd!</h2>

									<p className="mb-0">{ChallengeStore.challenge?.congratulation_text}</p>
								</div>
							</div>
						</Block>
					</div>
				</div>
			);
		}

		return <></>;
	};

	if (ChallengeStore.challenge) {
		return (
			<Page breadcrumbs={breadcrumbs}>
				<div className="container">
					{ChallengeStore.challenge?.assignments && (
						<div className="row">
							<div className="col-lg-12">
								<Block name={ChallengeStore.challenge?.title}>
									<div className={styles.Steps}>
										<Step
											step={ChallengeStore.challenge?.progress.done}
											steps={ChallengeStore.challenge?.progress.total}
										/>
									</div>

									{getCompleted()}

									<div className="row">
										<div className="col-lg-8">
											<div className={styles.Introduction}>
												<p>{ChallengeStore.challenge?.introduction_text}</p>
											</div>
										</div>
									</div>

									<AssignmentList assignments={ChallengeStore.challenge?.assignments} />
								</Block>
							</div>
						</div>
					)}
				</div>
			</Page>
		);
	}

	return <></>;
};

export default observer(ChallengePage);
