import React from "react";
import { useTranslation } from "react-i18next/";

import Form from "@Core/components/Form";
import Yup from "@Core/validation";

import Props from "./ResetPasswordForm.props";

const ResetPasswordForm = ({ initialValues, onSubmit, onSuccess }: Props) => {
	const { t } = useTranslation();
	const validationSchema = Yup.object().shape({
		password: Yup.string().password().required(),
		password_confirmation: Yup.string().password("password"),
	});

	return (
		<Form.Form
			identifier="RESET_FORM"
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			submit={t("LOGIN.CHANGE.CONFIRM")}
		>
			<Form.Item
				id="password"
				name={t("LOGIN.CHANGE.LABEL_PASSWORD")}
				description={t("FORMS.STRING_PASSWORD_DESCRIPTION")}
				render={(props) => <Form.Password {...props} />}
			/>

			<Form.Item
				id="password_confirmation"
				name={t("LOGIN.CHANGE.LABEL_PASSWORD_REPEAT")}
				render={(props) => <Form.Password {...props} />}
			/>
		</Form.Form>
	);
};

export default ResetPasswordForm;
