import { flow, Instance, SnapshotIn, types } from "mobx-state-tree";

import { FileStepType } from "@Core/constants/ENUMS";

import { FileQuestionnairesModel } from "./FileQuestionnairesModel";

export const FileStepModel = types
	.model("FileStepModel", {
		id: types.identifierNumber,
		type: types.enumeration<FileStepType>(Object.values(FileStepType)),
		title: types.string,
		description: types.maybeNull(types.string),
		selected: types.boolean,
		recommended: types.boolean,
		file_questionnaire: types.maybeNull(FileQuestionnairesModel),
	})
	.actions((self) => ({
		toggle: flow(function* () {
			// yield FileService.closeFile(self.id);
			self.selected = !self.selected;
		}),
	}));

export interface FileStepInstance extends Instance<typeof FileStepModel> {}
export type FileStepSnapshot = SnapshotIn<typeof FileStepModel>;
