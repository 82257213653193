import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { UserQuestionnaireThemeScoreModel } from "./UserQuestionnaireThemeScoreModel";

export const UserQuestionnaireSectionModel = types.model("UserQuestionnaireSectionModel", {
	id: types.identifierNumber,
	icon: types.maybeNull(types.string),
	title: types.string,
	theme_scores: types.maybeNull(types.array(UserQuestionnaireThemeScoreModel)),
	questionnaire_id: types.maybeNull(types.number),
});

export interface UserQuestionnaireSectionInstance extends Instance<typeof UserQuestionnaireSectionModel> {}
export type UserQuestionnaireSectionSnapshot = SnapshotIn<typeof UserQuestionnaireSectionModel>;
