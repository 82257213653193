import React from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import Form from "@Core/components/Form";
import Yup from "@Core/validation";

import Props from "./TeamSignupForm.props";

const TeamSignupForm = ({ submit, onSubmit, onSuccess }: Props) => {
	const { t } = useTranslation();
	const validationSchema = Yup.object().shape({
		/* eslint-disable no-template-curly-in-string */
		licence_code: Yup.string().min(6, 'STRING_MIN={"min":${min}}').max(6, 'STRING_MAX={"max":${max}}').required(),
	});

	const onFailure = () => {
		toast.error(t("COURSES.TEAM_SIGNUP.ERROR"));
	};

	return (
		<Form.Form
			identifier="REGISTER_LICENSE_FORM"
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			onFailure={onFailure}
			submit={submit}
		>
			<Form.Item
				id="licence_code"
				name={t("COURSES.TEAM_SIGNUP.LABEL_LICENSE")}
				render={(props) => <Form.Text {...props} icon="id-badge" />}
			/>
		</Form.Form>
	);
};

export default TeamSignupForm;
