import React from "react";

import { IFieldProps } from "@Core/interfaces";
import { UserQuestionnaireAnswerInstance, UserQuestionnaireQuestionInstance } from "@Core/models";

import styles from "./ScaleEmoji.module.scss";

type Props = {
	id: string;
	name: string;
	field: IFieldProps;

	disabled: boolean;
	question: UserQuestionnaireQuestionInstance;
	placeholder?: string;
};

const ScaleEmoji = ({ id, name, field, disabled, question, placeholder }: Props) => {
	return (
		<div className={styles.Inputs}>
			{question.answers.map((answer: UserQuestionnaireAnswerInstance) => (
				<div key={answer.id}>
					<input
						disabled={disabled}
						id={`Q${question.id}A${answer.id}`}
						type="radio"
						{...field}
						defaultChecked={answer.selected}
						value={answer.id}
					/>

					<label className={styles.Emoji} htmlFor={`Q${question.id}A${answer.id}`}>
						<div className={styles.Icon}>
							<span role="img" aria-label={answer.text}>
								😄
							</span>
						</div>
						<div className={styles.Text}>{answer.text}</div>
					</label>
				</div>
			))}
		</div>
	);
};

export default ScaleEmoji;
