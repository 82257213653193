import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { UserQuestionnaireThemeModel } from "./UserQuestionnaireThemeModel";

export const UserQuestionnaireThemeScoreModel = types.model("UserQuestionnaireThemeScoreModel", {
	score: types.number,
	threshold_description: types.maybeNull(types.string),
	theme: UserQuestionnaireThemeModel,
});

export interface UserQuestionnaireThemeScoreInstance extends Instance<typeof UserQuestionnaireThemeScoreModel> {}
export type UserQuestionnaireThemeScoreSnapshot = SnapshotIn<typeof UserQuestionnaireThemeScoreModel>;
