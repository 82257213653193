import { useMatomo } from "@datapunt/matomo-tracker-react";
import cn from "classnames";
import React, { useCallback, useState } from "react";

import { Card, Circle } from "@Core/components/UI";
import { DataLayer } from "@Core/constants/ENUMS";
import { useCoreStores } from "@Core/stores";
import { formatDate } from "@Core/utilities";

import styles from "./InboxMessage.module.scss";
import { InboxMessageProps } from "./InboxMessage.props";

export const InboxMessage = ({ message, className }: InboxMessageProps) => {
	const [isOpen, setOpen] = useState(false);
	const { UserStore } = useCoreStores();
	const { trackEvent } = useMatomo();

	const handleToggle = useCallback(async () => {
		setOpen((oldOpen) => !oldOpen);

		if (!message.is_read) {
			trackEvent({
				name: message.title,
				action: DataLayer.INBOX,
				category: String(UserStore.getCompanyId),
			});

			await message.read();
		}
	}, []);

	const classes = cn(styles.InboxMessage, className, {
		[styles.isOpen]: isOpen,
		[styles.isRead]: message.is_read,
	});

	return (
		<Card onClick={handleToggle} className={classes}>
			<div className={styles.Notification} />

			<div className={styles.Content}>
				<div className={styles.Heading}>
					<div>
						<h4 className={styles.Title}>{message.title}</h4>
						<div className={styles.Date}>{formatDate(message.created_at || "")}</div>
					</div>

					<i className={cn(styles.Toggle, "far fa-chevron-down")} />
				</div>

				<div className={styles.Message} dangerouslySetInnerHTML={{ __html: message.message }} />
			</div>

			{isOpen && <Circle quaternary dashed x={100} y={100} r={100} />}
		</Card>
	);
};
