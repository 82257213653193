import { flow, Instance, types } from "mobx-state-tree";

import { ArticleModel, TagModel } from "@Core/models";
import { ArticleService } from "@Core/services";

const ArticleStore = types
	.model({
		articles: types.array(ArticleModel),
		tags: types.array(TagModel),
	})
	.actions((self) => ({
		load: flow(function* () {
			const response = yield ArticleService.getArticles();

			if (response) {
				self.articles.clear();
				self.articles = response;
			}
		}),

		getTags: flow(function* () {
			const response = yield ArticleService.getTags();

			if (response) {
				self.tags = response;
			}
		}),
	}))
	.views((self) => ({
		get hasArticles(): boolean {
			return self.articles.length > 0;
		},

		get hasTags(): boolean {
			return self.tags.length > 0;
		},
	}));

export interface ArticleStoreInstance extends Instance<typeof ArticleStore> {}

export default ArticleStore;
