import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Block, Button, Loader } from "@Core/components/UI";

import { PreprArticleCard } from "@PNI/components/Containers";
import { IArticle, PreprArticleService } from "@PNI/services";

export const DashboardArticles = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [articles, setArticles] = useState<IArticle[]>();

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);
				setError(false);

				const response = await PreprArticleService.getArticles({ limit: 4 });
				setArticles(response.Artikels.items);
			} catch (e) {
				setError(true);
				Sentry.captureException(error);
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	return (
		<Block name={t("DASHBOARD.ARTICLES.TITLE")}>
			<div className="row">
				<div className="col-xl-9">
					<p>{t("DASHBOARD.ARTICLES.INTRO")}</p>
				</div>

				{loading && <Loader width={50} color="#56bbad" className="mb-md" />}

				{error && <div className="col-lg-12 mt-md mb-md">{t("DASHBOARD.ARTICLES.ERROR")}</div>}

				<div className="col-lg-12" />

				{articles?.map((article) => {
					return (
						<div key={article._id} className="col-xl-3 col-lg-6 col-md-6 mb-md ">
							<PreprArticleCard article={article} />
						</div>
					);
				})}
			</div>

			<Button to="/artikelen" primary iconRight="fa-long-arrow-right">
				{t("DASHBOARD.ARTICLES.VIEW_ALL")}
			</Button>
		</Block>
	);
};
