import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next/";
import { Link, useHistory } from "react-router-dom";

import Form from "@Core/components/Form";
import { Modal } from "@Core/components/Layouts";
import { Icon, Logo } from "@Core/components/UI";
import { IFieldProps } from "@Core/interfaces";
import { CompanyInstance } from "@Core/models";
import { AuthService } from "@Core/services";
import { useCoreStores } from "@Core/stores";

import { useOOMStores } from "@OOM/stores";

import styles from "./Header.module.scss";

const AuthorizedHeader = observer(
	(): JSX.Element => {
		const { t } = useTranslation();
		const history = useHistory();
		const { UserStore } = useCoreStores();

		const { load, isLoading } = useOOMStores();

		const [dropdown, setDropdown] = useState(false);

		const options =
			UserStore.user?.companies.reduce((total: Record<string, string>, company: CompanyInstance) => {
				total[company.id] = company.name;

				return total;
			}, {}) || {};

		const field: IFieldProps = {
			name: "company",
			value: UserStore.company?.id.toString() || "0",
			onChange: async (value: any) => {
				UserStore.setCompany(value.target.value);

				await load();
				history.push("/");
			},
		};

		const toggleDropdown = () => {
			const oldDropdown = dropdown;
			setDropdown(!oldDropdown);
		};

		const logout = () => {
			AuthService.logout();
			window.location.href = "";
		};

		return (
			<header className={styles.Wrapper} id="header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className={styles.Inner}>
								<Logo />

								<div className={styles.Company}>
									<Form.Select id="company" name="company" field={field} options={options} />
								</div>

								<div
									className={cn(styles.Profile, {
										[styles.ProfileActive]: dropdown,
									})}
								>
									<div onClick={toggleDropdown} className={styles.ProfileWrapper}>
										<div className={styles.ProfileName}>{UserStore?.getName}</div>

										<Icon icon="fal fa-user" />

										<div className={styles.ProfileToggle}>
											<i className="far fa-chevron-down"></i>
										</div>
									</div>

									<div className={styles.ProfileDropdown}>
										<Link to="/mijn-profiel">
											<i className="far fa-user"></i>
											{t("SHARED.CHANGE_PROFILE")}
										</Link>

										<Link to="/" onClick={logout}>
											<i className="far fa-edit"></i>
											{t("SHARED.LOGOUT")}
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal title={t("DEFAULT.RESULTS_TITLE")} isVisible={isLoading}>
					{t("DEFAULT.RESULTS_DESCRIPTION")}
				</Modal>
			</header>
		);
	},
);

const UnauthorizedHeader = observer(
	(): JSX.Element => {
		return (
			<header className={styles.Wrapper} id="header">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className={styles.Inner}>
								<Logo />
							</div>
						</div>
					</div>
				</div>
			</header>
		);
	},
);

type Props = {
	authorized?: boolean;
};

const Header = ({ authorized }: Props) => {
	if (authorized === true) {
		return <AuthorizedHeader />;
	} else {
		return <UnauthorizedHeader />;
	}
};

export default Header;
