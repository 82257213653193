import { flow, getParent, getRoot, Instance, SnapshotIn, types } from "mobx-state-tree";

import { ArticleModel, BaseModel, ImageModel, QuestionnaireModel } from "@Core/models";
import { QuestionnaireService } from "@Core/services";

import { AssignmentCategory, AssignmentType } from "@LNI/constants/ENUMS";
import { ChallengeModel, CourseModel, PollAnswerInstance, PollModel } from "@LNI/models";
import { ChallengeService, CourseService, MomentService } from "@LNI/services";
import RootStore from "@LNI/stores/LNIRootStore";

import { MomentInstance } from "./MomentModel";

export const AssignmentModel = BaseModel.props({
	title: types.string,
	category: types.enumeration<AssignmentCategory>(Object.values(AssignmentCategory)),
	order: types.number,
	description: types.maybeNull(types.string),
	type: types.enumeration<AssignmentType>(Object.values(AssignmentType)),
	contents: types.maybeNull(types.string),
	article: types.maybeNull(ArticleModel),
	poll: types.maybeNull(PollModel),
	questionnaire: types.maybeNull(QuestionnaireModel),
	user_questionnaire_id: types.maybeNull(types.number),
	image: types.maybeNull(ImageModel),
	isDone: false,
	canComplete: false,
})
	.actions((self) => ({
		startQuestionnaire: flow(function* (assignmentId?: number) {
			if (self.questionnaire) {
				const response = yield QuestionnaireService.start(self.questionnaire.id, assignmentId);

				if (response) {
					self.user_questionnaire_id = response.id;
				}
			}
		}),

		setAnswer: flow(function* (questionId: number, answer: number) {
			const parent = getParent<typeof CourseModel | typeof ChallengeModel>(self, 2);

			if (self.category === AssignmentCategory.COURSE) {
				yield CourseService.setAnswer(parent.id, self.order, questionId, answer);
			}

			if (self.category === AssignmentCategory.CHALLENGE) {
				yield ChallengeService.setAnswer(parent.id, self.order, questionId, answer);
			}
		} as any),

		setCanComplete() {
			if (self.isDone === false) {
				self.canComplete = true;
			}
		},

		setCompleted: flow(function* () {
			if (self.canComplete || self.isDone) {
				const parent = getParent<typeof CourseModel | typeof ChallengeModel>(self, 2);

				switch (self.category) {
					case AssignmentCategory.COURSE:
						yield CourseService.setCompleted(parent.id, self.order);
						parent.setDone();

						break;
					case AssignmentCategory.CHALLENGE:
						yield ChallengeService.setCompleted(parent.id, self.order);
						parent.setDone();

						break;
					case AssignmentCategory.MOMENT:
						if (self.type !== AssignmentType.POLL) {
							yield MomentService.setCompleted();
						} else {
							if (self.poll) {
								const selected = self.poll.answers.find(
									(answer: PollAnswerInstance) => answer.selected === true,
								);

								if (selected && selected.id) {
									yield MomentService.setCompleted(self.poll.id, selected.id);
								}
							}
						}

						getRoot<typeof RootStore>(self).MomentStore.getPreferences();
						getRoot<typeof RootStore>(self).MomentStore.getMoment();
						break;
				}

				self.isDone = true;
			}
		} as any),
	}))
	.views((self) => ({
		get isCourse(): boolean {
			return self.category === AssignmentCategory.COURSE;
		},

		get isChallenge(): boolean {
			return self.category === AssignmentCategory.CHALLENGE;
		},

		get isMoment(): boolean {
			return self.category === AssignmentCategory.MOMENT;
		},

		get getParentId(): number {
			const current = getParent<typeof CourseModel | typeof ChallengeModel>(self, 2);
			return current.id;
		},

		get getSlug(): string {
			const current = getParent<typeof CourseModel | typeof ChallengeModel>(self, 2);
			return `${current.id}/${self.id}`;
		},

		get getParentSlug(): string {
			const current = getParent<typeof CourseModel | typeof ChallengeModel>(self, 2);
			return `${current.id}`;
		},

		get canView(): boolean {
			if (this.isMoment) {
				return true;
			}

			if (self.isDone) {
				return true;
			}

			const parent = getParent<typeof CourseModel | typeof ChallengeModel>(self, 2);
			const foundFirst = parent.assignments.find((assignment: AssignmentSnapshot) => assignment.isDone === false);

			return foundFirst?.id === self.id;
		},

		get canFinish(): boolean {
			return (
				!self.canComplete ||
				self.isDone ||
				(self.category === "MOMENT" && (self as MomentInstance).isPivotDone) ||
				(self.category === "MOMENT" && (self as MomentInstance).isPivotExpired)
			);
		},

		get getType(): string {
			return self.type.toUpperCase();
		},
	}));

export interface AssignmentInstance extends Instance<typeof AssignmentModel> {}
export type AssignmentSnapshot = SnapshotIn<typeof AssignmentModel>;
