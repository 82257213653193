import { Instance, SnapshotIn, types } from "mobx-state-tree";

export const ActivationElementModel = types.model({
	title: types.maybeNull(types.string),
	icon: types.maybeNull(types.string),
	body: types.maybeNull(types.string),
	links: types.maybeNull(types.string),
});

export interface ActivationElementInstance extends Instance<typeof ActivationElementModel> {}
export type ActivationElementSnapshot = SnapshotIn<typeof ActivationElementModel>;
