import React from "react";

import { Button } from "@Core/components/UI";

import styles from "./Info.module.scss";

type Props = {
	name: string;
	button: string;
	onClick: () => void;
};

const Info = ({ name, button, onClick }: Props) => {
	return (
		<div className={styles.Item} onClick={onClick}>
			<div className={styles.Name}>{name}</div>

			<Button onClick={onClick} secondary link className={styles.Button}>
				{button}
				<i className="far fa-long-arrow-right"></i>
			</Button>
		</div>
	);
};

export default Info;
