import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";

import { Questionnaire } from "@Core/components/Components";
import { Block } from "@Core/components/UI";
import { useCoreStores } from "@Core/stores";
import { parseHTML } from "@Core/utilities";

import { Page } from "@OOM/components/Layouts";

import FilePageHeader from "./Components/FilePageHeader";

type Params = {
	questionnaire: string | undefined;
	companyRoleId: string | undefined;
};

const AnswerDetailPage = () => {
	const { t } = useTranslation();
	const { companyRoleId, questionnaire } = useParams<Params>();
	const history = useHistory();

	const { UserStore, QuestionnaireStore } = useCoreStores();

	const [isLoaded, setIsLoaded] = useState(false);

	const breadcrumbs = [
		{
			name: QuestionnaireStore.questionnaire?.questionnaire.title,
		},
	];

	useEffect(() => {
		(async () => {
			if (questionnaire) {
				try {
					await QuestionnaireStore.load(parseInt(questionnaire));

					if (!QuestionnaireStore.questionnaire?.isCompleted) {
						throw new Error("NOT_FOUND");
					}

					setIsLoaded(true);
				} catch (e) {
					history.push(`/antwoorden/${companyRoleId}`);
				}
			} else {
				history.push("");
			}
		})();
	}, [QuestionnaireStore, history, questionnaire, companyRoleId]);

	const getQuestionnaire = () => {
		if (QuestionnaireStore.questionnaire && UserStore.user) {
			const questionnaireData = QuestionnaireStore.questionnaire;
			return (
				<>
					<FilePageHeader
						title={QuestionnaireStore.questionnaire.questionnaire.title}
						description={QuestionnaireStore.questionnaire.questionnaire.information}
					/>

					<div className="container">
						<div className="row">
							<div className="col">
								<div className="row">
									<div className="col-lg-8">
										<Block
											name={t("QUICKSCAN.QUESTIONNAIRE.SUBMITTED_ANSWERS", {
												id: `ID: ${questionnaireData.id}`,
											})}
										>
											<Questionnaire id={parseInt(questionnaire || "0")} />
										</Block>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			);
		}
	};

	return <Page breadcrumbs={breadcrumbs}>{isLoaded && getQuestionnaire()}</Page>;
};

export default observer(AnswerDetailPage);
