import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

import { PAGE_SIZE } from "@Core/constants/DEFAULT";

import { IArticle, PreprArticleService } from "@PNI/services";

import { ArticlesArchiveFilterValues } from "../components/ArticlesArchiveFilter/ArticlesArchiveFilter.props";

export const useArticles = (page: number, filter?: ArticlesArchiveFilterValues) => {
	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const [articles, setArticles] = useState<IArticle[]>();
	const [total, setTotal] = useState(0);

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);
				setError(false);

				const response = await PreprArticleService.getArticles();
				setArticles(response.Artikels.items);
				setTotal(response.Artikels.total);

				setLoaded(true);
			} catch (e) {
				setError(true);
				Sentry.captureException(error);
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	useEffect(() => {
		setLoading(true);

		let delayDebounce: any = null;

		delayDebounce = setTimeout(async () => {
			(async () => {
				const params = {
					skip: page * PAGE_SIZE,
					where: {},
				} as any;

				const tags: string[] = [];

				if (filter && filter.keywords) {
					filter.keywords.map((keyword) => {
						tags.push(keyword.body);
					});
				}

				if (filter && filter.theme) {
					tags.push(filter.theme.body);
				}

				if (tags) {
					params.where._tags_all = tags;
				}

				if (filter?.search) {
					params.where._search = filter.search;
				}

				const response = await PreprArticleService.getArticles(params);

				setArticles(response.Artikels?.items || []);
				setTotal(response.Artikels?.total || 0);

				setLoading(false);
			})();
		}, 1000);

		return () => clearTimeout(delayDebounce);
	}, [page, filter]);

	return { articles, total, loading, loaded, error };
};
