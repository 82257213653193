import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Questionnaire } from "@Core/components/Components";
import { Block } from "@Core/components/UI";
import { useCoreStores } from "@Core/stores";
import { parseHTML } from "@Core/utilities";

import { Page } from "@PNI/components/Layouts";
import { VosService } from "@PNI/services";

const OmissionPage = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const { QuestionnaireStore, UserStore } = useCoreStores();

	const breadcrumbs = [
		{
			name: t("OMISSION.TITLE"),
		},
	];

	useEffect(() => {
		(async () => {
			try {
				const response = await VosService.start(UserStore.getCompanyId);

				if (response && response.id) {
					await QuestionnaireStore.load(response.id);
				}
			} catch (e) {
				history.push("");
			}
		})();
	}, [QuestionnaireStore, UserStore.getCompanyId, history]);

	const onCompletion = () => {
		history.push("/");
		toast.success(t("OMISSION.SUCCESS"));
	};

	return (
		<Page breadcrumbs={breadcrumbs}>
			{QuestionnaireStore.questionnaire && (
				<div className="container">
					<div className="row">
						<div className="col-lg-8">
							<h2 className="color-primary">{QuestionnaireStore.questionnaire.questionnaire.title}</h2>

							{parseHTML(QuestionnaireStore.questionnaire.questionnaire.information, "mb-sm")}
						</div>

						<div className="col-lg-8">
							<Questionnaire
								id={QuestionnaireStore.questionnaire.id}
								onCompletion={() => onCompletion()}
								block
							/>
						</div>
					</div>
				</div>
			)}
		</Page>
	);
};

export default observer(OmissionPage);
