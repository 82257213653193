import axios from "@Core/axios";
import { FileSnapshot, UserQuestionnaireSnapshot } from "@Core/models";
import { formatDate, Prepr } from "@Core/utilities";

import { GenerateFileFormValues, GenerateFileFormValuesEmployee } from "@PNI/forms";

export type ConnectEmployeeProps = {
	employee_id?: number;
	employee?: GenerateFileFormValuesEmployee;
};

class FileService {
	getFile = async (id: number): Promise<FileSnapshot[] | undefined> => {
		try {
			const response = await axios.get(`aom/files/${id}`);

			if (response?.data?.article_recommendations) {
				const recommended = response.data.article_recommendations.map((article_recommendation: any) => {
					return Prepr.format(article_recommendation.article, article_recommendation);
				});

				response.data.article_recommendations = recommended;

				return response.data;
			}

			if (response?.data) {
				return response.data;
			}
		} catch (e) {}
	};

	getFiles = async (companyId?: number, archived?: boolean): Promise<FileSnapshot[] | undefined> => {
		try {
			let config = {};

			if (companyId) {
				config = {
					params: { company_id: companyId, archived: archived },
				};
			}

			const response = await axios.get("aom/files", config);
			return response.data;
		} catch (e) {}
	};

	startOmission = async (id: number): Promise<UserQuestionnaireSnapshot | null> => {
		try {
			const response = await axios.post(`aom/files/${id}/vos`);
			return response.data;
		} catch (e) {
			return null;
		}
	};

	startInterventionAdvice = async (id: number): Promise<UserQuestionnaireSnapshot | null> => {
		try {
			const response = await axios.post(`aom/files/${id}/interventionadvice`);
			return response.data;
		} catch (e) {
			return null;
		}
	};

	createFile = async (companyId: number, values: GenerateFileFormValues): Promise<any | null> => {
		values.company_id = companyId;

		if (values.employee) {
			values.employee.date_of_birth = formatDate(values.employee.date_of_birth);
		}

		const response = await axios.post(`aom/files`, values);

		if (response) {
			return response.data;
		}
	};

	closeFile = async (id: number): Promise<any | null> => {
		const response = await axios.delete(`aom/files/${id}`);

		if (response) {
			return response.data;
		}
	};

	validateEmployeeEmail = async (email: string): Promise<any | null> => {
		const data = {
			email: email,
		};

		const response = await axios.post(`aom/employees/validate`, data);

		if (response) {
			return response.data;
		}
	};

	sendQuestionnaire = async (id: number, questionnaireId: number): Promise<any | null> => {
		const data = {
			questionnaireId,
		};

		const response = await axios.post(`aom/files/${id}/additional`, data);

		if (response) {
			return response.data;
		}
	};

	connectEmployee = async (id: number, values: ConnectEmployeeProps): Promise<any | null> => {
		const response = await axios.post(`aom/files/${id}/employee`, values);

		if (response) {
			return response.data;
		}
	};
}

export default new FileService();
