import axios, { AxiosInstance, AxiosResponse } from "axios";

import { GraphqlRequest } from "./PreprGraphqlService.props";

class PreprGraphqlServiceImplementation {
	private instance: AxiosInstance;

	constructor() {
		this.instance = axios.create({
			baseURL: process.env.REACT_APP_GRAPHQL_ENDPOINT,
			headers: {
				Authorization: process.env.REACT_APP_GRAPHQL_TOKEN,
			},
		});

		this.instance.interceptors.response.use(this.onSuccessInterceptor, this.onErrorInterceptor);
	}

	private onSuccessInterceptor = (value: AxiosResponse) => {
		if (value.data.errors && value.data.errors.length > 0) {
			console.log("%cerror", "color:red", "- ", {
				error: value.data.errors,
				request: value,
			});

			throw new Error(value.data.errors.map((error: any) => error.message));
		}

		return Promise.resolve(value);
	};

	private onErrorInterceptor = (value: AxiosResponse) => {
		return Promise.reject(value);
	};

	public query<T>(query: string): Promise<AxiosResponse<GraphqlRequest<T>>> {
		return this.instance.post<GraphqlRequest<T>>("", {
			query: this.compress(query),
		});
	}

	private compress = (query: string): string => {
		return query;
		// .replace(/[\t\n]+/g, '')
		// .replace(/\s+/g, ' ')
		// .trim();
	};
}

export const PreprGraphqlService = new PreprGraphqlServiceImplementation();
