import { Instance, SnapshotIn, types } from "mobx-state-tree";

export const PublicationElementModel = types.model({
	id: types.string,
	label: types.string,
	body: types.string,
	type: types.string,
	title: types.string,
	url: types.string,
});

export interface PublicationElementInstance extends Instance<typeof PublicationElementModel> {}
export type PublicationElementSnapshot = SnapshotIn<typeof PublicationElementModel>;
