import React from "react";

import { DashboardOmissionExportFilter, DashboardOmissionExportResults } from "./internal";

export const DashboardOmissionExport = () => {
	return (
		<div className="row">
			<div className="col-lg-4">
				<DashboardOmissionExportFilter />
			</div>

			<div className="col-lg-8">
				<DashboardOmissionExportResults />
			</div>
		</div>
	);
};
