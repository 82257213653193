import React from "react";

import { IFieldProps } from "@Core/interfaces";
import { UserQuestionnaireQuestionInstance } from "@Core/models";

import styles from "./Scale.module.scss";

type Props = {
	id: string;
	name: string;
	field: IFieldProps;

	disabled?: boolean;
	question: UserQuestionnaireQuestionInstance;
	placeholder?: string;
};

const Scale = ({ id, name, field, disabled, question }: Props) => {
	return (
		<div className={styles.Inputs}>
			<div className={styles.Titles}>
				<div>{question.scale_low ? question.scale_low : "Helemaal mee oneens"}</div>
				<div>{question.scale_high ? question.scale_high : "Helemaal mee eens"}</div>
			</div>

			<div className={styles.Numbers}>
				{question.answers.map((answer: any, key: number) => (
					<div key={key} className={styles.Number}>
						<input
							disabled={disabled}
							id={`Q${question.id}A${answer.id}`}
							type="radio"
							{...field}
							value={answer.id}
							defaultChecked={answer.selected}
						/>
						<label htmlFor={`Q${question.id}A${answer.id}`}>{answer.text}</label>
					</div>
				))}
			</div>
		</div>
	);
};

export default Scale;
