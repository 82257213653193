import React from "react";
import { useTranslation } from "react-i18next/";

import { Frame } from "@Core/components/UI";

import { SeperatedList } from "@PNI/components/Containers";
import { usePNIStores } from "@PNI/stores";

import { ResourcesProps } from "./Resources.props";

export const Resources = ({ className }: ResourcesProps) => {
	const { t } = useTranslation();
	const { FileStore } = usePNIStores();

	const resources = FileStore.file?.resources ?? [];

	return (
		<Frame
			title={t("FILES.RESOURCES.TITLE")}
			icon="fas fa-hand-holding-medical"
			iconVariant="success"
			className={className}
			variant={resources.length ? undefined : "light"}
		>
			<Frame.Body padding="3">
				{resources.length ? (
					<SeperatedList list={resources} />
				) : (
					<p className="small mb-0 color-support">{t("FILES.RESOURCES.EMPTY")}</p>
				)}
			</Frame.Body>
		</Frame>
	);
};
