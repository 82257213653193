import { flow, Instance, types } from "mobx-state-tree";

import { MomentModel, PreferencesModel } from "@LNI/models";
import { MomentService } from "@LNI/services";

const MomentStore = types
	.model({
		moment: types.maybe(MomentModel),
		oldMoment: types.maybe(MomentModel),

		preferences: types.maybe(PreferencesModel),
	})
	.actions((self) => {
		const actions = {
			load: flow(function* () {
				// Load preferences
				if (self.preferences == null) {
					yield actions.getPreferences();
				}

				// Load moment if signed up for
				if (self.moment == null) {
					yield actions.getMoment();
				}
			}),

			getPreferences: flow(function* () {
				const response = yield MomentService.getPreferences();

				if (response && response.moment_interval) {
					self.preferences = response;
				}
			}),

			getMoment: flow(function* () {
				try {
					const response = yield MomentService.getMoment();

					if (response && !response.error) {
						self.moment = response;
					}
				} catch (e) {
					self.moment = undefined;
				}
			}),

			getOldMoment: flow(function* (id: number) {
				const response = yield MomentService.getMoment(id);

				if (response && !response.error) {
					self.oldMoment = response;
				} else {
					self.oldMoment = undefined;
				}
			}),

			setPreferences: flow(function* (interval: string) {
				const response = yield MomentService.setPreferences({
					moment_interval: interval,
				});

				if (response) {
					self.preferences = response;

					yield actions.getMoment();
				}
			}),
		} as any;

		return actions;
	})
	.views((self) => ({
		get hasMoment(): boolean {
			return self.moment !== undefined;
		},

		get hasPreferences(): boolean {
			return self.preferences !== undefined;
		},
	}));

export interface MomentStoreInstance extends Instance<typeof MomentStore> {}

export default MomentStore;
