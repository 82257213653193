import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { useEffect } from "react";

import { useCoreStores } from "@Core/stores";

const history = createBrowserHistory();

export const useSentry = (): void => {
	const { UserStore } = useCoreStores();

	useEffect(() => {
		if (process.env.NODE_ENV !== "development") {
			Sentry.init({
				dsn: process.env.REACT_APP_SENTRY_URL,
				environment: process.env.NODE_ENV,
				integrations: [
					new Integrations.BrowserTracing({
						routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
					}),
				],
				tracesSampleRate: 1.0,
			});
		}
	}, []);

	useEffect(() => {
		if (UserStore?.user) {
			Sentry.setUser({
				username: UserStore?.user?.getName,
				email: UserStore?.user?.email,
			});
		}
	}, [UserStore, UserStore.user, UserStore.user?.email, UserStore?.user?.getName]);
};

export default useSentry;
