import * as Yup from "yup";

import { MOBILE, PASSWORD, ZIPCODE } from "@Core/constants/REGEXP";

import { FileService } from "./services";

Yup.setLocale({
	mixed: {
		required: "REQUIRED",
	},
	string: {
		email: "STRING_EMAIL",
		min: "STRING_MIN",
		max: "STRING_MAX",
	},
	number: {
		min: "NUMBER_MIN",
		max: "NUMBER_MAX",
	},
});

Yup.addMethod(Yup.string, "phone", () => {
	return Yup.string().test("phone", "", function (value) {
		const { path, createError } = this;
		return (value && value.match(MOBILE)) || createError({ path, message: "STRING_PHONE" });
	});
});

Yup.addMethod(Yup.string, "zipcode", () => {
	return Yup.string().test("zipcode", "", function (value) {
		const { path, createError } = this;
		return (value && value.match(ZIPCODE)) || createError({ path, message: "STRING_ZIPCODE" });
	});
});

Yup.addMethod(Yup.string, "password", (ref: string) => {
	// Check if password is equal to the reference
	if (ref) {
		return Yup.string().test("password", "", function (value: string) {
			const { path, createError } = this;
			return this.parent[ref] === value || createError({ path, message: "STRING_PASSWORD_MATCH" });
		});
	}

	// Check if password is in correct format
	return Yup.string().test("password", "", function (value) {
		const { path, createError } = this;
		return (value && value.match(PASSWORD)) || createError({ path, message: "STRING_PASSWORD" });
	});
});

let debounce: NodeJS.Timeout;
export const validateEmployeeEmail = async (value: string, old?: string): Promise<boolean> => {
	clearTimeout(debounce);

	return new Promise(function (resolve) {
		if (old === value) return resolve(true);

		debounce = setTimeout(async () => {
			const { is_valid } = await FileService.validateEmployeeEmail(value);
			return resolve(is_valid);
		}, 250);
	});
};

export default Yup;
