import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { Login } from "@Core/components/Layouts";
import { ResetPasswordForm } from "@Core/forms";
import { ResetPasswordFormValues } from "@Core/forms/ResetPasswordForm/ResetPasswordForm.props";
import { AuthService } from "@Core/services";

const ResetPage = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();

	const [token, setToken] = useState("");
	const [mail, setMail] = useState("");

	useEffect(() => {
		const params = new URLSearchParams(location.search);

		if (params && params.get("token") && params.get("email")) {
			setToken("" + params.get("token"));
			setMail("" + params.get("email"));
		} else {
			history.push("/");
		}
	}, [history, location.search]);

	const initialValues: ResetPasswordFormValues = {
		token: token,
		email: mail,
		password: "",
		password_confirmation: "",
	};

	const onSubmit = (values: ResetPasswordFormValues) => {
		return AuthService.resetPassword(values);
	};

	const onSuccess = () => {
		toast.success(t("LOGIN.CHANGE.PASSWORD_CHANGED"));
		history.push("/inloggen");
	};

	const title = t("LOGIN.CHANGE.TITLE");
	const description = t("LOGIN.CHANGE.SUBTITLE");
	const form = <ResetPasswordForm initialValues={initialValues} onSubmit={onSubmit} onSuccess={onSuccess} />;

	return (
		<Login title={title} description={description}>
			{token && form}
		</Login>
	);
};

export default ResetPage;
