import cn from "classnames";
import React from "react";
import ReactDOM from "react-dom";
import LazyLoad from "react-lazyload";

import { Icon } from "@Core/components/UI";

import styles from "./Modal.module.scss";

type Props = {
	title: string;
	isVisible: boolean;
	hide?: () => void;

	children?: any;
	image?: string;
	large?: boolean;
};

const Modal = ({ title, isVisible, hide, children, large, image }: Props) =>
	isVisible
		? ReactDOM.createPortal(
				<React.Fragment>
					<div className={styles.Overlay} onClick={hide} />

					<div className={cn([styles.Modal], { [styles.ModalLarge]: large })}>
						<div className={styles.Header}>
							<h4 className={styles.Title}>{title}</h4>

							{hide && (
								<div className={styles.Close} onClick={hide}>
									<Icon icon="far fa-times" />
								</div>
							)}
						</div>

						<div className={styles.Body}>
							<div className={styles.Content}>{children}</div>

							{image && (
								<div className={styles.Image}>
									<LazyLoad>
										<img src={image} alt={title} />
									</LazyLoad>
								</div>
							)}
						</div>
					</div>
				</React.Fragment>,
				document.body,
		  )
		: null;

export default Modal;
