import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseModel, UserQuestionnaireModel } from "@Core/models";
import { BaseUserModel } from "@Core/models/BaseUserModel";

export const UserModel = BaseModel.props({
	date_of_birth: types.string,
	email: types.string,
	external_id: types.maybeNull(types.string),
	first_name: types.string,
	last_name: types.string,
	phone_number: types.string,
	pob: BaseUserModel,
	user_questionnaire: UserQuestionnaireModel,
	zipcode: types.string,
});

export interface UserInstance extends Instance<typeof UserModel> {}
export type UserSnapshot = SnapshotIn<typeof UserModel>;
