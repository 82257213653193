import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Login } from "@Core/components/Layouts";
import { LoginForm, LoginSmsForm } from "@Core/forms";
import { LoginFormValues } from "@Core/forms/LoginForm/LoginForm.props";
import { LoginSmsFormValues } from "@Core/forms/LoginSmsForm/LoginSmsForm.props";
import { AuthService } from "@Core/services";
import { useCoreStores } from "@Core/stores";

const LoginPage = () => {
	const { t } = useTranslation();
	const [token, setToken] = useState("");
	const Core = useCoreStores();

	const initialValuesLogin: LoginFormValues = {
		email: "",
		password: "",
	};

	const onSubmitLogin = (values: LoginFormValues) => {
		return AuthService.login(values);
	};

	const onSuccessLogin = (response: { sms_token: string }) => {
		setToken(response.sms_token);
	};

	const initialValuesSms: LoginSmsFormValues = {
		sms_token: token,
		code: "",
	};

	const onSubmitSms = async (values: LoginSmsFormValues) => {
		const response = await AuthService.token(values);

		Core.setIsLoggingIn(true);
		Core.UserStore.login(response.new_user);

		AuthService.setToken(response.access_token);
	};

	const title = t("LOGIN.LOGIN.TITLE");
	let description = t("LOGIN.LOGIN.SUBTITLE");

	let form = <LoginForm initialValues={initialValuesLogin} onSubmit={onSubmitLogin} onSuccess={onSuccessLogin} />;

	if (token) {
		description = t("LOGIN.SMS.SUBTITLE");
		form = <LoginSmsForm initialValues={initialValuesSms} onSubmit={onSubmitSms} />;
	}

	return (
		<Login title={title} description={description}>
			{form}
		</Login>
	);
};

export default LoginPage;
