import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Form from "@Core/components/Form";
import { Modal } from "@Core/components/Layouts";
import { Block, Button, Circle } from "@Core/components/UI";
import { Platform } from "@Core/constants/ENUMS";
import { useDialog } from "@Core/hooks";
import { QuestionnaireService } from "@Core/services";
import { useCoreStores } from "@Core/stores";

import { Page } from "@OOM/components/Layouts";
import { CompanyRoleInstance } from "@OOM/models";
import { RoleService } from "@OOM/services";
import { useOOMStores } from "@OOM/stores";
import { parseEmailCSV } from "@OOM/utilities";

import DashboardPageQuestionList from "./Components/DashboardPageQuestionList";

type SendQuestionnaireFormValues = {
	email?: string;
};

type EligibleRoleFormValues = {
	role?: string;
};

const DashboardPageQuickscan = () => {
	const { t } = useTranslation();
	const { UserStore } = useCoreStores();
	const { RoleStore } = useOOMStores();
	const sendPopup = useDialog();
	const addRole = useDialog();
	const history = useHistory();
	const [emails, setEmails] = useState<string | null>("");
	const [selectedCompanyRole, setSelectedCompanyRole] = useState<CompanyRoleInstance>();
	const [selectedEligibleRole, setSelectedEligibleRole] = useState<string>();

	const handleSendQuestionnaireSubmit = async (values: SendQuestionnaireFormValues) => {
		if (selectedCompanyRole) {
			try {
				const emails = values.email?.replaceAll(" ", "").split(",") || [];
				const response = await RoleService.sendQuestionnaire(selectedCompanyRole.id, emails);

				toast.success(t("QUICKSCAN.QUESTIONLIST_SENDED"));
				sendPopup.toggle();

				return response;
			} catch (e) {
				toast.error(t("SHARED.ERROR"));
			}
		}
	};
	const handleEligibleRoleSubmit = async (values: EligibleRoleFormValues) => {
		if (values.role) {
			const roleId = parseInt(values.role);
			const response = await RoleService.assignRoleToCompany(UserStore.getCompanyId, roleId);

			if (response.data) {
				RoleStore.setCompanyRoles(response.data);
				RoleStore.setEligibleRoles(RoleStore.eligibleRoles.filter((role) => role.id !== roleId));

				setTimeout(addRole.toggle, 1000);
			}

			return response;
		}
	};

	const handleStartByPhone = useCallback(
		async (companyRoleId: number) => {
			try {
				const response = await QuestionnaireService.phone(companyRoleId, Platform.OOM);

				if (response) {
					history.push(`/vragenlijst/${response.id}`);
				}
			} catch (e) {
				toast.error(t("SHARED.ERROR"));
			}
		},
		[t],
	);

	const getCompanyRoleData = useCallback(() => {
		return RoleStore.companyRoles.map((companyRole) => ({
			id: companyRole.id.toString(),
			title: `${t("QUICKSCAN.QUESTIONLIST_SUBTITLE")} ${companyRole.role.name}`,
			onClickSend: () => {
				setSelectedCompanyRole(companyRole);
				setEmails(null);
				sendPopup.toggle();
			},
			onClickPhone: () => handleStartByPhone(companyRole.id),
		}));
	}, [RoleStore.companyRoles, sendPopup, t, handleStartByPhone]);

	const getEligibleRoleOptions = useCallback(() => {
		return RoleStore.eligibleRoles.reduce(
			(total: { [key: string]: string }, eligibleRole) => {
				total[eligibleRole.id.toString()] = eligibleRole.name;

				return total;
			},
			{
				"0": t("QUICKSCAN.ROLE_SELECT"),
			},
		);
	}, [RoleStore.eligibleRoles, t]);

	const getEligibleQuestionnaire = useCallback(() => {
		return RoleStore.eligibleRoles.find((role) => role.id.toString() === selectedEligibleRole)?.base_questionnaire
			.title;
	}, [selectedEligibleRole, RoleStore.eligibleRoles]);

	const renderCompanyRoleBlocks = useCallback(() => {
		return RoleStore.companyRoles.map((companyRole) => (
			<div key={companyRole.id} className="col-12 col-sm-6 col-lg-4">
				<Block name={companyRole.role.name}>
					<Circle quaternary dashed r={100} x={-3} y={50} />
					<Circle primary dashed r={40} x={60} y={90} />

					<div>{t("QUICKSCAN.BLOCK_DESCRIPTION", { role: companyRole.role.name })}</div>

					<Button to={`/antwoorden/${companyRole.id}`} className="mt-sm" primary>
						{t("QUICKSCAN.CHECK_AWNSERS")}
					</Button>
				</Block>
			</div>
		));
	}, [RoleStore.companyRoles, t]);

	const handleCsvUpload = async (files: FileList | null) => {
		if (files && files[0]) {
			setEmails(await parseEmailCSV(files[0]));
		}
	};

	return (
		<Page>
			<div className="container">
				<Modal
					title={t("QUICKSCAN.QUESTIONLIST_TITLE")}
					isVisible={sendPopup.isVisible}
					hide={() => {
						sendPopup.toggle();
					}}
				>
					<p>{t("QUICKSCAN.QUESTIONLIST_DESCRIPTION")}</p>
					<h4>{`${t("QUICKSCAN.QUESTIONLIST_SUBTITLE")} ${selectedCompanyRole?.role.name}`}</h4>
					<Form.Form
						identifier="IMPORT_EMAIL_CSV"
						onSubmit={() => {
							return;
						}}
					>
						<Form.Item
							id="emailCsv"
							render={(props) => (
								<Form.FileInput
									label={t("QUICKSCAN.QUESTIONLIST_IMPORT_EMAIL")}
									accept=".csv"
									onUpload={handleCsvUpload}
									{...props}
								/>
							)}
						/>
					</Form.Form>

					<Form.Form
						identifier="SEND_EMAIL_FORM"
						onSubmit={handleSendQuestionnaireSubmit}
						initialValues={{ email: emails }}
						submit={t("QUICKSCAN.QUESTIONLIST_SEND")}
					>
						<Form.Item
							id="email"
							name={t("QUICKSCAN.QUESTIONLIST_EMAIL_DESCRIPTION")}
							render={(props) => <Form.Textarea {...props} />}
						/>
					</Form.Form>
				</Modal>
				<Modal
					title={t("QUICKSCAN.ROLE_TITLE")}
					isVisible={addRole.isVisible}
					hide={() => {
						addRole.toggle();
					}}
				>
					<p>{t("QUICKSCAN.ROLE_DESCRIPTION")}</p>
					<Form.Form
						identifier="ADD_LIST_FORM"
						onSubmit={handleEligibleRoleSubmit}
						submit={t("QUICKSCAN.QUESTIONLIST_ADD")}
						initialValues={{ role: selectedEligibleRole, questionnaire: getEligibleQuestionnaire() || "" }}
					>
						<Form.Item
							id="role"
							name={t("QUICKSCAN.ROLE_CHOOSE")}
							render={(props) => (
								<Form.Select
									{...props}
									onChange={(id) => setSelectedEligibleRole(id)}
									options={getEligibleRoleOptions()}
								/>
							)}
						/>
						<Form.Item
							id="questionnaire"
							name={t("QUICKSCAN.QUESTIONLIST_CHOOSE")}
							render={(props) => <Form.Text {...props} disabled />}
						/>
					</Form.Form>
				</Modal>

				<div className="row">
					<div className="col-lg-12">
						<div className="mb-sm d-flex">
							<h3 className="color-primary ">{t("QUICKSCAN.TITLE")}</h3>
						</div>

						<Block name={t("QUICKSCAN.SEND_QUESTIONS")}>
							<DashboardPageQuestionList files={getCompanyRoleData()} />
							{RoleStore.eligibleRoles.length > 0 && (
								<Button className="mt-sm" primary onClick={addRole.toggle}>
									{t("QUICKSCAN.ADD_NEW_ROLE")}
								</Button>
							)}
						</Block>

						{!!RoleStore.companyRoles && <div className="row">{renderCompanyRoleBlocks()}</div>}

						<Block name={t("QUICKSCAN.SCORECARD")}>
							<Circle quaternary dashed r={100} x={-3} y={50} />
							<Circle primary dashed r={40} x={60} y={90} />
							<div>
								<text>
									Vivamus laoreet. Nullam tincidunt adipiscing enim. Phasellus tempus. Proin viverra,
									ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi
									mauris ac eros. Fusce neque. Suspendisse faucibus, nunc et pellentesque egestas,
									lacus ante convallis tellus, vitae iaculis lacus elit id tortor.
								</text>
							</div>
							<Button to="/scorekaart" className="mt-sm" tertiary>
								{t("QUICKSCAN.VIEW_SCORECARD")}
							</Button>
						</Block>
					</div>
				</div>
			</div>
		</Page>
	);
};

export default observer(DashboardPageQuickscan);
