import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Form from "@Core/components/Form";
import { Button } from "@Core/components/UI";
import Yup from "@Core/validation";

import styles from "./NotesForm.module.scss";
import { NotesFormProps, NotesFormValues } from "./NotesForm.props";

export const NotesForm = observer(({ initialValues, onSubmit, className }: NotesFormProps) => {
	const { t } = useTranslation();
	const [isEnabled, setIsEnabled] = useState(false);

	const validationSchema = Yup.object().shape({
		note: Yup.string().required(),
	});

	const handleSubmit = (values: NotesFormValues) => {
		setIsEnabled(false);

		onSubmit(values);
	};

	const hasNotes = !!initialValues?.note;

	return (
		<Form.Form
			identifier="NOTES_FORM"
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
			className={cn(styles.NotesForm, className)}
		>
			<Form.Item
				id="note"
				hidden
				render={(props) => (
					<Form.RichText
						className={styles.Input}
						placeholder={t("FILES.NOTES.FORM.ADD_NOTES.INPUT.PLACEHOLDER")}
						isDisabled={!isEnabled}
						{...props}
					/>
				)}
			/>

			<div className={styles.Footer}>
				{isEnabled && (
					<Button type="submit" tertiary>
						{t("FILES.NOTES.FORM.SUBMIT")}
					</Button>
				)}

				{!isEnabled && (
					<Button tertiary link={hasNotes} onClick={() => setIsEnabled(true)}>
						{hasNotes ? t("FILES.NOTES.FORM.EDIT_NOTES.BUTTON") : t("FILES.NOTES.FORM.ADD_NOTES.BUTTON")}
					</Button>
				)}
			</div>
		</Form.Form>
	);
});
