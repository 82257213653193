import DEFAULT from "./default.json";
import FILES from "./files.json";
import LOGIN from "./login.json";
import MANAGER from "./manager.json";
import QUESTIONNAIRE from "./questionnaire.json";
import QUICKSCAN from "./quickscan.json";
import REGISTER from "./register.json";

const data = {
	translation: {
		DEFAULT,
		FILES,
		LOGIN,
		MANAGER,
		QUICKSCAN,
		QUESTIONNAIRE,
		REGISTER,
	},
};

export default data;
