import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseUserModel } from "./BaseUserModel";
import { CompanyModel } from "./CompanyModel";

export const UserModel = BaseUserModel.props({
	companies: types.array(CompanyModel),
});

export interface UserInstance extends Instance<typeof UserModel> {}
export type UserSnapshot = SnapshotIn<typeof UserModel>;
