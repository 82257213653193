import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import Form from "@Core/components/Form";
import { Block, Circle } from "@Core/components/UI";
import { ExportType } from "@Core/constants/ENUMS";
import { CompanyInstance } from "@Core/models";
import { useCoreStores } from "@Core/stores";

import { usePNIStores } from "@PNI/stores";

export const DashboardOmissionExportFilter = observer(() => {
	const { t } = useTranslation();
	const { ExportStore } = usePNIStores();
	const { UserStore } = useCoreStores();

	const companies = UserStore.user?.companies.map((company: CompanyInstance) => {
		return { id: String(company.id), name: company.name };
	});

	const initialValues = {
		type: ExportStore.type,
		from: ExportStore.from,
		companies: ExportStore.companies,
		to: ExportStore.to,
	};

	const onSubmit = async (values: { type: ExportType; companies: string[]; from: Date; to: Date }) => {
		ExportStore.setType(values.type);
		ExportStore.setCompanies(values.companies);
		ExportStore.setFrom(values.from);
		ExportStore.setTo(values.to);

		await ExportStore.getRegistrations();
	};

	const typeOptions = {
		[ExportType.FILES]: t("DASHBOARD.OMISSION_EXPORT.FILTER.TYPES.FILES"),
		[ExportType.VOS]: t("DASHBOARD.OMISSION_EXPORT.FILTER.TYPES.VOS"),
	};

	const onSuccess = () => {
		toast.success(t("DASHBOARD.OMISSION_EXPORT.FILTER.SUCCESS_FILTER"));
	};

	return (
		<Block name={t("DASHBOARD.OMISSION_EXPORT.FILTER.TITLE")}>
			<Circle quaternary dashed r={100} x={-3} y={50} />
			<Circle primary dashed r={40} x={60} y={90} />

			<Form.Form
				identifier="ACCOUNTMANAGER_FILTER"
				initialValues={initialValues}
				onSubmit={onSubmit}
				onSuccess={onSuccess}
				submit={t("DASHBOARD.OMISSION_EXPORT.FILTER.BUTTON_APPEND")}
			>
				<Form.Item
					id="type"
					name={t("DASHBOARD.OMISSION_EXPORT.FILTER.LABEL_TYPES")}
					render={(field) => <Form.Select options={typeOptions} {...field} />}
				/>

				<Form.Item
					id="companies"
					name={t("DASHBOARD.OMISSION_EXPORT.FILTER.LABEL_COMPANIES")}
					render={(field) => <Form.Checkbox options={companies} {...field} />}
				/>

				<Form.Item
					id="from"
					name={t("DASHBOARD.OMISSION_EXPORT.FILTER.LABEL_FROM")}
					render={(field) => <Form.Date maxDate={new Date()} {...field} />}
				/>

				<Form.Item
					id="to"
					name={t("DASHBOARD.OMISSION_EXPORT.FILTER.LABEL_TO")}
					render={(field) => <Form.Date maxDate={new Date()} {...field} />}
				/>
			</Form.Form>
		</Block>
	);
});
