import React, { useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Platform } from "@Core/constants/ENUMS";
import { ForgotPasswordPage, LoginPage, RegisterPage, ResendPage, ResetPasswordPage } from "@Core/pages";
import { useCoreStores } from "@Core/stores";

import { getPublicRoutes as getPublicLNIRoutes } from "@LNI/routes/PublicRoutes";
import { getPublicRoutes as getPublicOOMRoutes } from "@OOM/routes/PublicRoutes";
import { getPublicRoutes as getPublicPNIRoutes } from "@PNI/routes/PublicRoutes";

const PublicRoutes = () => {
	const { getPlatform } = useCoreStores();

	const getRoutes = useCallback(() => {
		switch (getPlatform) {
			case Platform.LNI:
				return getPublicLNIRoutes();
			case Platform.OOM:
				return getPublicOOMRoutes();
			case Platform.PNI:
				return getPublicPNIRoutes();
		}

		return [];
	}, [getPlatform]);

	return (
		<Switch>
			{getRoutes()}

			<Route path="/inloggen" exact component={LoginPage} />
			<Route path="/aanmelden" exact component={RegisterPage} />
			<Route path="/uitnodiging-aanvragen" exact component={ResendPage} />
			<Route path="/wachtwoord-vergeten" exact component={ForgotPasswordPage} />
			<Route path="/wachtwoord-wijzigen" exact component={ResetPasswordPage} />

			<Redirect to="/inloggen" />
		</Switch>
	);
};

export default PublicRoutes;
