import React from "react";
import { Route } from "react-router-dom";

import { EmployeePage } from "@PNI/pages";

export const getPublicRoutes = () => {
	return [
		<Route
			key="/digitale-medewerkervragenlijst"
			path="/digitale-medewerkervragenlijst"
			exact
			component={EmployeePage}
		/>,
	];
};
