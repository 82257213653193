import * as Sentry from "@sentry/react";
import axios, { AxiosError, AxiosResponse } from "axios";

import AuthService from "@Core/services/AuthService";

import { QuestionnaireTokenService } from "./services";
import { getAPIUrl, getPlatform } from "./utilities";

const instance = axios.create({
	baseURL: getAPIUrl() || "",
});

instance.interceptors.request.use(
	(config) => {
		if (AuthService.isAuthenticated()) {
			config.headers.Authorization = AuthService.getHeaders();
		}

		if (QuestionnaireTokenService.getToken()) {
			config.headers["X-Token"] = QuestionnaireTokenService.getToken();
		}

		config.headers["X-Module"] = getPlatform();

		return config;
	},
	(error: AxiosError) => {
		return Promise.reject(error);
	},
);

let isRefreshing = false;
let failedQueue: { resolve: (value: unknown) => void; reject: (reason?: any) => void }[] = [];

const processQueue = (error: any, token: string) => {
	failedQueue.forEach((prom) => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});

	failedQueue = [];
};

instance.interceptors.response.use(
	(response) => response,
	(error) => {
		const originalRequest = error.config;

		if (error.response.status === 400) {
			// Return a default error generated by the API to the front-end
			return new Promise((resolve, reject) => {
				reject(error.response);
			});
		}

		if (error.response.status !== 401) {
			// Return any error which is not due to authentication back to the calling service
			Sentry.addBreadcrumb({
				type: "API_ERROR",
				level: Sentry.Severity.Debug,
				data: originalRequest.data ? JSON.parse(originalRequest.data) : "",
			});

			return new Promise((resolve, reject) => {
				reject(error);
			});
		}

		if (error.response.status === 401 && !originalRequest._retry) {
			// Fill queue till token is successfully refreshed...
			if (isRefreshing) {
				return new Promise((resolve, reject) => {
					failedQueue.push({ resolve, reject });
				})
					.then((token) => {
						originalRequest.headers["Authorization"] = "Bearer " + token;
						return axios.request(originalRequest);
					})
					.catch((err) => {
						return Promise.reject(err);
					});
			}

			originalRequest._retry = true;
			isRefreshing = true;

			// Try to refresh the accessToken with de deprecated accessToken...
			return new Promise((resolve, reject) => {
				AuthService.getNewToken()
					.then((token) => {
						originalRequest.headers["Authorization"] = "Bearer " + token;
						processQueue(null, token);
						resolve(axios.request(originalRequest));
					})
					.catch((err) => {
						processQueue(err, "");
						reject(err);
					})
					.then(() => {
						isRefreshing = false;
					});
			});
		}

		return Promise.reject(error);
	},
);

/**
 * Outputs nicely the Axios API call to the users console
 * with all the needed data.
 *
 * @param response
 * @param isError
 */
const logResponse = (response: AxiosResponse, isError: boolean) => {
	if (response) {
		console.groupCollapsed(
			`%c [${response?.config?.method?.toUpperCase()}] ${response.status} ${response.request.responseURL}`,
			`color: ${isError ? "#D11020" : "#30BF89"}`,
		);

		if (response.config.data) console.log("Payload: \n", response.config.data);
		if (response.data) console.log("Response: \n", response.data);

		console.groupEnd();
	}
};

if (process.env.NODE_ENV === "development") {
	instance.interceptors.response.use(
		(response) => {
			logResponse(response, false);
			return response;
		},

		(error) => {
			logResponse(error.response, true);
			return Promise.reject(error);
		},
	);
}

export default instance;
