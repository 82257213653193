import cn from "classnames";
import React, { ReactNode } from "react";

import styles from "./Banner.module.scss";

type Props = {
	children: ReactNode;
	className?: string;
};

const Banner = ({ children, className }: Props) => {
	const classes = cn([styles.Wrapper], {
		[className + ""]: className,
	});

	return (
		<div className={classes}>
			<div className="row">
				<div className="col-lg-1 hidden-lg-down"></div>
				<div className="col-lg-11 col-md-12">{children}</div>
			</div>
		</div>
	);
};

export default Banner;
