import { Document, Page, PDFDownloadLink, Text } from "@react-pdf/renderer";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Button } from "@Core/components/UI";

import { ReportProps } from "./Report.props";

// TODO: build PDF after design is ready
const PDF = () => {
	return (
		<Document>
			<Page size="A4" wrap>
				<Text>Placeholder</Text>
			</Page>
		</Document>
	);
};

export const Report = ({ isDisabled = false, ...props }: ReportProps) => {
	const { t } = useTranslation();

	if (isDisabled) {
		return (
			<Button iconLeft="fas fa-download" tertiary disabled={isDisabled} {...props}>
				{t("FILES.REPORT.BUTTON.DOWNLOAD")}
			</Button>
		);
	}

	return (
		// TODO: adjust path when pdf is ready
		<Button href="/Resultaten rapportage.pdf" iconLeft="fas fa-download" tertiary {...props}>
			{t("FILES.REPORT.BUTTON.DOWNLOAD")}
		</Button>
	);
};
