import nl from "date-fns/locale/nl";
import React from "react";
import { registerLocale } from "react-datepicker";
import ReactDOM from "react-dom";

import App from "./App";
import Maintenance from "./Maintenance";

registerLocale("nl", nl);

ReactDOM.render(
	!process.env.REACT_APP_REACT_MAINTENANCE || localStorage.getItem("SKIP_MAINTENANCE") === "true" ? (
		<App />
	) : (
		<Maintenance />
	),

	document.getElementById("root"),
);
