import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Form from "@Core/components/Form";
import Yup from "@Core/validation";

import Props from "./LoginForm.props";

const LoginForm = ({ initialValues, onSubmit, onSuccess }: Props) => {
	const { t } = useTranslation();
	const history = useHistory();

	const validationSchema = Yup.object().shape({
		email: Yup.string().required().email(),
		password: Yup.string().required(),
	});

	return (
		<Form.Form
			identifier="LOGIN_FORM"
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			submit={t("LOGIN.LOGIN.TITLE")}
		>
			<Form.Item
				id="email"
				name={t("LOGIN.LOGIN.LABEL_EMAIL")}
				render={(props) => <Form.Text {...props} icon="envelope" />}
			/>
			<Form.Item
				id="password"
				name={t("LOGIN.LOGIN.LABEL_PASSWORD")}
				render={(props) => <Form.Password {...props} />}
			/>

			<Form.Info
				name={t("LOGIN.LOGIN.FORGOT_PASSWORD")}
				button={t("LOGIN.LOGIN.REQUEST_NEW_PASSWORD")}
				onClick={() => history.push("/wachtwoord-vergeten")}
			/>
		</Form.Form>
	);
};

export default LoginForm;
