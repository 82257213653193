import merge from "lodash.merge";

import { FileGuidelineType, FileStepType, QuestionnaireFor, UserQuestionnaireStatus } from "@Core/constants/ENUMS";
import { FileQuestionnairesInstance } from "@Core/models";

type DeepPartial<T> = T extends Record<string, any>
	? {
			[P in keyof T]?: DeepPartial<T[P]>;
	  }
	: T;

const getFileQuestionnaireMock = (instance?: DeepPartial<FileQuestionnairesInstance>) => {
	return merge(
		{
			id: 13,
			aom_file_id: 17,
			user_questionnaire_id: 25,
			created_at: "2023-03-14T10:09:46.000000Z",
			updated_at: "2023-03-14T10:09:46.000000Z",
			phone: 0,
			mail_received: false,
			mail_id: null,
			mail_error: false,
			questionnaire_id: 10,
			questionnaire_type: "App\\Models\\AliiQuestionnaire",
			user_questionnaire: {
				id: 25,
				user_id: 9,
				questionnaire_id: 10,
				status: "PENDING",
				created_at: "2023-03-14T10:09:46.000000Z",
				updated_at: "2023-03-14T10:09:46.000000Z",
				completed_at: null,
				assignment_id: null,
				questionnaire_type: "App\\Models\\AliiQuestionnaire",
				user_type: "App\\Models\\AoM\\AomEmployee",
				current_step: {
					id: "8af9ffcf-da1c-4413-910b-4419fc901ca3",
					type: "QUESTION",
					question: {
						id: "8af9ffcf-da1c-4413-910b-4419fc901ca3",
						title: "Wat is je leeftijd?",
						text: "",
						type: "single",
						answers_required: 1,
						answers: [
							{
								id: "454a8e40-a363-476b-8339-7cbc5d744bff",
								text: "0-25",
								selected: false,
							},
							{
								id: "81413c29-8408-41ad-99a5-0125d9bcc8bf",
								text: "26-50",
								selected: false,
							},
							{
								id: "9ad22ca9-5707-4530-b329-348696642e76",
								text: "> 50",
								selected: false,
							},
						],
					},
				},
				questionnaire: {
					id: 10,
					title: "Vragenlijst werknemer",
					information: null,
					created_at: "2022-06-29T12:58:58.000000Z",
					updated_at: "2022-06-29T12:58:58.000000Z",
					category: "Advies op Maat",
					for: QuestionnaireFor.FILE_MANAGER,
					type: "",
					file_type: "",
				},
			},
			questionnaire: {
				id: 10,
				title: "Vragenlijst werknemer",
				information: null,
				created_at: "2022-06-29T12:58:58.000000Z",
				updated_at: "2022-06-29T12:58:58.000000Z",
				category: "Advies op Maat",
				for: QuestionnaireFor.FILE_MANAGER,
				type: "",
				file_type: "",
			},
		},
		instance,
	);
};

export const mock = {
	absence_started_at: "2021-01-25 15:47:38",
	absence_finished_at: null,
	recommended_absence_type: null,
	domains: {
		health: {
			score: 1,
			questions: [
				{
					title: "Heb je medische klachten?",
					answers: ["Ja"],
				},
				{
					title: "Welke medische klachten?",
					answers: ["Griep, verkoudheid, of vergelijkbare klachten"],
				},
				{
					title: "Ben je in behandeling voor je medische klachten?",
					answers: ["Nee"],
				},
				{
					title: "Kun je aangeven wat de belangrijkste oorzaak is van jouw medische klachten?",
					answers: ["Mijn medische klachten komen door mijn privésituatie"],
				},
			],
		},

		work: {
			score: 3,
			questions: [
				{
					title: "Zijn er zaken waar je tegenaan loopt op je werk?",
					answers: ["Ik heb een conflict met collega's en/of leidinggevenden"],
				},
				{
					title: "Zijn er zaken in jouw privé situatie die van invloed zijn op je werk?",
					answers: [
						"Ik kan mij moeilijk concentreren op mijn werk, omdat ik mij druk maak over mijn situatie thuis.",
					],
				},
				{
					title: "Welke positieve zaken zijn er op jouw werk van toepassing?",
					answers: [
						"Ik heb genoeg vrijheid in mijn werk ",
						"Ik heb genoeg mogelijkheden om zelf mijn werk te regelen",
						"Ik heb genoeg afwisseling in mijn werk",
						"Ik kan me genoeg ontwikkelen in mijn werk",
					],
				},
			],
		},

		motivation: {
			score: 0,
			questions: [
				{
					title: "Wat vind je leuk aan jouw werk?",
					answers: ["De werkzaamheden", "Mijn collega's", "De organisatie"],
				},
				{
					title: "Zijn er zaken waardoor je ertegenop ziet om weer aan het werk te gaan?",
					answers: ["Een conflict op mijn werk"],
				},
				{
					title: "Moedigt jouw prive omgeving je aan om weer (meer) te gaan werken?",
					answers: ["Nee"],
				},
			],
		},

		private: {
			score: 4,
			questions: [
				{
					title: "Zijn er zaken op het werk die van invloed zijn op je privé-situatie?",
					answers: ["Nee"],
				},
				{
					title: "Heb je te maken met één of meerdere van deze situaties?",
					answers: ["Ik heb geldzorgen/schulden", "Ik heb relatieproblemen/lig in scheiding"],
				},
				{
					title: "In hoeverre hebben deze situaties invloed op je functioneren?",
					answers: ["In beperkte mate"],
				},
				{
					title: "Hoe kijk jij naar je eigen leefstijl?",
					answers: [
						"Ik beweeg voldoende",
						"Ik drink beperkt of geen alcohol",
						"Ik rook niet",
						"Ik gebruik geen drugs of andere verslavende middelen",
					],
				},
				{
					title: "Wil je iets doen om je leefstijl te verbeteren?",
					answers: ["Nee"],
				},
			],
		},
	},
	characteristics: {
		risk_profile: {
			title: "Risicoprofiel",
			description:
				"Stress kan functioneel zijn, sterker nog, ieder mens heeft een bepaalde hoeveelheid stress nodig om in beweging te komen. De huidige maatschappij zorgt echter voor zoveel stress en werkdruk, dat problemen op de loer liggen. Denk aan spanningsklachten of zelfs burn-out. In dit artikel lees je hoe je medewerkers kan behoeden voor negatieve effecten van stress.",
		},
		general_questions: [
			{
				title: "Wat is je leeftijd?",
				answers: ["26-50"],
			},
			{
				title: "Welke opleiding heb je gedaan?",
				answers: ["Hbo, wo bachelor en wo master, doctor."],
			},
			{
				title: "In hoeverre ben je momenteel aan het werk?",
				answers: ["Ik ben niet aan het werk"],
			},
			{
				title: "Gebruik je op dit moment vakantiedagen?",
				answers: ["Nee"],
			},
			{
				title: "Gebruik je op dit moment een van de volgende verlofregelingen? ",
				answers: ["Nee"],
			},
		],
		coping_questions: [
			{
				title: "Hoe ga je om met tegenslagen en omstandigheden waar je stress van krijgt",
				answers: ["Ik kan vaak niet ophouden hierover te praten"],
			},
			{
				title: "Heb je voldoende mensen in je omgeving waarmee je lief en leed kan delen?",
				answers: ["Redelijk"],
			},
			{
				title: "Wat heb je nodig om je beter te voelen?",
				answers: ["Verbetering in privésituatie"],
			},
			{
				title:
					"Vind je het goed dat we jouw leidinggevende algemene artikelen sturen over de onderwerpen waar jij mee te maken hebt?",
				answers: ["Nee"],
			},
			{
				title: "Wanneer denk je weer aan het werk te kunnen gaan?",
				answers: ["05-01-2023"],
			},
			{
				title: "In hoeverre heb je er vertrouwen in dat je op deze datum je eigen werk weer kunt uitvoeren?",
				answers: ["Veel vertrouwen"],
			},
			{
				title: "Wil je nog iets meegeven aan de Praktijkondersteunend Bedrijfsarts over deze ziekmelding? ",
				answers: ["N.v.t."],
			},
		],
	},
	resources: ["Ik vind mijn werk leuk/zinvol"],
	stressors: ["Relatieproblemen", "Geldzorgen/schulden"],
	mirrored_questions: [
		"Werknemer heeft een conflict met collega's en/of leidinggevenden. De werkgever signaleert dit niet",
		"Werknemer kan niet ophouden met praten over tegenslagen en stressvolle omstandigheden. De werkgever signaleert dit niet.",
		"Volgens de werkgever doet de werknemer alsof tegenslagen en stressvolle omstandigheden er niet zijn. De werknemer signaleert dit niet",
	],
	steps: [
		// Aanbevolen
		// {
		// 	id: 1,
		// 	type: FileStepType.EMPLOYEE_QUESTIONNAIRE,
		// 	title: "Werknemervragenlijst",
		// 	description: null,
		// 	selected: true,
		// 	recommended: true,
		// 	file_questionnaire: getFileQuestionnaireMock({
		// 		user_questionnaire: {
		// 			status: UserQuestionnaireStatus.Completed,
		// 			completed_at: "2023-03-15T11:03:18Z",
		// 		},
		// 		questionnaire: { for: QuestionnaireFor.EMPLOYEE },
		// 	}),
		// },
		// {
		// 	id: 2,
		// 	type: FileStepType.SUPERVISOR_QUESTIONNAIRE,
		// 	title: "Werkgevervragenlijst",
		// 	selected: true,
		// 	recommended: false,
		// 	file_questionnaire: getFileQuestionnaireMock({
		// 		user_questionnaire: {
		// 			status: UserQuestionnaireStatus.Completed,
		// 			completed_at: "2023-03-11T11:03:18Z",
		// 		},
		// 		questionnaire: { for: QuestionnaireFor.SUPERVISOR },
		// 	}),
		// },
		{
			id: 993,
			type: FileStepType.STEP,
			title: "Mediation",
			description: "Plan een gesprek in met de medewerker en leidinggevende en eventueel een mediator.",
			selected: true,
			recommended: true,
		},
		{
			id: 994,
			type: FileStepType.STEP,
			title: "Scheidingscoach",
			selected: true,
			recommended: true,
		},
		{
			id: 995,
			type: FileStepType.STEP,
			title: "Financiële coach",
			selected: false,
			recommended: true,
		},
		{
			id: 996,
			type: FileStepType.QUESTIONNAIRE,
			title: "Checklist A - Distress",
			selected: true,
			recommended: true,
			file_questionnaire: getFileQuestionnaireMock(),
		},

		// Overige
		{
			id: 981,
			type: FileStepType.QUESTIONNAIRE,
			title: "4DKL",
			selected: false,
			recommended: false,
			file_questionnaire: getFileQuestionnaireMock(),
		},
		{
			id: 997,
			type: FileStepType.QUESTIONNAIRE,
			title: "Checklist A - Angst",
			selected: false,
			recommended: false,
			file_questionnaire: getFileQuestionnaireMock(),
		},
		{
			id: 998,
			type: FileStepType.QUESTIONNAIRE,
			title: "Checklist A - Depressie",
			selected: false,
			recommended: false,
			file_questionnaire: getFileQuestionnaireMock(),
		},
		{
			id: 9910,
			type: FileStepType.QUESTIONNAIRE,
			title: "Checklist B - Overspanning en burnout",
			selected: false,
			recommended: false,
			file_questionnaire: getFileQuestionnaireMock(),
		},
		{
			id: 999,
			type: FileStepType.QUESTIONNAIRE,
			title: "Checklist B - Motivatie",
			selected: false,
			recommended: false,
			file_questionnaire: getFileQuestionnaireMock(),
		},

		...[
			"Psycholoog/MijnCoachlijn/BMW",
			"Ouderschap&Werk",
			"Loonbaancoach",
			"Work/life balans",
			"Rouwcoach",
			"Overweeg bedrijfsmaatschappelijk werker voor stressoren",
			"Bellen ter preventie langdurig verlof",
			"Bespreken verlofsoort",
			"Advisering hoe om te gaan met werkdruk/ werkstress",
			"Advisering hoe om te gaan met conflict met collega's en/of leidinggevenden",
			"Advisering hoe om te gaan met andere belastende factoren",
			"Advisering rondom uitdagingen op werk",
			"Onderzoeken hoe privé-werk balans te versterken",
			"Onderzoeken hoe deze privé impact op werk te verbeteren",
			"Bespreekt hulpvraag, toets instrinsieke motivatie, impact van extra interventies inschatten",
			"Uitvragen: reëel beeld van mogelijkheid/ wenselijkheid van werk",
			"Check noodzaak opvragen medische informatie of SU BA",
			"Achterhalen hoe werk weer leuker kan worden",
			"Onderzoeken hoe drempels tot werkenhervatting weggenomen kunnen worden",
			"Uitvragen waarom werknemer nog niet aan het werk is",
			"Op basis van volledige vragenlijst de beste vervolgstappen bepalen tav werkhervatten",
			"Omtrend vertrouwen opnieuw aan werk te gaan",
			"Onderzoeken hoe coping/ eigen initiatief versterkt kan worden",
		].map((title, index) => {
			return {
				id: 9999 + index,
				type: FileStepType.STEP,
				title: title,
				selected: false,
				recommended: false,
			};
		}),
	],
	guidelines: [
		{
			title: "Bewegingsapparaat",
			items: [
				{
					type: FileGuidelineType.PDF,
					title: "Computerwerk",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Klachten arm, nek, schouder",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Lage Rugpijn en Lumbosacraal Radiculair Syndroom (LRS)",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Lichaamstrillingen",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Vermindering van tilbelasting om rugklachten te voorkomen",
					link: "/",
				},
			],
		},
		{
			title: "Chronische ziekten",
			items: [
				{
					type: FileGuidelineType.PDF,
					title: "Chronisch Zieken en Werk",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Diabetes en Arbeid",
					link: "/",
				},
			],
		},
		{
			title: "Hart en Vaten",
			items: [
				{
					type: FileGuidelineType.LINK,
					title: "Ischemische Hartziekten",
					link: "/",
				},
			],
		},
		{
			title: "Huid",
			items: [
				{
					type: FileGuidelineType.PDF,
					title: "Contacteczeem: Preventie, behandeling en begeleiding",
					link: "/",
				},
			],
		},
		{
			title: "Infectieziekten",
			items: [
				{
					type: FileGuidelineType.PDF,
					title: "Arbeid en Lymeziekte",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Influenza: Preventie en Outbreak Control",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Influenzapandemie in Arbeidsorganisaties",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Prikaccidenten",
					link: "/",
				},
			],
		},
		{
			title: "Kanker",
			items: [
				{
					type: FileGuidelineType.PDF,
					title: "Kanker en Werk voor het handelen van de bedrijfsarts bij behoud van en terugkeer naar werk",
					link: "/",
				},
			],
		},
		{
			title: "Luchtwegen",
			items: [
				{
					type: FileGuidelineType.PDF,
					title: "Astma en COPD",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Lasrook",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Obstructieve Slaapapneusyndroom en Werk - OSAS",
					link: "/",
				},
			],
		},
		{
			title: "Nachtwerk",
			items: [
				{
					type: FileGuidelineType.PDF,
					title: "Nachtwerk en gezondheid",
					link: "/",
				},
			],
		},
		{
			title: "Practice Guidelines in English",
			items: [
				{
					type: FileGuidelineType.PDF,
					title: "Practice Guideline Complaints of Arm, Shoulder or Neck",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title:
						"Practice Guideline to Reduce the Workload due to Lifting for Preventing Work-Related Low Back Pain",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title:
						"The Dutch Multidisciplinary Occupational Health Guideling to Enhance Work Participation Among Low Back Pain and Lumboscral Radicular Syndrome Patients",
					link: "/",
				},
			],
		},
		{
			title: "Psyche en Gedrag",
			items: [
				{
					type: FileGuidelineType.PDF,
					title: "Agressie en Geweld",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Bevorderen van Veilig Gedrag in Productieomgevingen",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Conflictsituaties in de werksituatie",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Depressie",
					link: "/",
				},
				{
					type: FileGuidelineType.LINK,
					title: "Psychische Problemen",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Werk-Privé Balans",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Werkdruk",
					link: "/",
				},
			],
		},
		{
			title: "Zintuigen",
			items: [
				{
					type: FileGuidelineType.PDF,
					title: "Computerwerk",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Gehoorstoornissen en tinnitus",
					link: "/",
				},
				{
					type: FileGuidelineType.PDF,
					title: "Preventie Beroepsslechthorendheid",
					link: "/",
				},
			],
		},
		{
			title: "Zwangerschap",
			items: [
				{
					type: FileGuidelineType.PDF,
					title: "Zwangerschap, Postpartumperiode en Werk",
					link: "/",
				},
			],
		},
	],
	note: "<p>Besloten om geen schuldcoach in te zetten. Eerst focus op conflict en relatieproblematiek.</p>",
};

export const empty = {
	absence_started_at: "2021-01-25 15:47:38",
	absence_finished_at: null,
	recommended_absence_type: null,
	domains: null,
	characteristics: null,
	resources: [],
	stressors: [],
	mirrored_questions: [],
	steps: [],
	guidelines: mock.guidelines,
	note: null,
};
