import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { FileStatus, FileType } from "@Core/constants/ENUMS";
import { BaseModel } from "@Core/models";
import { leadingZero } from "@Core/utilities";

import { UserModel } from "../UserModel";
import { UserQuestionnaireModel } from "../UserQuestionnaire/UserQuestionnaireModel";
import { EmployeeModel } from "./EmployeeModel";
import { FileQuestionnairesModel } from "./FileQuestionnairesModel";

export const FilesModel = BaseModel.props({
	status: types.enumeration<FileStatus>(Object.values(FileStatus)),
	type: types.enumeration<FileType>(Object.values(FileType)),
	company_id: types.maybeNull(types.number),
	absent_since: types.maybeNull(types.string),
	deleted_at: types.maybeNull(types.string),
	duration: types.number,
	employee: types.maybeNull(EmployeeModel),
	employee_questionnaires: types.array(FileQuestionnairesModel),
	supervisor: UserModel,
	supervisor_questionnaires: types.array(FileQuestionnairesModel),
	file_manager_questionnaires: types.array(FileQuestionnairesModel),
	vos: types.maybeNull(UserQuestionnaireModel),
	intervention_advice: types.maybeNull(UserQuestionnaireModel),
}).views((self) => ({
	get getId(): string {
		return leadingZero(self.id.toString(), 5);
	},

	get isClosed(): boolean {
		return self.status === FileStatus.CLOSED;
	},
}));

export interface FilesInstance extends Instance<typeof FilesModel> {}
export type FilesSnapshot = SnapshotIn<typeof FilesModel>;
