import { Instance, SnapshotIn, types } from "mobx-state-tree";

export const UserQuestionnaireThemeModel = types.model("UserQuestionnaireThemeModel", {
	id: types.identifierNumber,
	name: types.string,
	description: types.maybeNull(types.string),
	theme_group_id: types.number,
});

export interface UserQuestionnaireThemeInstance extends Instance<typeof UserQuestionnaireThemeModel> {}
export type UserQuestionnaireThemeSnapshot = SnapshotIn<typeof UserQuestionnaireThemeModel>;
