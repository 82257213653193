import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Menu, MenuItem } from "@Core/components/UI";
import { useCoreStores } from "@Core/stores";

import { Page } from "@PNI/components/Layouts";
import { usePNIStores } from "@PNI/stores";

import {
	DashboardArticles,
	DashboardFiles,
	DashboardInbox,
	DashboardOmission,
	DashboardOmissionExport,
} from "./internal/components";

const DashboardPage = () => {
	const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
	const { t } = useTranslation();
	const { InboxStore } = usePNIStores();
	const { UserStore } = useCoreStores();

	useEffect(() => {
		const items: MenuItem[] = [
			{
				label: t("DASHBOARD.MENU.DASHBOARD"),
				to: "#root",
				icon: "home",
			},
		];

		if (UserStore.hasFilesPermissions) {
			items.push({
				label: t("DASHBOARD.MENU.FILES"),
				to: "#files",
				icon: "file",
			});
		}

		if (UserStore.hasOmissionExportPermissions) {
			items.push({
				label: t("DASHBOARD.MENU.OMISSION_EXPORT"),
				to: "#omission-export",
				icon: "file-export",
			});
		}

		if (UserStore.hasOmissionPermissions) {
			items.push({
				label: t("DASHBOARD.MENU.VOS"),
				to: "#verzuim-oorzaak-scan",
				icon: "poll",
			});
		}

		if (UserStore.hasArticlesPermissions) {
			items.push({
				label: t("DASHBOARD.MENU.ARTICLES"),
				to: "#articles",
				icon: "newspaper",
			});
		}

		if (UserStore.hasInboxPermissions) {
			items.push({
				label: t("DASHBOARD.MENU.INBOX"),
				to: "#inbox",
				icon: "inbox",
				count: InboxStore.messages.filter((message) => message.is_read === false).length,
			});
		}

		setMenuItems(items);
	}, [
		UserStore.hasFilesPermissions,
		UserStore.hasOmissionPermissions,
		UserStore.hasArticlesPermissions,
		UserStore.hasInboxPermissions,
		UserStore.hasContactsPermissions,
	]);

	return (
		<Page>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<Menu menuItems={menuItems} />
					</div>
				</div>

				{UserStore.hasFilesPermissions && (
					<div id="files" className="row">
						<div className="col-lg-12">
							<DashboardFiles />
						</div>
					</div>
				)}

				{UserStore.hasOmissionExportPermissions && (
					<div id="omission-export" className="row">
						<div className="col-lg-12">
							<DashboardOmissionExport />
						</div>
					</div>
				)}

				{UserStore.hasOmissionPermissions && (
					<div id="verzuim-oorzaak-scan" className="row">
						<div className="col-lg-12">
							<DashboardOmission />
						</div>
					</div>
				)}

				{UserStore.hasArticlesPermissions && (
					<div id="articles" className="row">
						<div className="col-lg-12">
							<DashboardArticles />
						</div>
					</div>
				)}

				{UserStore.hasInboxPermissions && (
					<div id="inbox" className="row">
						<div className="col-lg-12">
							<DashboardInbox />
						</div>
					</div>
				)}
			</div>
		</Page>
	);
};

export default observer(DashboardPage);
