import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next/";

import styles from "./Step.module.scss";

type Props = {
	step: number;
	steps: number;
	question?: boolean;
	hide?: boolean;
	dots?: boolean;
	points?: boolean;
	className?: any;
};

const Step = ({ step, steps, question, points, dots, hide, className }: Props) => {
	const { t } = useTranslation();

	const wrapper = cn([styles.Wrapper], {
		[styles.Points]: points,
		[styles.Dots]: dots,
		[className]: className,
	});

	const classes = cn([], {
		[styles.ProgressBar]: !question && !dots,
		[styles.ProgressSteps]: question || dots,
	});

	const getProgressSteps = () => {
		const s = [];

		for (let i = 0; i < steps; i++) {
			const classes = cn([styles.Step], {
				[styles.Active]: i < step - 1,
				[styles.Current]: i === step - 1,
			});

			s.push(<div key={i} className={classes}></div>);
		}

		return s;
	};

	return (
		<div className={wrapper}>
			<div className={classes}>
				{!question && !dots && (
					<div className={styles.Progress} style={{ width: `${(step / steps) * 100}%` }}></div>
				)}

				{question || (dots && getProgressSteps())}
			</div>

			{!hide && (
				<div className={styles.Title}>
					{question ? <span>{t("DEFAULT.QUESTION")}</span> : <span>{t("DEFAULT.STEP")}</span>} {step} /{" "}
					<strong>{steps}</strong>
				</div>
			)}
		</div>
	);
};

export default Step;
