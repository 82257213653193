import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next/";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import { Button, CardArticle } from "@Core/components/UI";
import { ArticleInstance } from "@Core/models";

import styles from "./ArticleList.module.scss";

type Props = {
	articles: ArticleInstance[];
	pagination?: boolean;
	readmore?: boolean;
	onClick?: (article?: any) => void;
	items: number;
};

SwiperCore.use([Navigation, Pagination]);

const ArticleList = ({ articles, pagination, items, readmore, onClick }: Props) => {
	const { t } = useTranslation();
	const [, setRender] = useState(false);

	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const paginationRef = useRef(null);

	if (articles) {
		return (
			<div className={styles.Container}>
				<Swiper
					spaceBetween={20}
					slidesPerView={items}
					allowTouchMove={false}
					translate="no"
					centeredSlides={false}
					navigation={{
						prevEl: prevRef.current || null,
						nextEl: nextRef.current || null,
						disabledClass: styles.ButtonDisabled,
					}}
					pagination={{
						bulletActiveClass: styles.BulletActive,
						bulletClass: styles.Bullet,
						el: paginationRef.current || null,
					}}
					onInit={(swiper) => {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						// eslint-disable-next-line no-param-reassign
						swiper.params.navigation.prevEl = prevRef.current;
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						// eslint-disable-next-line no-param-reassign
						swiper.params.navigation.nextEl = nextRef.current;
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						// eslint-disable-next-line no-param-reassign
						swiper.params.pagination.el = paginationRef.current;
						// swiper.navigation.update();
						setRender(true);
					}}
					breakpoints={{
						0: {
							slidesPerView: Math.min(items, 1),
						},
						576: {
							slidesPerView: Math.min(items, 2),
						},
						992: {
							slidesPerView: Math.min(items, 3),
						},
						1200: {
							slidesPerView: Math.min(items, 4),
						},
					}}
				>
					{articles.map((article: ArticleInstance, key: number) => (
						<SwiperSlide key={`${article.id}-${key}`}>
							<CardArticle article={article} readmore={readmore} />

							{onClick && (
								<Button primary block className="mt-sm" onClick={() => onClick(article)}>
									{t("FILES.RECOMMENDED_ARTICLES.RECOMMEND")}
								</Button>
							)}
						</SwiperSlide>
					))}
				</Swiper>

				<div className={styles.Pagination}>
					{pagination && <div ref={paginationRef} className={styles.PaginationBullets}></div>}

					<div ref={prevRef}>
						<Button primary link hover iconLeft="fa-long-arrow-left">
							{t("SHARED.PREVIOUS")}
						</Button>
					</div>

					<div ref={nextRef}>
						<Button primary link hover iconRight="fa-long-arrow-right">
							{t("SHARED.NEXT")}
						</Button>
					</div>
				</div>
			</div>
		);
	}

	return <></>;
};

export default ArticleList;
