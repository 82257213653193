import React from "react";
import { useTranslation } from "react-i18next/";

import { Platform } from "@Core/constants/ENUMS";
import { AuthService } from "@Core/services";
import { useCoreStores } from "@Core/stores";
import { getKennisbankToken, isProduction } from "@Core/utilities";

import styles from "./Footer.module.scss";

const Footer = () => {
	const { t } = useTranslation();
	const { UserStore } = useCoreStores();

	const setToggler = (platform: Platform) => {
		AuthService.logout();

		localStorage.setItem("REACT_APP_MODULE", platform);
		window.location.reload();
	};

	const getToggler = () => {
		const { getPlatform } = useCoreStores();

		if (getPlatform === Platform.LNI) {
			return (
				<div className={styles.TogglerContainer}>
					<button
						title="Professional & Inzetbaarheid"
						onClick={() => setToggler(Platform.PNI)}
						className={styles.Toggler}
					>
						<i className="far fa-address-card"></i>
					</button>

					<button
						title="Organisatieadvies op Maat"
						onClick={() => setToggler(Platform.OOM)}
						className={styles.Toggler}
					>
						<i className="far fa-users"></i>
					</button>
				</div>
			);
		}

		if (getPlatform === Platform.OOM) {
			return (
				<div className={styles.TogglerContainer}>
					<button
						title="Professional & Inzetbaarheid"
						onClick={() => setToggler(Platform.PNI)}
						className={styles.Toggler}
					>
						<i className="far fa-address-card"></i>
					</button>

					<button
						title="Leiderschap & Inzetbaarheid"
						onClick={() => setToggler(Platform.LNI)}
						className={styles.Toggler}
					>
						<i className="far fa-users-crown"></i>
					</button>
				</div>
			);
		}

		if (getPlatform === Platform.PNI) {
			return (
				<div className={styles.TogglerContainer}>
					<button
						title="Leiderschap & Inzetbaarheid"
						onClick={() => setToggler(Platform.LNI)}
						className={styles.Toggler}
					>
						<i className="far fa-users-crown"></i>
					</button>

					<button
						title="Organisatieadvies op Maat"
						onClick={() => setToggler(Platform.OOM)}
						className={styles.Toggler}
					>
						<i className="far fa-users"></i>
					</button>
				</div>
			);
		}
	};

	return (
		<footer className={styles.Wrapper}>
			{!isProduction() && getToggler()}

			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className={styles.Container}>
							<div className={styles.Copyright}>
								&copy; {new Date().getFullYear()} {t("SHARED.MENSELY")}
							</div>

							<div className={styles.Disclaimer}>
								{UserStore.isAuthenticated ? (
									<a
										href={`${t("SHARED.KENNISBANK_URL")}blog?token=${getKennisbankToken()}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{t("SHARED.KENNISBANK_NAME")}
									</a>
								) : (
									<a href={t("SHARED.KENNISBANK_URL")} target="_blank" rel="noopener noreferrer">
										{t("SHARED.KENNISBANK_NAME")}
									</a>
								)}

								<a href={t("SHARED.DISCLAIMER_URL")} target="_blank" rel="noopener noreferrer">
									{t("SHARED.DISCLAIMER_NAME")}
								</a>

								<a href={t("SHARED.PRIVACY_URL")} target="_blank" rel="noopener noreferrer">
									{t("SHARED.PRIVACY_NAME")}
								</a>

								<a href={t("SHARED.TERMS_URL")} target="_blank" rel="noopener noreferrer">
									{t("SHARED.TERMS_NAME")}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
