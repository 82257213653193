import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseModel, UserQuestionnaireModel } from "@Core/models";

export const CompanyRoleQuestionnaireModel = BaseModel.props({
	oom_company_role_id: types.number,
	user_questionnaire_id: types.number,
	phone: types.boolean,
	user_questionnaire: UserQuestionnaireModel,
});

export interface CompanyRoleQuestionnaireInstance extends Instance<typeof CompanyRoleQuestionnaireModel> {}
export type CompanyRoleQuestionnaireSnapshot = SnapshotIn<typeof CompanyRoleQuestionnaireModel>;
