import React, { useState } from "react";
import { useTranslation } from "react-i18next/";

import { Login } from "@Core/components/Layouts";
import { ForgotPasswordForm } from "@Core/forms";
import { ForgotPasswordFormValues } from "@Core/forms/ForgotPasswordForm/ForgotPasswordForm.props";
import { AuthService } from "@Core/services";

const ForgotPasswordPage = () => {
	const { t } = useTranslation();
	const [verify, setVerify] = useState(false);

	const initialValues: ForgotPasswordFormValues = {
		email: "",
	};

	const onSubmit = (values: ForgotPasswordFormValues) => {
		return AuthService.forgotPassword(values);
	};

	const onSuccess = () => {
		setVerify(true);
	};

	const title = t("LOGIN.FORGOT.TITLE");
	let description = t("LOGIN.FORGOT.SUBTITLE");
	let form = <ForgotPasswordForm initialValues={initialValues} onSubmit={onSubmit} onSuccess={onSuccess} />;

	if (verify) {
		description = t("LOGIN.FORGOT.REMEMBER_PASSWORD_SEND");
		form = <></>;
	}

	return (
		<Login title={title} description={description}>
			{form}
		</Login>
	);
};

export default ForgotPasswordPage;
