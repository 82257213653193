import { format } from "date-fns";

import axios from "@Core/axios";

export enum TOKEN_STATUS {
	TOKEN_STATUS_VALID = "VALID",
	TOKEN_STATUS_EXPIRED = "EXPIRED",
	TOKEN_STATUS_PROCESSED = "PROCESSED",
}

class QuestionnaireTokenService {
	LOCAL_STORAGE_TOKEN = "REACT_APP_X-TOKEN";

	validateToken = async (token: string): Promise<null> => {
		const data = {
			params: {
				token: token,
			},
		};

		const response = await axios.post(`questionnairetokens/validate`, {}, data);

		if (response && response.data) {
			return response.data;
		}

		return null;
	};

	validateUser = async (token: string, birthdate?: string, zipcode?: string): Promise<any> => {
		const data = {
			token: token,
			date_of_birth: birthdate ? format(new Date(birthdate), "dd-MM-yyyy") : undefined,
			zipcode: zipcode,
		};

		const response = await axios.post(`questionnairetokens/token`, data);

		if (response && response.data) {
			this.setToken(response.data.token);
			return response.data;
		}

		return null;
	};

	me = async (): Promise<any> => {
		const response = await axios.get("questionnairetokens/me");

		if (response && response.data) {
			return response.data;
		}

		return null;
	};

	setToken = (token: string): void => {
		localStorage.setItem(this.LOCAL_STORAGE_TOKEN, token);
	};

	getToken = (): string | null => {
		return localStorage.getItem(this.LOCAL_STORAGE_TOKEN);
	};

	logout = (): void => {
		localStorage.removeItem(this.LOCAL_STORAGE_TOKEN);
	};
}

export default new QuestionnaireTokenService();
