import cn from "classnames";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

import styles from "./Navigation.module.scss";

type NavigationProps = {
	className?: string;
	children: ReactNode;
};

const Navigation = ({ className, children }: NavigationProps) => {
	return <div className={cn(styles.Navigation, className)}>{children}</div>;
};

type NavigationItemProps = {
	to: string;
	className?: string;
	children: ReactNode;
};

const NavigationItem = ({ to, className, children }: NavigationItemProps) => {
	return (
		<NavLink to={to} activeClassName={styles.isActive} exact className={cn(styles.NavigationItem, className)}>
			{children}
		</NavLink>
	);
};

export default Object.assign(Navigation, {
	Item: NavigationItem,
});
