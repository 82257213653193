import { FormikValues } from "formik";
import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { UserQuestionnaireQuestionType } from "@Core/constants/ENUMS";
import Yup from "@Core/validation";

import { UserQuestionnaireAnswerInstance, UserQuestionnaireAnswerModel } from "./UserQuestionnaireAnswerModel";
import { UserQuestionnairesAdditionalModel } from "./UserQuestionnairesAdditionalModel";
import { UserQuestionnaireStepInstance } from "./UserQuestionnaireStepModel";

export const UserQuestionnaireQuestionModel = types
	.model("UserQuestionnaireQuestionModel", {
		id: types.union(types.string, types.number),
		user_answer: types.maybeNull(types.string),
		answers_required: types.number,
		type: types.enumeration<UserQuestionnaireQuestionType>(Object.values(UserQuestionnaireQuestionType)),
		title: types.string,
		text: types.maybeNull(types.string),
		min: types.maybeNull(types.number),
		max: types.maybeNull(types.number),
		step: types.maybeNull(types.number),
		scale_low: types.maybeNull(types.string),
		scale_high: types.maybeNull(types.string),
		answers: types.array(UserQuestionnaireAnswerModel),
		additionalQuestionnaires: types.maybeNull(types.array(UserQuestionnairesAdditionalModel)),
	})
	.views((self) => ({
		getAnswer(step: UserQuestionnaireStepInstance) {
			const values: FormikValues = [];

			switch (self.type) {
				case UserQuestionnaireQuestionType.Single:
				case UserQuestionnaireQuestionType.Multiple:
					values[`S${step.id}Q${self.id}`] = self.answers
						.filter((answer: UserQuestionnaireAnswerInstance) => answer.selected === true)
						.map((item: UserQuestionnaireAnswerInstance) => {
							if (item.open_input && item.user_answer) {
								values[`Q${self.id}A${item.id}_OPEN`] = item.user_answer;
							}

							return "" + item.id;
						});
					break;
				case UserQuestionnaireQuestionType.SingleDropdown:
					const selected = self.answers.find(
						(answer: UserQuestionnaireAnswerInstance) => answer.selected === true,
					);

					values[`S${step.id}Q${self.id}`] = selected ? `${selected.id}` : "";
					break;
				case UserQuestionnaireQuestionType.Statement:
				case UserQuestionnaireQuestionType.Scale:
				case UserQuestionnaireQuestionType.ScaleEmoji:
					values[`S${step.id}Q${self.id}`] = self.answers
						.filter((answer: UserQuestionnaireAnswerInstance) => answer.selected === true)
						.map((item: UserQuestionnaireAnswerInstance) => {
							values[`S${step.id}Q${self.id}`] = "" + item.id;
							return item.id + "";
						});
					break;
				case UserQuestionnaireQuestionType.ScaleSlider:
					self.answers
						.filter((answer: UserQuestionnaireAnswerInstance) => answer.selected === true)
						.map((item: UserQuestionnaireAnswerInstance) => {
							values[`S${step.id}Q${self.id}`] = `${item.id}`;
							return null;
						});

					if (!values[`S${step.id}Q${self.id}`]) {
						values[`S${step.id}Q${self.id}`] = `${self.answers[0].id}`;
					}
					break;
				default:
					values[`S${step.id}Q${self.id}`] = self.user_answer || "";
					break;
			}

			return values;
		},

		getAnswerLabel() {
			switch (self.type) {
				case UserQuestionnaireQuestionType.Single:
				case UserQuestionnaireQuestionType.Multiple:
				case UserQuestionnaireQuestionType.SingleDropdown:
				case UserQuestionnaireQuestionType.Statement:
				case UserQuestionnaireQuestionType.Scale:
				case UserQuestionnaireQuestionType.ScaleEmoji:
				case UserQuestionnaireQuestionType.ScaleSlider:
					return self.answers
						.filter((answer: UserQuestionnaireAnswerInstance) => answer.selected === true)
						.map((item: UserQuestionnaireAnswerInstance) => {
							if (item.open_input && item.user_answer) {
								return `${item.text} "${item.user_answer}"`;
							}

							return item.text;
						});
				default:
					return [self.user_answer];
			}
		},

		getValidation(step: UserQuestionnaireStepInstance) {
			const validation: Record<string, any> = [];

			switch (self.type) {
				case UserQuestionnaireQuestionType.Multiple:
					validation[`S${step.id}Q${self.id}`] = Yup.array()
						// eslint-disable-next-line
						.min(self.answers_required, 'MIN_ANSWERS={"min":${min}}')
						.required();
					break;
				default:
					validation[`S${step.id}Q${self.id}`] = Yup.string().required();
					break;
			}

			return Yup.object().shape(validation);
		},
	}));

export interface UserQuestionnaireQuestionInstance extends Instance<typeof UserQuestionnaireQuestionModel> {}
export type UserQuestionnaireQuestionSnapshot = SnapshotIn<typeof UserQuestionnaireQuestionModel>;
