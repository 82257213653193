import React from "react";

import { IFieldProps } from "@Core/interfaces";
import { UserQuestionnaireAnswerInstance, UserQuestionnaireQuestionInstance } from "@Core/models";

import styles from "./Statement.module.scss";

type Props = {
	id: string;
	name: string;
	field: IFieldProps;

	disabled: boolean;
	question: UserQuestionnaireQuestionInstance;
	placeholder?: string;
};

const Statement = ({ id, name, field, disabled, question, placeholder }: Props) => {
	return (
		<div className={styles.Inputs}>
			{question.answers.map((answer: UserQuestionnaireAnswerInstance) => (
				<div key={answer.id} className={styles.Statement}>
					<input
						disabled={disabled}
						id={`Q${question.id}A${answer.id}`}
						type="radio"
						{...field}
						defaultChecked={answer.selected}
						value={answer.id}
					/>
					<label htmlFor={`Q${question.id}A${answer.id}`}>{answer.text}</label>
				</div>
			))}
		</div>
	);
};

export default Statement;
