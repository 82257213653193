import cn from "classnames";
import React from "react";

import { IFieldProps } from "@Core/interfaces";

import styles from "./Select.module.scss";

type Props = {
	id: string;
	name: string;
	field: IFieldProps;
	disabled?: boolean;
	options: Record<string, string>;
	placeholder?: string;
	onChange?: (value: string) => any;
};

const Select = ({ id, name, field, options, placeholder, onChange, disabled }: Props) => {
	return (
		<div className={cn([styles.Field], { [styles.FieldEmpty]: field.value === "" })}>
			<select
				id={id}
				className={styles.Input}
				{...field}
				onChange={(e) => {
					if (onChange) onChange(e.target.value);

					field.onChange(e);
				}}
			>
				{placeholder && (
					<option value="" disabled>
						{placeholder}
					</option>
				)}

				{Object.keys(options).map((key) => (
					<option key={key} value={key}>
						{options[key]}
					</option>
				))}
			</select>

			<i className={`${styles.Icon} far fa-chevron-down`}></i>
		</div>
	);
};

export default Select;
