import React, { ReactNode, useState } from "react";

import { Icon } from "@Core/components/UI";
import { PublicationElementInstance } from "@Core/models";

import Popup from "../Popup/Popup";
import styles from "./Text.module.scss";

type Props = {
	children: ReactNode;
	document?: PublicationElementInstance;
};

const Text = ({ children, document }: Props) => {
	const [visible, setVisible] = useState(false);

	const onClick = (type: string) => {
		if (document) {
			switch (type) {
				case "Webpage":
					window.open(document.url, "_blank");
					break;
				case "Document":
					window.open(document.url, "_blank");
					break;
				case "Video":
					handlePopup();
					break;
			}
		}
	};

	const handlePopup = () => {
		const value = !visible;
		setVisible(value);
	};

	const getIcon = (type: string) => {
		switch (type) {
			case "Webpage":
				return "far fa-link";
			case "Video":
				return "far fa-video";
			case "Document":
				return "far fa-file-alt";
			default:
				return "far fa-hearth";
		}
	};

	return (
		<div className="row">
			<div className="col-xl-1 hidden-lg-down"></div>

			<div className="col-xl-7 col-lg-8 col-md-12">
				<div className={styles.Text}>{children}</div>
			</div>

			{document && (
				<div className="col-xl-4 col-lg-4 col-md-12">
					<div onClick={() => onClick(document.type)} className={styles.File}>
						<Icon secondary icon={getIcon(document.type)} />

						<p>{document.title}</p>
					</div>
				</div>
			)}

			{document && document.type === "Video" && (
				<Popup title={document.title} video={document.url} visible={visible} onClose={handlePopup} />
			)}
		</div>
	);
};

export default Text;
