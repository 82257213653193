import merge from "lodash.merge";
import { applySnapshot, flow, Instance, types } from "mobx-state-tree";

import { FileModel, FilesInstance, FilesModel } from "@Core/models";
import { EmployeeInstance } from "@Core/models/File/EmployeeModel";
import { FileService } from "@Core/services";
import { ConnectEmployeeProps } from "@Core/services/FileService";
import CoreRootStore from "@Core/stores/CoreRootStore";
import { isProduction } from "@Core/utilities";

import { GenerateFileFormValues } from "@PNI/forms";
import { EmployeeService } from "@PNI/services";

import { empty, mock } from "./mock";

const FileStore = types
	.model("FileStore", {
		files: types.array(FilesModel),
		archived: types.array(FilesModel),
		file: types.maybeNull(FileModel),
	})
	.actions((self) => ({
		load: flow(function* () {
			const companyId = CoreRootStore.UserStore.getCompanyId;

			const response = yield Promise.all([
				FileService.getFiles(companyId),
				FileService.getFiles(companyId, true),
			]);

			if (response) {
				self.files.clear();
				self.archived.clear();

				if (response[0] && response[0].length > 0) {
					self.files = response[0];
				}

				if (response[1] && response[1].length > 0) {
					self.archived = response[1];
				}
			}
		}),

		createFile: flow(function* (values: GenerateFileFormValues) {
			try {
				const companyId = CoreRootStore.UserStore.getCompanyId;
				return yield FileService.createFile(companyId, values);
			} catch (e) {
				throw new Error(`FILE_NOT_CREATED`);
			}
		}),

		sendQuestionnaire: flow(function* (questionnaireId: number) {
			try {
				if (self.file) {
					return yield FileService.sendQuestionnaire(self.file.id, questionnaireId);
				}
			} catch (e) {
				throw new Error(e);
			}
		}),

		connectEmployee: flow(function* (values: ConnectEmployeeProps) {
			try {
				if (self.file) {
					const response = yield FileService.connectEmployee(self.file.id, values);

					self.file = response;
				}
			} catch (e) {
				throw new Error(e);
			}
		}),

		updateEmployee: flow(function* (values: EmployeeInstance) {
			try {
				if (self.file && self.file.employee) {
					const response = yield EmployeeService.update(self.file.employee.id, values);
					self.file.employee = response;
				}
			} catch (e) {
				throw new Error(e);
			}
		}),

		clearFile() {
			self.file = null;
		},

		getFile: flow(function* (file: string, reload = false) {
			// if (reload || !self.file || self.file.id !== parseInt(file)) { TODO: Yanick: Put back later when API fixed
			if (reload || !self.file || self.file.id !== parseInt(file)) {
				try {
					const response = yield FileService.getFile(parseInt(file));
					delete response.characteristics;

					if (file === "1") {
						empty.steps = response.steps
							.concat(empty.steps)
							.filter((v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i);

						self.file = merge(response, self.file ? self.file : empty);
					} else {
						mock.steps = response.steps
							.concat(mock.steps)
							.filter((v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i);

						self.file = merge(response, self.file ? self.file : mock);
					}
				} catch (e) {
					if (!isProduction()) {
						console.error(e);
					}

					throw new Error(`FILE_NOT_FOUND`);
				}
			}
		}),

		getOmission: flow(function* (file: number) {
			try {
				if (self.file && !self.file.vos) {
					const response = yield FileService.startOmission(file);

					self.file.vos = response;

					return response.id;
				}
			} catch (e) {
				throw new Error(`VOS_NOT_STARTED`);
			}
		}),
	}));

export interface FileStoreInstance extends Instance<typeof FileStore> {}

export default FileStore;
