import cn from "classnames";
import React from "react";

import styles from "./Icon.module.scss";

type Props = {
	icon?: string;
	success?: boolean;
	danger?: boolean;
	secondary?: boolean;
	transparent?: boolean;
	small?: boolean;
	large?: boolean;
	className?: string;
};

const Icon = ({ success, danger, small, large, icon, secondary, className, transparent }: Props) => (
	<div
		className={cn([styles.Wrapper], className, {
			[styles.Secondary]: secondary,
			[styles.Transparent]: transparent,
			[styles.Success]: success,
			[styles.Danger]: danger,
			[styles.Small]: small,
			[styles.Large]: large,
		})}
	>
		<i className={icon}></i>
	</div>
);

export default Icon;
