import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next/";

import personal from "@Core/assets/images/illustrations/personal.png";
import { Sidebar } from "@Core/components/Layouts";
import { Frame } from "@Core/components/UI";
import { useDialog } from "@Core/hooks";

import { FileQuestions } from "@PNI/components/Containers";

import styles from "./Profile.module.scss";
import { ProfileProps } from "./Profile.props";

export const Profile = ({ characteristics }: ProfileProps) => {
	const { isVisible, toggle } = useDialog();
	const { t } = useTranslation();

	return (
		<>
			<div className={cn(styles.Profile, { [styles.hasClick]: characteristics })} onClick={toggle}>
				<img src={personal} alt={t("FILES.DOMAINS.CHARACTERISTICS")} />

				<span>{t("FILES.DOMAINS.CHARACTERISTICS")}</span>
			</div>

			{characteristics && (
				<Sidebar title={t("FILES.DOMAINS.CHARACTERISTICS")} isVisible={isVisible} onClose={toggle}>
					<hr className="mb-4" />

					{characteristics.general_questions && (
						<Frame title={t("FILES.DOMAINS.GENERAL_QUESTIONS")} className="mb-md">
							<Frame.Body padding="3">
								<FileQuestions value={characteristics.general_questions} />
							</Frame.Body>
						</Frame>
					)}

					{characteristics.coping_questions && (
						<Frame title={t("FILES.DOMAINS.COPING_QUESTIONS")}>
							<Frame.Body padding="3">
								<FileQuestions value={characteristics.coping_questions} />
							</Frame.Body>
						</Frame>
					)}
				</Sidebar>
			)}
		</>
	);
};
