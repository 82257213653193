import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";

import { useCoreStores } from "@Core/stores";

import { Page } from "@PNI/components/Layouts";
import { usePNIStores } from "@PNI/stores";

import FilePageHeader from "./internal/components/FilePageHeader";

const FilePage = () => {
	const { t } = useTranslation();
	const { file } = useParams<{ file: string }>();
	const history = useHistory();

	const { UserStore } = useCoreStores();
	const { FileStore } = usePNIStores();

	const breadcrumbs = [
		{
			name: t("FILES.PAGE_TITLE", {
				id: FileStore.file?.getId,
			}),
		},
	];

	useEffect(() => {
		(async () => {
			try {
				await FileStore.getFile(file, true);
			} catch (e) {
				history.push("");
			}
		})();
	}, [file, FileStore, history]);

	const getFile = () => {
		if (FileStore.file && UserStore.user) {
			return (
				<>
					<FilePageHeader
						title={t("FILES.PAGE_TITLE", {
							id: FileStore.file.getId,
						})}
						close
					/>

					<div className="container">
						{/* {UserStore.company?.hr_can_send_questionnaires && (
							<>
								<div className="row">
									<div className="col-lg-12">
										<FilePageEmployee />
										!!
									</div>
								</div>

								<div className="row">
									<div className="col-lg-12">
										<FilePageSupervisor />
									</div>
								</div>
							</>
						)} */}

						<div className="row">
							<div className="col-lg-12">
								{/* TODO: Yanick: Replace me later with the new component, when we fine tune the HR role. */}
							</div>
						</div>
					</div>
				</>
			);
		}
	};

	return <Page breadcrumbs={breadcrumbs}>{FileStore.file?.id === parseInt("" + file) && getFile()}</Page>;
};

// const FilePageEmployee = observer(() => {
// 	const { t } = useTranslation();
// 	const { FileStore } = usePNIStores();

// 	if (FileStore.file && FileStore.file.employee) {
// 		return (
// 			<Block name={t("FILES.QUESTIONS_EMPLOYEE", { name: FileStore.file.employee.getName || "-" })}>
// 				<QuestionnaireList
// 					questionnaires={FileStore.file.employee_questionnaires}
// 					additional={QuestionnaireFor.EMPLOYEE}
// 					open={false}
// 				/>
// 			</Block>
// 		);
// 	}

// 	return <></>;
// });

// const FilePageSupervisor = observer(() => {
// 	const { t } = useTranslation();
// 	const { FileStore } = usePNIStores();

// 	if (FileStore.file) {
// 		return (
// 			<Block name={t("FILES.QUESTIONS_SUPERVISOR", { name: FileStore.file.supervisor.getName })}>
// 				<QuestionnaireList
// 					questionnaires={FileStore.file.supervisor_questionnaires}
// 					additional={QuestionnaireFor.SUPERVISOR}
// 					open={false}
// 				/>
// 			</Block>
// 		);
// 	}

// 	return <></>;
// });

export default observer(FilePage);
