import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { AccountType } from "@Core/constants/ENUMS";

export const ArticleRecommendationModel = types.model("ArticleRecommendationModel", {
	message: types.maybeNull(types.string),
	recommended_by: types.model({
		account_type: types.enumeration<AccountType>(Object.values(AccountType)),
	}),
});

export interface ArticleRecommendationInstance extends Instance<typeof ArticleRecommendationModel> {}
export type ArticleRecommendationSnapshot = SnapshotIn<typeof ArticleRecommendationModel>;
