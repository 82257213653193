import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseModel } from "@Core/models";

export const EmployeeModel = BaseModel.props({
	email: types.string,
	phone_number: types.string,
	first_name: types.string,
	last_name: types.string,
	date_of_birth: types.string,
	zipcode: types.string,
	external_id: types.maybeNull(types.string),
}).views((self) => ({
	get getName(): string {
		return `${self.first_name} ${self.last_name}`;
	},
}));

export interface EmployeeInstance extends Instance<typeof EmployeeModel> {}
export type EmployeeSnapshot = SnapshotIn<typeof EmployeeModel>;
