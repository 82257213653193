import axios from "@Core/axios";
import { ExportType } from "@Core/constants/ENUMS";
import { formatDate } from "@Core/utilities";

class ExportService {
	getRegistrations = async (type: ExportType, companies: string[], from: Date, to: Date): Promise<null> => {
		const data = {
			companies: companies,
			from: from.toISOString(),
			to: to.toISOString(),
		};

		const response = await axios.post(`export/${type}/registrations`, data);

		if (response && response.data) {
			return response.data;
		}

		return null;
	};

	getCSV = async (type: ExportType, companies: string[], from: Date, to: Date): Promise<null> => {
		const data = {
			companies,
			from: from.toISOString(),
			to: to.toISOString(),
		};

		const response = await axios.post(`export/${type}/csv`, data);

		if (response) {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			const fileName = `${formatDate(new Date().toISOString())}.csv`;
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
		}

		return null;
	};
}

export default new ExportService();
