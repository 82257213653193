import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import { Button } from "@Core/components/UI";
import { useCoreStores } from "@Core/stores";
import { parseHTML } from "@Core/utilities";

import { usePNIStores } from "@PNI/stores";

import styles from "./FilePageHeader.module.scss";

type Props = {
	title: string;
	description?: string | null;
	close?: boolean;
};

const FilePageHeader = ({ title, description, close }: Props) => {
	const { t } = useTranslation();
	const { UserStore } = useCoreStores();
	const { FileStore } = usePNIStores();

	const closeFile = async () => {
		try {
			await FileStore.file?.close();
			await Promise.all([UserStore.load(), FileStore.load()]);

			toast.success(t("FILES.CLOSE.SUCCESS"));
		} catch (e) {
			toast.error(t("FILES.CLOSE.ERROR"));
		}
	};

	if (FileStore.file) {
		return (
			<div className="container">
				<div className="row">
					<div className="col-lg-8">
						<div className={styles.FilePageHeader}>
							<div>
								<h2 className="color-primary">{title}</h2>

								{parseHTML(description, "mb-sm")}

								<div className={styles.People}>
									<div>
										<i className="fas fa-user"></i>
										{FileStore.file.employee?.getName || "-"}
									</div>

									<div>
										<i className="fas fa-user-tie"></i>
										{FileStore.file.supervisor.getName}
									</div>
								</div>

								<div className={styles.Data}>
									<div>
										{t("FILES.STATUS", {
											status: t(`FILES.STATUS_${FileStore.file.status}`),
										})}
									</div>

									<div>
										{t("FILES.TYPE", {
											type: t(`FILES.TYPE_${FileStore.file.type}`),
										})}
									</div>

									<div>
										{t("FILES.DURATION")}
										{t("SHARED.DAYS", { count: FileStore.file.duration })}
									</div>

									{FileStore.file.employee?.external_id && (
										<div>
											{t("FILES.EMPLOYEE_ID", {
												id: FileStore.file.employee.external_id,
											})}
										</div>
									)}
								</div>
							</div>

							{close && FileStore.file.isClosed && (
								<Button iconLeft="fa-archive" primary disabled className={styles.Close}>
									{t("FILES.CLOSE.BUTTON_CLOSED")}
								</Button>
							)}

							{close && !FileStore.file.isClosed && (
								<Button
									iconLeft="fa-check"
									tertiary
									className={styles.Close}
									onClick={() => closeFile()}
								>
									{t("FILES.CLOSE.BUTTON")}
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}

	return <></>;
};

export default observer(FilePageHeader);
