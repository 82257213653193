import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Modal } from "@Core/components/Layouts";
import { Button, StepRow } from "@Core/components/UI";
import { FileStepType, Platform, QuestionnaireFor } from "@Core/constants/ENUMS";
import { useDialog } from "@Core/hooks";
import { FileStepInstance } from "@Core/models/File/FileStepModel";
import { QuestionnaireService } from "@Core/services";
import { formatDate } from "@Core/utilities";

import { usePNIStores } from "@PNI/stores";

import { StepProps } from "./Step.props";

interface PhoneModalProps {
	step: FileStepInstance;
	isVisible: boolean;
	toggle: () => void;
}

export const PhoneModal = ({ step, isVisible, toggle }: PhoneModalProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const { FileStore } = usePNIStores();

	const isSupervisor = step.type === FileStepType.SUPERVISOR_QUESTIONNAIRE;

	const phoneNumber = isSupervisor
		? FileStore.file?.employee?.phone_number
		: FileStore.file?.supervisor?.phone_number;

	const handleOpen = async () => {
		if (FileStore.file && step.file_questionnaire && step.file_questionnaire.questionnaire) {
			try {
				const response = await QuestionnaireService.phone(step.file_questionnaire.id, Platform.PNI);

				FileStore.getFile(String(FileStore.file.id), true);

				if (response) {
					history.push(`/dossiers/${FileStore.file.id}/vragenlijst/${response.id}`);
				}
			} catch (e) {
				toast.error(t("SHARED.ERROR"));
			}
		}
	};

	return (
		<Modal title={t("FILES.MODALS.QUESTIONNAIRE_BY_PHONE.POPUP_TITLE")} isVisible={isVisible} hide={toggle}>
			<p>
				{t(`FILES.MODALS.QUESTIONNAIRE_BY_PHONE.POPUP_DESCRIPTION_${isSupervisor ? "SUPERVISOR" : "EMPLOYEE"}`)}
			</p>

			<Button block iconLeft="fa-phone" href={`tel:${phoneNumber}`} primary className="mb-xs">
				{`${t("ADVISOR.CALL")} ${phoneNumber}`}
			</Button>

			<Button block iconLeft="fa-long-arrow-right" onClick={handleOpen} tertiary>
				{t("FILES.MODALS.QUESTIONNAIRE_BY_PHONE.POPUP_BUTTON")}
			</Button>
		</Modal>
	);
};

interface ReminderModalProps {
	step: FileStepInstance;
	isVisible: boolean;
	toggle: () => void;
}

export const ReminderModal = ({ step, isVisible, toggle }: ReminderModalProps) => {
	const { t } = useTranslation();

	const handleSend = () => {
		toggle();

		toast.success(t("FILES.MODALS.QUESTIONNAIRE_REMINDER.SUCCESS"));
	};

	return (
		<Modal title={t("FILES.MODALS.QUESTIONNAIRE_REMINDER.POPUP_TITLE")} isVisible={isVisible} hide={toggle}>
			<p>
				{t(
					`FILES.MODALS.QUESTIONNAIRE_REMINDER.POPUP_DESCRIPTION.${step.file_questionnaire?.questionnaire?.for}`,
				)}
			</p>

			<Button block iconLeft="fa-long-arrow-right" onClick={handleSend} tertiary>
				{t("FILES.MODALS.QUESTIONNAIRE_REMINDER.POPUP_BUTTON")}
			</Button>
		</Modal>
	);
};

export const Step = observer(({ step, hasToggle }: StepProps) => {
	const { t } = useTranslation();
	const { FileStore } = usePNIStores();
	const history = useHistory();
	const dialogPhone = useDialog();
	const dialogReminder = useDialog();

	const handleToggle = () => {
		step.toggle();
	};

	const handleOpen = () => {
		history.push(`/dossiers/${FileStore.file?.id}/vragenlijst/${step.file_questionnaire?.user_questionnaire?.id}`);
	};

	return (
		<>
			<StepRow {...step} isChecked={step.selected} onToggle={hasToggle ? () => handleToggle() : undefined}>
				{step.file_questionnaire && step.file_questionnaire.questionnaire && (
					<>
						{step.file_questionnaire.isCompleted &&
							step.file_questionnaire.user_questionnaire?.completed_at && (
								<StepRow.Tag
									name={formatDate(step.file_questionnaire.user_questionnaire?.completed_at)}
									icon="folder-open"
									variant="light"
								/>
							)}

						{!step.file_questionnaire.isCompleted && !!step.file_questionnaire.mail_id && (
							<StepRow.Tag
								name={
									step.file_questionnaire.mail_received
										? t("FILES.UNBOUNCE.ARRIVED")
										: t("FILES.UNBOUNCE.SEND")
								}
								icon={step.file_questionnaire.mail_received ? "mailbox" : "paper-plane"}
								variant="light"
							/>
						)}

						<StepRow.Buttons>
							{step.file_questionnaire.isCompleted ? (
								<Button tertiary link onClick={handleOpen}>
									{t("FILES.QUESTIONNAIRE_OPEN")}
								</Button>
							) : (
								<>
									{step.file_questionnaire.questionnaire.for === QuestionnaireFor.EMPLOYEE && (
										<>
											<ReminderModal step={step} {...dialogReminder} />
											<PhoneModal step={step} {...dialogPhone} />

											<Button tertiary onClick={dialogPhone.toggle} block>
												{t("FILES.QUESTIONNAIRE_BY_PHONE")}
											</Button>

											<Button primary onClick={dialogReminder.toggle} block>
												{!!step.file_questionnaire.mail_id
													? t("FILES.QUESTIONNAIRE_SEND_REMINDER")
													: t("FILES.QUESTIONNAIRE_SEND")}
											</Button>
										</>
									)}

									{step.file_questionnaire.questionnaire.for === QuestionnaireFor.SUPERVISOR && (
										<>
											<ReminderModal step={step} {...dialogReminder} />

											<Button tertiary onClick={dialogReminder.toggle} block>
												{!!step.file_questionnaire.mail_id
													? t("FILES.QUESTIONNAIRE_SEND_REMINDER")
													: t("FILES.QUESTIONNAIRE_SEND")}
											</Button>
										</>
									)}

									{step.file_questionnaire.questionnaire.for === QuestionnaireFor.FILE_MANAGER && (
										<>
											<PhoneModal step={step} {...dialogPhone} />

											<Button tertiary onClick={dialogPhone.toggle} block>
												{t("FILES.QUESTIONNAIRE_BY_PHONE")}
											</Button>
										</>
									)}
								</>
							)}
						</StepRow.Buttons>
					</>
				)}
			</StepRow>
		</>
	);
});
