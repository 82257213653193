import axios from "@Core/axios";
import { UserSnapshot } from "@Core/models/UserModel";
import BaseService from "@Core/services/BaseService";

class UserService extends BaseService<UserSnapshot> {
	me = async (): Promise<UserSnapshot> => {
		return this.one("me");
	};

	save = async (data: any): Promise<any> => {
		const response = await this.update("me", data);
		return response;
	};

	team = async (licence_code: string): Promise<any> => {
		const response = await axios.post(`${this.endpoint}/me/team`, {
			licence_code: licence_code,
		});

		return response;
	};
}

export default new UserService("users");
