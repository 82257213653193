import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { AssetModel } from "./AssetModel";

export const AssetsModel = types.model({
	label: types.string,
	items: types.array(AssetModel),
});

export interface AssetsInstance extends Instance<typeof AssetsModel> {}
export type AssetsSnapshot = SnapshotIn<typeof AssetsModel>;
