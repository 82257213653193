import { QuestionaireSnapshot } from "@Core/models";
import { BaseService } from "@Core/services";

import { ChallengeSnapshot } from "@LNI/models";

interface Status {
	finished_onboarding: boolean;
	has_challenges: boolean;
	has_article_tags: boolean;
	isDone: boolean;
}

class OnboardingService extends BaseService<any> {
	getQuestionnaire = async (): Promise<QuestionaireSnapshot | null> => {
		const response = this.list();

		if (response) {
			return response;
		}

		return null;
	};

	getChallenges = async (): Promise<ChallengeSnapshot | null> => {
		const response = this.one("result");

		if (response) {
			return response;
		}

		return null;
	};

	getStatus = async (): Promise<Status | null> => {
		const response = this.one("status");

		if (response) {
			return response;
		}

		return null;
	};

	setChallenges = async (challenges: number[]): Promise<boolean> => {
		const values = {
			challenges: challenges.join(","),
		};

		const response = await this.update("subscribe", values);

		if (response) {
			return true;
		}

		return false;
	};

	setResult = async (questionId: number, answer: string): Promise<boolean> => {
		const values = {
			question: questionId,
			answer: answer,
		};

		const response = await this.create(values);

		if (response) {
			return true;
		}

		return false;
	};
}

export default new OnboardingService("onboarding");
