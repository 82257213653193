import React from "react";
import { useTranslation } from "react-i18next/";

import Form from "@Core/components/Form";
import Yup from "@Core/validation";

import { ResendInviteFormProps } from "./ResendInviteForm.props";

export const ResendInviteForm = ({ initialValues, onSubmit, onSuccess }: ResendInviteFormProps) => {
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		first_name: Yup.string().required(),
		last_name: Yup.string().required(),
		email: Yup.string().required().email(),
	});

	return (
		<Form.Form
			identifier="RESEND_INVITE_FORM"
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			submit={t("LOGIN.RESEND.CONFIRM")}
		>
			<Form.Item
				id="first_name"
				name={t("LOGIN.RESEND.LABEL_FIRST_NAME")}
				render={(props) => <Form.Text {...props} />}
			/>

			<Form.Item
				id="last_name"
				name={t("LOGIN.RESEND.LABEL_LAST_NAME")}
				render={(props) => <Form.Text {...props} />}
			/>

			<Form.Item
				id="email"
				name={t("LOGIN.RESEND.LABEL_EMAIL")}
				render={(props) => <Form.Text {...props} icon="envelope" />}
			/>
		</Form.Form>
	);
};
