import React from "react";
import { Route } from "react-router-dom";

import { InvitationPage, QuestionnairePage } from "@OOM/pages";

export const getPublicRoutes = () => {
	const routes = [
		<Route key="/digitale-vragenlijst" path="/digitale-vragenlijst" exact component={QuestionnairePage} />,
		<Route key="/uitnodiging" path="/uitnodiging" exact component={InvitationPage} />,
	];

	return routes;
};
