import { Instance, SnapshotIn, types } from "mobx-state-tree";

export const QuestionnaireAnswersModel = types.model({
	id: types.number,
	order: types.number,
	text: types.string,
	selected: types.boolean,
});

export interface QuestionnaireAnswersInstance extends Instance<typeof QuestionnaireAnswersModel> {}
export type QuestionnaireAnswersSnapshot = SnapshotIn<typeof QuestionnaireAnswersModel>;
