import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseModel } from "@Core/models";

import { AssignmentModel, ProgressModel } from "@LNI/models";

export const CourseModel = BaseModel.props({
	id: types.identifierNumber,
	title: types.string,
	icon: types.string,
	introduction_text: types.string,
	congratulation_text: types.string,
	trainer_email: types.maybeNull(types.string),
	assignments: types.array(AssignmentModel),
	progress: ProgressModel,
}).actions((self) => ({
	setDone() {
		self.progress.done += 1;
	},
}));

export interface CourseInstance extends Instance<typeof CourseModel> {}
export type CourseSnapshot = SnapshotIn<typeof CourseModel>;
