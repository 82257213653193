import { useField } from "formik";
import React from "react";

import { Button } from "@Core/components/UI";

import styles from "./FileInput.module.scss";

type Props = {
	id: string;
	label: string;
	accept?: string;
	onUpload: (e: FileList | null) => any;
};

const FileInput = ({ id, label, accept, onUpload }: Props) => {
	const [field] = useField(id);

	return (
		<div className={styles.FileInput}>
			<label htmlFor={id}>
				<Button className="mb-xs mt-xs" primary>
					{label}
				</Button>
			</label>
			<input
				id={id}
				type="file"
				accept={accept}
				{...field}
				onChange={(e) => {
					onUpload(e.target.files);
					field.onChange(e);
				}}
			/>
		</div>
	);
};

export default FileInput;
