import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";

import styles from "./Sidebar.module.scss";

type Props = {
	items: {
		icon: string;
		title: string;
		notifications?: number;
		element: string;
	}[];
};

const Sidebar = ({ items }: Props) => {
	const [selectedList, setSelectedList] = useState(Array(items.length));
	const [stickyTop, setStickyTop] = useState(false);
	const [wrapperWidth, setWrapperWidth] = useState(0);
	const [activeLine, setActiveLine] = useState(Math.abs(window.screenY) / 3);

	const onScroll = useCallback(() => {
		// Check if The bar should be fixed
		const elem = document.getElementById("stickToTop");
		if (!!elem) setStickyTop(elem.getBoundingClientRect().top < 15);

		// check current selected item
		let hasTrue = false;
		setSelectedList(
			items.map((item) => {
				if (item.element && !hasTrue) {
					if (window.scrollY < 85) {
						hasTrue = true;
						return true;
					}
					const elem = document.getElementById(item.element);
					if (elem) {
						const active =
							elem.getBoundingClientRect().top < activeLine &&
							elem.getBoundingClientRect().bottom > activeLine;
						if (active) hasTrue = true;
						return active;
					}
				}
				return false;
			}),
		);
	}, [items, activeLine]);

	const onResize = useCallback(() => {
		const element = document.getElementById("wrapper");
		if (element) setWrapperWidth(element.getBoundingClientRect().width);
		setActiveLine(Math.abs(window.screenY) / 3);
		onScroll();
	}, [setActiveLine, onScroll]);

	useEffect(() => {
		window.addEventListener("scroll", onScroll);
		onScroll();

		window.addEventListener("resize", onResize);
		onResize();

		return () => {
			window.removeEventListener("scroll", onScroll);
			window.removeEventListener("resize", onResize);
		};
	}, [onScroll, onResize]);

	const goToElement = (targetElement: string) => {
		const elem = document.getElementById(targetElement);
		if (!!elem) {
			window.scrollTo({
				top:
					elem.getBoundingClientRect().top +
					window.pageYOffset -
					Math.max(activeLine - elem.getBoundingClientRect().height / 2, 0),
				behavior: "smooth",
			});
		}
	};

	return (
		<div id="wrapper">
			<div
				className={cn(styles.Sidebar, {
					[styles.SidebarSticky]: stickyTop,
				})}
				style={{ width: wrapperWidth }}
			>
				{items.map((item, id) => {
					return (
						<div
							className={cn(styles.Item, { [styles.ItemSelected]: selectedList[id] })}
							key={id}
							onClick={() => {
								if (item.element) goToElement(item.element);
							}}
						>
							<div className={cn(`far ${item.icon}`, styles.ItemIcon)}>
								{!!item.notifications && item.notifications > 0 && (
									<div className={styles.ItemIconNotification}>{item.notifications}</div>
								)}
							</div>

							<div className={styles.ItemTitle}>{item.title}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default observer(Sidebar);
