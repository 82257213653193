import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { QuestionnaireModel } from "@Core/models";

export const EligibleRoleModel = types.model({
	id: types.identifierNumber,
	name: types.string,
	slug: types.string,
	base_questionnaire: QuestionnaireModel,
});

export interface EligibleRoleInstance extends Instance<typeof EligibleRoleModel> {}
export type EligibleRoleSnapshot = SnapshotIn<typeof EligibleRoleModel>;
