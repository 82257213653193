import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";

import image_welcome from "@Core/assets/images/illustrations/welcome.png";
import { ArticleList, ContactList } from "@Core/components/HOC";
import { Modal } from "@Core/components/Layouts";
import { Block, Button } from "@Core/components/UI";
import { useDialog } from "@Core/hooks";
import { ArticleInstance } from "@Core/models";
import { useCoreStores } from "@Core/stores";
import { getKennisbankToken } from "@Core/utilities";

import { Moment, Sidebar } from "@LNI/components/Components";
import { ChallengeList, CourseList, FileList } from "@LNI/components/HOC";
import { Page } from "@LNI/components/Layouts";
import { useLNIStores } from "@LNI/stores";

const DashboardPage = () => {
	const { t } = useTranslation();
	const { UserStore } = useCoreStores();
	const { ArticleStore, ChallengeStore, CourseStore, FileStore } = useLNIStores();

	return (
		<Page>
			{UserStore.isFirstLogin && <DashboardPageWelcome />}

			<div className="container" id="top">
				<div className="row">
					<div className="col-1 " id="stickToTop">
						<DashboardPageSidebar />
					</div>

					<div className="col-lg-11">
						<div className="row">
							<div
								id="challenges"
								className={cn([], {
									"col-lg-12":
										UserStore.hasAOMPermissions && UserStore.company?.getContacts.length !== 0,
									"col-lg-8":
										!UserStore.hasAOMPermissions ||
										(UserStore.hasAOMPermissions && UserStore.company?.getContacts.length === 0),
								})}
							>
								<ChallengeList challenges={ChallengeStore.challenges} />
							</div>

							{!UserStore.hasAOMPermissions && (
								<div className="col-lg-4">
									{UserStore.hasAdvisorPermissions &&
									UserStore.company?.getContacts &&
									UserStore.company?.getContacts?.length > 0 ? (
										<ContactList items={1} contacts={UserStore.company?.getContacts} />
									) : (
										<DashboardPageQuote />
									)}
								</div>
							)}

							{UserStore.company?.getContacts.length === 0 && (
								<div className="col-lg-4">
									<DashboardPageQuote />
								</div>
							)}
						</div>

						{UserStore.hasAOMPermissions && (
							<div id="files" className="row">
								<div className="col-lg-12">
									<Block
										name={t("FILES.MY_FILES")}
										info={
											<>
												<p>{t("FILES.MY_FILES_DESCRIPTION_1")}</p>
												<p>{t("FILES.MY_FILES_DESCRIPTION_2")}</p>
												<div>{t("FILES.MY_FILES_DESCRIPTION_3")}</div>
											</>
										}
									>
										<FileList files={FileStore.files} length={3} />

										{FileStore.files.length > 0 && (
											<Button primary className="mt-sm" to="/dossiers">
												{t("FILES.LOAD_ALL")}
											</Button>
										)}
									</Block>
								</div>
							</div>
						)}

						{UserStore.hasLNIPermissions && (
							<div id="courses" className="row">
								<div className="col-lg-12">
									<CourseList courses={CourseStore.courses} />
								</div>
							</div>
						)}

						{UserStore.hasMomentsPermissions && (
							<div className="row">
								<div id="moments" className="col-xl-5 col-lg-12">
									<Moment />
								</div>

								{ArticleStore.hasArticles && (
									<div id="articles" className="col-xl-7 col-lg-12">
										{<DashboardPageArticles articles={ArticleStore.articles} items={2} />}
									</div>
								)}
							</div>
						)}

						{!UserStore.hasMomentsPermissions && ArticleStore.hasArticles && (
							<div id="articles" className="row">
								<div className="col-lg-12">
									{<DashboardPageArticles articles={ArticleStore.articles} items={4} />}
								</div>
							</div>
						)}

						{UserStore.hasAOMPermissions &&
							UserStore.user?.companies[0].getContacts &&
							UserStore.user?.companies[0].getContacts.length > 0 && (
								<div id="advisor" className="row">
									<div className="col-lg-12">
										<Block name={t("DASHBOARD.CONTACTPERSONS")}>
											<ContactList
												items={2.5}
												card
												contacts={UserStore.user?.companies[0].getContacts}
											/>
										</Block>
									</div>
								</div>
							)}
					</div>
				</div>
			</div>
		</Page>
	);
};

const DashboardPageWelcome = () => {
	const { t } = useTranslation();
	const { UserStore } = useCoreStores();
	const [step, setStep] = useState(0);

	const { isVisible, toggle } = useDialog();

	const steps = [];

	useEffect(() => {
		if (!isVisible) {
			toggle();
		}
	}, [toggle, isVisible]);

	steps.push({
		title: t("DASHBOARD.WELCOME"),
		image: image_welcome,
		content: <p>{t("DASHBOARD.WELCOME_DESCRIPTION")}</p>,
	});

	steps.push({
		title: t("DASHBOARD.INTRODUCTION"),
		image: image_welcome,
		content: (
			<>
				<p>{t("DASHBOARD.INTRODUCTION_DESCRIPTION")}</p>
				<ul>
					<li>{t("DASHBOARD.INTRODUCTION_DESCRIPTION_B1")}</li>
					<li>{t("DASHBOARD.INTRODUCTION_DESCRIPTION_B2")}</li>
					<li>{t("DASHBOARD.INTRODUCTION_DESCRIPTION_B3")}</li>
					<li>{t("DASHBOARD.INTRODUCTION_DESCRIPTION_B4")}</li>
					<li>{t("DASHBOARD.INTRODUCTION_DESCRIPTION_B5")}</li>
				</ul>
			</>
		),
	});

	steps.push({
		title: t("DASHBOARD.CHALLENGES"),
		image: image_welcome,
		content: <p>{t("DASHBOARD.CHALLENGES_DESCRIPTION")}</p>,
	});

	steps.push({
		title: t("DASHBOARD.EXCLUSIVE_CONTENT"),
		image: image_welcome,
		content: <p>{t("DASHBOARD.EXCLUSIVE_CONTENT_DESCRIPTION")}</p>,
	});

	if (UserStore.hasAdvisorPermissions) {
		steps.push({
			title: t("DASHBOARD.ADVICE"),
			image: image_welcome,
			content: <p>{t("DASHBOARD.ADVICE_DESCRIPTION")}</p>,
		});
	}

	const onTogglePopup = () => {
		toggle();
		UserStore.hasFirstLogin();
	};

	return (
		<Modal title={steps[step].title} image={steps[step].image} large isVisible={isVisible} hide={onTogglePopup}>
			{steps[step].content}

			{steps.length === step + 1 && (
				<Button className="mt-auto" iconRight="fa-long-arrow-right" primary onClick={onTogglePopup}>
					{t("DASHBOARD.TO_DASHBOARD")}
				</Button>
			)}

			{steps.length !== step + 1 && (
				<Button className="mt-auto" iconRight="fa-long-arrow-right" primary onClick={() => setStep(step + 1)}>
					{t("DASHBOARD.NEXT")}
				</Button>
			)}
		</Modal>
	);
};

type DashboardPageArticlesProps = {
	articles: ArticleInstance[];
	items: number;
};

const DashboardPageArticles = ({ articles, items }: DashboardPageArticlesProps) => {
	const { t } = useTranslation();

	return (
		<Block name={t("ARTICLES.MY_ARTICLES")}>
			<ArticleList articles={articles} items={items} />

			<Button className="mt-sm" href={`${t("SHARED.KENNISBANK_URL")}blog?token=${getKennisbankToken()}`} primary>
				{t("ARTICLES.ALL_ARTICLES")}
			</Button>
		</Block>
	);
};

const DashboardPageSidebar = observer(() => {
	const { t } = useTranslation();
	const { ArticleStore, MomentStore, FileStore } = useLNIStores();
	const { UserStore } = useCoreStores();

	const items = [];

	items.push({
		title: t("DEFAULT.SIDEBAR.HOME"),
		icon: "fa-home",
		element: "header",
	});

	items.push({
		title: t("DEFAULT.SIDEBAR.CHALLENGES"),
		icon: "fa-handshake",
		element: "challenges",
		notifications: 0,
	});

	if (
		!UserStore.hasAOMPermissions &&
		UserStore.hasAdvisorPermissions &&
		UserStore.company?.getContacts.length !== 0
	) {
		items.push({
			title: t("DEFAULT.SIDEBAR.ADVISOR_SINGLE"),

			icon: "fa-id-card",

			element: "advisor",
		});
	}

	if (UserStore.hasAOMPermissions) {
		items.push({
			title: t("DEFAULT.SIDEBAR.FILES"),
			icon: "fa-folder-open",
			element: "files",
			notifications: FileStore.files.length || 0,
		});
	}

	items.push({
		title: t("DEFAULT.SIDEBAR.COURSES"),
		icon: "fa-user-graduate",
		element: "courses",
	});

	if (UserStore.hasMomentsPermissions) {
		items.push({
			title: t("DEFAULT.SIDEBAR.MOMENTS"),
			icon: "fa-clock",
			element: "moments",
			notifications: MomentStore.hasMoment && MomentStore.moment.isPivotReceived ? 1 : 0,
		});
	}

	if (ArticleStore.hasArticles) {
		items.push({
			title: t("DEFAULT.SIDEBAR.ARTICLES"),
			icon: "fa-star",
			element: "articles",
		});
	}

	if (UserStore.hasAOMPermissions && UserStore.company?.getContacts.length !== 0) {
		items.push({
			title: t("DEFAULT.SIDEBAR.ADVISOR"),
			icon: "fa-id-card",
			element: "advisor",
		});
	}

	return <Sidebar items={items} />;
});

const DashboardPageQuote = () => {
	const { t } = useTranslation();

	return (
		<Block primary>
			<blockquote>
				<p>{t("DASHBOARD.QUOTE")}</p>

				<footer>{t("DASHBOARD.QUOTE_SOURCE")}</footer>
			</blockquote>
		</Block>
	);
};

export default observer(DashboardPage);
