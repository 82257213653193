import { observer } from "mobx-react-lite";
import React from "react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next/";

import { Frame, Navigation } from "@Core/components/UI";

import { usePNIStores } from "@PNI/stores";

import FilePageHeader from "./FilePageHeader";

type Props = {
	children: ReactNode;
};

const FilePageWrapper = ({ children }: Props) => {
	const { t } = useTranslation();
	const { FileStore } = usePNIStores();

	return (
		<div className="mb-lg">
			<FilePageHeader close />

			<div className="container">
				<Frame elevated="small">
					<Navigation>
						<Navigation.Item to={`/dossiers/${FileStore.file?.id}`}>
							{t("FILES.NAVIGATION.INSIGHT")}
						</Navigation.Item>

						<Navigation.Item to={`/dossiers/${FileStore.file?.id}/advies`}>
							{t("FILES.NAVIGATION.ADVICE")}
						</Navigation.Item>

						<Navigation.Item to={`/dossiers/${FileStore.file?.id}/rapportage`}>
							{t("FILES.NAVIGATION.REPORT")}
						</Navigation.Item>

						<Navigation.Item to={`/dossiers/${FileStore.file?.id}/richtlijnen`}>
							{t("FILES.NAVIGATION.GUIDELINES")}
						</Navigation.Item>
					</Navigation>

					<Frame.Body>{children}</Frame.Body>
				</Frame>
			</div>
		</div>
	);
};

export default observer(FilePageWrapper);
