import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { KENNISBANK_URL } from "@Core/constants/DEFAULT";
import { getKennisbankToken } from "@Core/utilities";

import { ActivationModel } from "./ActivationModel";
import { ArticleRecommendationModel } from "./ArticleRecommendationModel";
import { TagModel } from "./TagModel";

export const ArticleModel = types
	.model({
		id: types.string,
		title: types.string,
		created_on: types.string,
		intro: types.string,
		exclusive: types.boolean,
		slug: types.string,
		theme: TagModel,
		most_read: types.boolean,
		content: types.maybeNull(types.array(types.frozen() as any)),
		featured: types.boolean,
		keywords: types.array(TagModel),
		read_time: types.maybeNull(types.number),
		activation: types.maybeNull(ActivationModel),
		recommendation: types.maybeNull(ArticleRecommendationModel),
	})
	.views((self) => ({
		get getSlug(): string {
			return `${KENNISBANK_URL}kennis/${self.theme.slug}/${self.slug}?token=${getKennisbankToken()}`;
		},
	}));

export interface ArticleInstance extends Instance<typeof ArticleModel> {}
export type ArticleSnapshot = SnapshotIn<typeof ArticleModel>;
