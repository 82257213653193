import { useEffect, useState } from "react";

export const useCountdown = (countdown: number) => {
	const [count, setCount] = useState(0);
	const [_start, setStart] = useState(false);

	const start = () => {
		setCount(countdown);
		setStart(true);
	};

	const reset = () => {
		setStart(false);
	};

	useEffect(() => {
		if (_start) {
			const interval = setInterval(() => {
				setCount((old) => old - 1);

				if (count === 1) {
					reset();
					clearInterval(interval);
				}
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [_start, count]);

	return {
		start,
		reset,
		count,
	};
};
