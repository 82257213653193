import React from "react";

import { IFieldProps } from "@Core/interfaces";
import { UserQuestionnaireQuestionInstance } from "@Core/models";

import styles from "./Open.module.scss";

type Props = {
	id: string;
	name: string;
	field: IFieldProps;

	disabled?: boolean;
	question: UserQuestionnaireQuestionInstance;
	placeholder?: string;
};

const Open = ({ id, name, field, disabled, question, placeholder }: Props) => {
	return (
		<div className={styles.Inputs}>
			<textarea id={id} disabled={disabled} className={styles.Textarea} {...field} />
		</div>
	);
};

export default Open;
