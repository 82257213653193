import cn from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "..";
import styles from "./Share.module.scss";
import { ShareProps } from "./Share.props";

export const Share = ({ title, intro, block, secondary, background, document, className }: ShareProps) => {
	const { t } = useTranslation();

	const handleShare = useCallback(
		(platform: string) => () => {
			const location = encodeURIComponent(window.location.href + "?share=1");
			let url = "";

			switch (platform) {
				case "WhatsApp":
					url = `whatsapp://send?text=${t("ARTICLES.ARTICLE.SHARE.TITLE", {
						TITLE: title,
					})}%0a%0a${location}`;
					break;
				case "LinkedIn":
					url = `https://www.linkedin.com/shareArticle?mini=true&url=${location}&title={title}&summary=${intro}&source=LinkedIn`;
					break;
				case "Mail":
					url = `mailto:?subject=${t("ARTICLES.ARTICLE.SHARE.TITLE", {
						TITLE: title,
					})}&body=${t("ARTICLES.ARTICLE.SHARE.TITLE", {
						TITLE: title,
					})} ${t("ARTICLES.ARTICLE.SHARE.READALL", { LOCATION: location })}`;
					break;
			}

			window.open(url, "_blank");
		},
		[],
	);

	return (
		<div
			className={cn([styles.Share], className, {
				[styles.isBlock]: block,
				[styles.hasBackground]: background,
			})}
		>
			<label>{t("ARTICLES.ARTICLE.SHARE.TITLE")}</label>

			<div className={styles.Social}>
				<Button
					primary={!secondary}
					secondary={secondary}
					icon
					onClick={handleShare("WhatsApp")}
					className={styles.Button}
				>
					<i className="fab fa-whatsapp" />
				</Button>

				<Button
					primary={!secondary}
					secondary={secondary}
					icon
					onClick={handleShare("LinkedIn")}
					className={styles.Button}
				>
					<i className="fab fa-linkedin" />
				</Button>

				<Button
					primary={!secondary}
					secondary={secondary}
					icon
					onClick={handleShare("Mail")}
					className={styles.Button}
				>
					<i className="far fa-envelope" />
				</Button>
			</div>

			{document && (
				<a href={document}>
					<Button className={`${styles.Download} ml-lg download`} primary icon secondary={secondary}>
						{t("ARTICLES.ARTICLE.SHARE.DOWNLOAD")}
						<i className="far fa-download" />
					</Button>
				</a>
			)}
		</div>
	);
};
