import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";

import { useCoreStores } from "@Core/stores";

import { Page } from "@PNI/components/Layouts";
import { usePNIStores } from "@PNI/stores";

import FilePageWrapper from "../internal/components/FilePageWrapper";
import { ChosenQuestionnaires } from "./internal/components/ChosenQuestionnaires/ChosenQuestionnaires";
import { ChosenSteps } from "./internal/components/ChosenSteps/ChosenSteps";
import { Notes } from "./internal/components/Notes/Notes";
import { Report } from "./internal/components/Report/Report";

const FilePageReport = () => {
	const { t } = useTranslation();
	const { file } = useParams<{ file: string }>();
	const history = useHistory();

	const { UserStore } = useCoreStores();
	const { FileStore } = usePNIStores();

	const breadcrumbs = [
		{
			name: t("FILES.PAGE_TITLE", {
				id: FileStore.file?.getId,
			}),
		},
	];

	useEffect(() => {
		(async () => {
			try {
				await FileStore.getFile(file, true);
			} catch (e) {
				history.push("");
			}
		})();
	}, [file, FileStore, history]);

	return (
		<Page breadcrumbs={breadcrumbs}>
			{FileStore.file && UserStore.user && (
				<FilePageWrapper>
					<ChosenQuestionnaires className="mb-md" />

					<ChosenSteps className="mb-md" />

					<Notes className="mb-md" />

					<Report />
				</FilePageWrapper>
			)}
		</Page>
	);
};

export default observer(FilePageReport);
