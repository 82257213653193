import cn from "classnames";
import React from "react";

import styles from "./StackListItem.module.scss";

export interface StackListItemProps {
	title: string;
	children: string;
	icon?: string;
	className?: string;
	onClick?: () => void;
}

export const StackListItem = ({ className, title, children, icon, onClick }: StackListItemProps) => {
	return (
		<div
			className={cn(styles.StackListItem, className, {
				[styles.hasIcon]: !!icon,
				[styles.hasOnClick]: !!onClick,
			})}
		>
			<div className={styles.Title}>{title}</div>

			<div onClick={onClick} className={styles.Wrapper}>
				{!!icon && <i className={cn(styles.Icon, `fas fa-${icon}`)} />}

				{children}
			</div>
		</div>
	);
};
