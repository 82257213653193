import React from "react";
import { useTranslation } from "react-i18next/";

import Form from "@Core/components/Form";
import Yup from "@Core/validation";

import Props from "./MomentsForm.props";

const MomentsForm = ({ initialValues, onSubmit, onSuccess }: Props) => {
	const { t } = useTranslation();

	const formOptions = {
		"": t("FORMS.VALUES_MOMENTS.SELECT"),
		two_weekly: t("FORMS.VALUES_MOMENTS.BI_WEEKLY"),
		monthly: t("FORMS.VALUES_MOMENTS.MONTHLY"),
	};

	const validationSchema = Yup.object().shape({
		moment_interval: Yup.string().required(),
	});

	return (
		<Form.Form
			identifier="MOMENTS_FORM"
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			submit="Momenten aanpassen"
		>
			<Form.Item
				id="moment_interval"
				name="Kies de frequentie van jouw momenten"
				render={(props) => <Form.Select {...props} options={formOptions} />}
			/>
		</Form.Form>
	);
};

export default MomentsForm;
