import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { Share } from "@Core/components/UI";

import { PreprArticleCard } from "@PNI/components/Containers";

import styles from "./ArticlesDetailFooter.module.scss";
import { ArticlesDetailFooterProps } from "./ArticlesDetailFooter.props";

export const ArticlesDetailFooter = ({ article, className }: ArticlesDetailFooterProps) => {
	const { t } = useTranslation();

	return (
		<div className={cn(styles.ArticlesDetailFooter, className)}>
			<Share
				title={article.title}
				intro={article.intro}
				className={styles.Share}
				block
				secondary
				background={!!article.activation_tips || !!article.activation_steps}
				document={article.download && article.download[0] ? article.download[0].cdn_files[0].url : undefined}
			/>

			{article.related && article.related.length > 0 && (
				<div className={cn(styles.Related, className)}>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-8 col-lg-10 col-md-12">
								<h2 className="color-primary mb-md">{t("ARTICLES.ARTICLE.FOOTER.TITLE")}</h2>

								{article.related.map((relatedArticle) => {
									return (
										<PreprArticleCard
											large
											key={relatedArticle._id}
											article={relatedArticle}
											className={styles.Article}
										/>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
