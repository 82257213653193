import cn from "classnames";
import React, { useCallback } from "react";
import ReactPlayer from "react-player";

import { Modal } from "@Core/components/Layouts";
import { Button } from "@Core/components/UI";
import { useDialog } from "@Core/hooks";

import { getContentAbbreviation } from "../../utils";
import styles from "./PreprRendererTextWithFile.module.scss";
import { PreprRendererTextWithFileProps } from "./PreprRendererTextWithFile.props";

export const PreprRendererTextWithFile = ({ element }: PreprRendererTextWithFileProps) => {
	const { isVisible, toggle } = useDialog();

	const getIcon = (type: string) => {
		switch (type) {
			case "Webpage":
				return "link";
			case "Video":
				return "video";
			case "Document":
				return "file-alt";
		}
	};

	const handleClick = useCallback(
		(type: string) => () => {
			switch (type) {
				case "Webpage":
				case "Document":
					window.open(element.url.url, "_blank");
					break;
				case "Video":
					toggle();
					break;
			}
		},
		[toggle],
	);

	return (
		<div className={cn(styles.PreprRendererTextWithFile, "row")}>
			<div className="col-xl-3 hidden-lg-down"></div>

			<div className="col-xl-6 col-lg-8 col-md-12">
				<div
					className={styles.Text}
					dangerouslySetInnerHTML={{ __html: getContentAbbreviation(element.text) }}
				/>
			</div>

			{document && (
				<div className="col-xl-3 col-lg-4 col-md-12">
					<div onClick={handleClick(element.url.type)} className={styles.File}>
						<Button secondary icon className={styles.Button}>
							<i className={`far fa-${getIcon(element.url.type)}`}></i>
						</Button>

						<p>{element.url.body}</p>
					</div>
				</div>
			)}

			{element.url && element.url.type === "Video" && (
				<Modal title={element.url.body} isVisible={isVisible} hide={toggle}>
					<ReactPlayer
						className="react-player"
						url={element.url.url}
						width="100%"
						height="100%"
						playing={isVisible}
						controls={false}
					/>
				</Modal>
			)}
		</div>
	);
};
