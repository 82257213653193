import format from "date-fns/format";
import { useFormikContext } from "formik";
import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { IFieldProps } from "@Core/interfaces";

import styles from "./Date.module.scss";

type Props = {
	id: string;
	field: IFieldProps;

	disabled?: boolean;
	placeholder?: string;
	icon?: string;
};

const DateInput = ({ id, field, disabled, placeholder, icon, ...props }: Props) => {
	const { setFieldValue } = useFormikContext();

	return (
		<div className={styles.Field}>
			<DatePicker
				id={id}
				endDate={new Date()}
				placeholderText={placeholder}
				disabled={disabled}
				locale="nl"
				autoComplete="off"
				selected={field.value ? new Date(field.value) : null}
				showYearDropdown
				showMonthDropdown
				dropdownMode="select"
				portalId="root"
				dateFormat="dd-MM-yyyy"
				onChange={(val) => {
					setFieldValue(field.name, val);
				}}
				{...props}
			/>

			{icon && <i className={`${styles.Icon} far fa-${icon}`}></i>}
		</div>
	);
};

DateInput.defaultProps = {
	icon: "calendar",
};

export default DateInput;
