import cn from "classnames";
import { Form as FormikForm, Formik, FormikProps, FormikValues } from "formik";
import debounce from "lodash.debounce";
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { Button, Loader } from "@Core/components/UI";
import { timeout } from "@Core/utilities";
import Yup from "@Core/validation";

import styles from "./Form.module.scss";

type Props = {
	identifier: string;
	children?: ReactNode;

	initialValues?: FormikValues;
	validationSchema?: Yup.ObjectSchema;
	onSubmit?: (values: any) => void;
	onChange?: (values: any) => void;
	bindSubmit?: (submitForm: (() => Promise<void>) & (() => Promise<any>)) => void;
	onSuccess?: (response?: any) => void;
	onFailure?: (response?: any) => void;
	onInitialValues?: boolean;

	disabled?: boolean;
	submit?: string;
	ref?: HTMLDivElement;

	className?: string;
};

enum Status {
	DEFAULT,
	LOADING,
	SUCCESS,
	FAILURE,
}

const Form = ({
	identifier,
	children,
	initialValues,
	validationSchema,
	onInitialValues,
	onSubmit,
	onChange,
	onSuccess,
	onFailure,
	disabled,
	submit,
	className,
}: Props) => {
	const [status, setStatus] = useState(Status.DEFAULT);

	const ref = useRef<FormikProps<FormikValues> | null>(null);

	useEffect(() => {
		if (initialValues && onInitialValues) {
			ref.current?.setValues(initialValues);
		}
	}, [initialValues, onInitialValues]);

	const handleSubmit = async (values: any) => {
		if (onSubmit && status === Status.DEFAULT) {
			setStatus(Status.LOADING);

			try {
				const response = await onSubmit(values);

				if (submit) {
					setStatus(Status.SUCCESS);
					await timeout(1000);
					setStatus(Status.DEFAULT);
				} else {
					setStatus(Status.DEFAULT);
				}

				if (onSuccess) onSuccess(response);
			} catch (errors) {
				console.log(errors);

				if (onFailure) onFailure();

				if (submit) {
					setStatus(Status.FAILURE);
					await timeout(1000);
					setStatus(Status.DEFAULT);
				} else {
					setStatus(Status.DEFAULT);
				}
			}
		}
	};

	const debounceFunc = useCallback(
		debounce((values: any) => {
			onChange && onChange(values);
		}, 500),
		[],
	);

	if ((onInitialValues && initialValues && Object.keys(initialValues).length > 0) || !onInitialValues) {
		return (
			<Formik
				key={identifier}
				innerRef={ref}
				initialValues={initialValues || {}}
				enableReinitialize
				validationSchema={validationSchema}
				onSubmit={handleSubmit}
			>
				{({ isValidating, isValid, values }) => {
					const classes = cn([styles.Submit], {
						[styles.SubmitLoading]: status === Status.LOADING || isValidating,
						[styles.SubmitSuccess]: status === Status.SUCCESS,
						[styles.SubmitFailure]: status === Status.FAILURE,
						[styles.SubmitDefault]: status === Status.DEFAULT && !isValidating,
					});

					if (!isValid && isValidating && onInitialValues) {
						if (onFailure) onFailure();
					}

					useEffect(() => {
						onChange && debounceFunc(values);
					}, [values]);

					return (
						<FormikForm about={identifier} name={identifier} className={cn(styles.Form, className)}>
							{children && <div className={styles.Field}>{children}</div>}

							{submit && submit !== "false" && (
								<div className={styles.Footer}>
									<Button disabled={disabled} tertiary block className={classes} type="submit">
										<div className={styles.Loading}>
											<Loader />
										</div>

										<i className={cn([styles.Success, styles.Icon, "far fa-check"])}></i>
										<i className={cn([styles.Failure, styles.Icon, "far fa-times"])}></i>

										<span className={styles.Text}>{submit}</span>
									</Button>
								</div>
							)}
						</FormikForm>
					);
				}}
			</Formik>
		);
	}

	return <></>;
};

export default Form;
