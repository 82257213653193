import cn from "classnames";
import { useField } from "formik";
import React from "react";

import { IFieldCheckbox } from "@Core/interfaces";

import styles from "./Checkbox.module.scss";

type Props = {
	id: string;
	name: string;
	options: IFieldCheckbox[];
};

const Checkbox = ({ id, name, options }: Props) => {
	const [field] = useField(id);

	return (
		<div className={cn([styles.Field], { [styles.FieldEmpty]: field.value === "" })}>
			{options.map((option: IFieldCheckbox) => (
				<div key={option.id}>
					<input
						id={`${id}_${option.id}`.toUpperCase()}
						className={styles.Input}
						defaultChecked={option.checked}
						{...field}
						type="checkbox"
						value={option.id}
					/>

					<label
						htmlFor={`${id}_${option.id}`.toUpperCase()}
						dangerouslySetInnerHTML={{ __html: option.name }}
					/>
				</div>
			))}
		</div>
	);
};

export default Checkbox;
