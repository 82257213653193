import React from "react";

import { IFieldProps } from "@Core/interfaces";

import styles from "./Hidden.module.scss";

type Props = {
	id: string;
	field: IFieldProps;
};

const Hidden = ({ id, field }: Props) => {
	return <input id={id} type="hidden" className={styles.Input} {...field} />;
};

export default Hidden;
