import React from "react";

import { Button } from "@Core/components/UI";

import styles from "./ArticlesDetailBodyActivation.module.scss";
import { ArticlesDetailBodyActivationProps } from "./ArticlesDetailBodyActivation.props";

export const ArticlesDetailBodyActivation = ({ title, icon, children }: ArticlesDetailBodyActivationProps) => {
	return (
		<div className={styles.ArticlesDetailBodyActivation}>
			<h4>
				<Button primary icon className={styles.Icon}>
					<i className={`far fa-${icon}`} />
				</Button>

				{title}
			</h4>

			<div className={styles.Content}>{children}</div>
		</div>
	);
};
