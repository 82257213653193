import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { UserQuestionnaireStepType } from "@Core/constants/ENUMS";
import { ImageModel } from "@Core/models";

import { UserQuestionnaireQuestionModel } from "./UserQuestionnaireQuestionModel";
import { UserQuestionnaireSectionModel } from "./UserQuestionnaireSectionModel";

export const UserQuestionnaireStepModel = types.model("UserQuestionnaireStepModel", {
	id: types.union(types.string, types.number),
	question: types.maybeNull(UserQuestionnaireQuestionModel),
	section: types.maybeNull(UserQuestionnaireSectionModel),
	type: types.enumeration<UserQuestionnaireStepType>(Object.values(UserQuestionnaireStepType)),
	show_continue_button: false,
	next_question_label: types.maybeNull(types.string),
	isCurrentStep: true,
	heading: types.maybeNull(types.string),
	text: types.maybeNull(types.string),
	image: types.maybeNull(ImageModel),
});

export interface UserQuestionnaireStepInstance extends Instance<typeof UserQuestionnaireStepModel> {}
export type UserQuestionnaireStepSnapshot = SnapshotIn<typeof UserQuestionnaireStepModel>;
