import React, { ReactNode } from "react";

import { stripHTML } from "@Core/utilities";

import styles from "./Question.module.scss";

type Props = {
	title: string;
	description?: string;
	children: ReactNode;
};

const Question = ({ title, description, children }: Props) => {
	return (
		<div className={styles.Wrapper}>
			<div className={styles.Question}>
				<h4
					className={styles.Title}
					dangerouslySetInnerHTML={{ __html: title.replace(/\*\*(.*?)\*\*/g, "<u>$1</u>") }}
				/>

				{description && <p>{stripHTML(description)}</p>}
			</div>

			<div className={styles.Answers}>{children}</div>
		</div>
	);
};

export default Question;
