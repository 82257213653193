import { flow, Instance, types } from "mobx-state-tree";

import { UserQuestionnaireStatus } from "@Core/constants/ENUMS";
import { QuestionnaireTokenService } from "@Core/services";

import { UserModel } from "@PNI/models";

const EmployeeStore = types
	.model({
		token: types.maybeNull(types.string),
		introduction: false,
		user: types.maybeNull(UserModel),
	})
	.actions((self) => ({
		login: flow(function* (token: string, zipcode: string, birthdate: string) {
			yield QuestionnaireTokenService.validateUser(token, birthdate, zipcode);
		}),

		load: flow(function* () {
			const response = yield QuestionnaireTokenService.me();

			if (response) {
				self.user = response;
			}
		}),
	}))
	.views((self) => ({
		get didIntroduction(): boolean {
			return self.user?.user_questionnaire.status !== UserQuestionnaireStatus.Pending;
		},
	}));

export interface EmployeeStoreInstance extends Instance<typeof EmployeeStore> {}

export default EmployeeStore;
