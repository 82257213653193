import React from "react";
import { useTranslation } from "react-i18next/";

import { Frame } from "@Core/components/UI";

import { StepList } from "@PNI/components/HOC";
import { usePNIStores } from "@PNI/stores";

import { RecommendedStepsProps } from "./RecommendedSteps.props";

export const RecommendedSteps = ({ className }: RecommendedStepsProps) => {
	const { t } = useTranslation();
	const { FileStore } = usePNIStores();

	const steps = FileStore.file?.getAdviceSteps.filter((step) => step.recommended === true) || [];

	return (
		<Frame title={t("FILES.RECOMMENDED_STEPS.TITLE")} className={className}>
			<Frame.Body padding="3">
				{FileStore.file?.hasCompletedEmployeeQuestionnaire ? (
					<StepList steps={steps} hasToggle />
				) : (
					<>
						<Frame variant="light">
							<Frame.Body padding="2">
								<p className="small mb-0 color-black-light">{t("FILES.RECOMMENDED_STEPS.EMPTY")}</p>
							</Frame.Body>
						</Frame>

						{FileStore.file?.getEmployeeSteps && <StepList steps={FileStore.file.getEmployeeSteps} />}
					</>
				)}
			</Frame.Body>
		</Frame>
	);
};
