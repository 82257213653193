import axios from "@Core/axios";
import { BaseService } from "@Core/services";
import { Prepr } from "@Core/utilities";

import { AssignmentType } from "@LNI/constants/ENUMS";
import { AssignmentSnapshot, CourseSnapshot } from "@LNI/models";

class CourseService extends BaseService<CourseSnapshot> {
	getCourses = async (): Promise<CourseSnapshot[] | null> => {
		const response = await axios.get(`${this.endpoint}`);

		if (response) {
			return response.data;
		}

		return null;
	};

	getAssignments = async (course: number): Promise<AssignmentSnapshot[] | null> => {
		const response = await axios.get(`courses/${course}/assignments`);

		if (response) {
			const value = response.data.map((assignment: any) => {
				if (assignment.type === AssignmentType.ARTICLE) {
					assignment.article = Prepr.format(assignment.article?.contents);
				}

				return {
					...assignment,
					category: "COURSE",
				};
			});

			return value;
		}

		return null;
	};

	setAnswer = async (course: number, assignment: number, question: number, answer: number): Promise<void> => {
		const data = {
			question: question,
			answer: answer,
		};

		await axios.post(`courses/${course}/assignments/${assignment}`, data);
	};

	setCompleted = async (course: number, assignment: number): Promise<void> => {
		const data = {
			isDone: true,
		};

		await axios.post(`courses/${course}/assignments/${assignment}`, data);
	};
}

export default new CourseService("courses");
