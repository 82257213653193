import { useMatomo } from "@datapunt/matomo-tracker-react";
import * as Sentry from "@sentry/react";
import cn from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Text from "@Core/components/Form/Text/Text";
import { Button, Loader, Tag } from "@Core/components/UI";
import { DataLayer } from "@Core/constants/ENUMS";
import { useCoreStores } from "@Core/stores";

import { PreprArticleCard } from "@PNI/components/Containers";
import { IArticle, IPreprRestTag, PreprArticleService, PreprRestService, TagGroup } from "@PNI/services";

import styles from "./ArticlesArchiveFilter.module.scss";
import { ArticlesArchiveFilterProps } from "./ArticlesArchiveFilter.props";

export const ArticlesArchiveFilter = ({ filter, onChange }: ArticlesArchiveFilterProps) => {
	const { t } = useTranslation();
	const { trackEvent } = useMatomo();
	const { UserStore } = useCoreStores();

	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [isKeywordsOpen, setKeywordsOpen] = useState(false);
	const [isFilterOpen, setFilterOpen] = useState(false);

	const [disabled, setDisabled] = useState(false);
	const [popularArticles, setPopularArticles] = useState<IArticle[]>();
	const [keywords, setKeywords] = useState<IPreprRestTag[]>();
	const [themes, setThemes] = useState<IPreprRestTag[]>();
	const [search, setSearch] = useState<string>();
	const [activeKeywords, setActiveKeywords] = useState<IPreprRestTag[]>();
	const [activeTheme, setActiveTheme] = useState<IPreprRestTag>();

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);
				setError(false);

				const _popularArticles = await PreprArticleService.getPopular();
				setPopularArticles(_popularArticles.Artikels?.items);

				const _keywords = await PreprRestService.getTagGroup(TagGroup.KEYWORDS);
				setKeywords(_keywords);

				const _themes = await PreprRestService.getTagGroup(TagGroup.THEMES);
				setThemes(_themes);

				setLoaded(true);
			} catch (e) {
				setError(true);
				Sentry.captureException(error);
			} finally {
				setLoading(false);
			}
		})();
	}, []);

	useEffect(() => {
		setDisabled(true);

		setSearch(filter?.search);
		setActiveTheme(filter?.theme);
		setActiveKeywords(filter?.keywords);

		setDisabled(false);
	}, [filter]);

	useEffect(() => {
		if (!disabled) {
			onChange({
				search: search,
				theme: activeTheme,
				keywords: activeKeywords,
			});
		}
	}, [search, activeTheme, activeKeywords, disabled]);

	const handleKeywordClick = useCallback(
		(keyword: IPreprRestTag) => () => {
			setActiveKeywords((oldActiveKeywords) => {
				const newActiveKeywords = [...(oldActiveKeywords || [])];

				const foundKeywordIndex = newActiveKeywords.findIndex(
					(newActiveKeyword) => newActiveKeyword.id === keyword.id,
				);

				if (foundKeywordIndex !== -1) {
					newActiveKeywords.splice(foundKeywordIndex, 1);
				} else {
					trackEvent({
						name: keyword.body,
						action: DataLayer.KEYWORD_SEARCH,
						category: String(UserStore.getCompanyId),
					});

					newActiveKeywords.push(keyword);
				}

				return newActiveKeywords;
			});
		},
		[],
	);

	const handleThemeClick = useCallback(
		(theme: IPreprRestTag) => () => {
			setActiveTheme((oldActiveTheme) => {
				if (oldActiveTheme !== theme) {
					trackEvent({
						name: theme.body,
						action: DataLayer.THEME_SEARCH,
						category: String(UserStore.getCompanyId),
					});

					return theme;
				}

				return undefined;
			});
		},
		[],
	);

	const handleSearch = useCallback((event: any) => {
		setSearch(event.target.value);
	}, []);

	const handleToggle = useCallback(() => {
		setFilterOpen((oldFilterOpen) => !oldFilterOpen);
	}, []);

	return (
		<div className={styles.ArticlesArchiveFilter}>
			<Button className={styles.Toggle} tertiary block onClick={handleToggle}>
				{t(`ARTICLES.FILTER.TOGGLE_${isFilterOpen ? "CLOSE" : "OPEN"}`)}
			</Button>

			<div
				className={cn(styles.Filters, {
					[styles.isOpen]: isFilterOpen,
				})}
			>
				{loading && <Loader width={50} color="#56bbad" className="mt-md" />}

				{error && <div className="col-lg-12 mb-md">{t("DASHBOARD.ARTICLES.ERROR")}</div>}

				{loaded && (
					<>
						{themes && themes.length > 0 && (
							<div className={styles.Block}>
								<Text
									id="search"
									placeholder={t("ARTICLES.FILTER.THEMES.PLACEHOLDER")}
									field={{
										name: "search",
										onChange: handleSearch,
										value: search || "",
									}}
								/>

								<h5 className="mt-md mb-sm">{t("ARTICLES.FILTER.THEMES.TITLE")}</h5>

								<div
									className={cn(styles.Themes, {
										[styles.isOpen]: isKeywordsOpen,
									})}
								>
									{themes.map((theme) => {
										const isActive = activeTheme?.id === theme.id;

										return (
											<Button
												className={styles.Button}
												primary={!isActive}
												secondary={isActive}
												onClick={handleThemeClick(theme)}
												small
												link
												iconRight="fa-long-arrow-right"
												key={theme.id}
											>
												{t("ARTICLES.FILTER.THEMES.BUTTON", {
													name: theme.body.toLowerCase(),
												})}
											</Button>
										);
									})}
								</div>
							</div>
						)}

						{keywords && keywords.length > 0 && (
							<div className={styles.Block}>
								<h5 className="mb-sm">{t("ARTICLES.FILTER.SUBJECTS.TITLE")}</h5>

								<div
									className={cn(styles.Keywords, {
										[styles.isOpen]: isKeywordsOpen,
									})}
								>
									{keywords.map((keyword) => {
										const isActive = activeKeywords?.some(
											(activeKeyword) => activeKeyword.id === keyword.id,
										);

										return (
											<Tag
												key={keyword.id}
												name={keyword.body}
												slug={keyword.slug}
												active={isActive}
												onClick={handleKeywordClick(keyword)}
											/>
										);
									})}
								</div>

								<Button
									className="mt-sm"
									primary
									link
									iconRight="fa-long-arrow-right"
									small
									onClick={() => setKeywordsOpen(!isKeywordsOpen)}
								>
									{t(`ARTICLES.FILTER.SUBJECTS.BUTTON_${isKeywordsOpen ? "LESS" : "MORE"}`)}
								</Button>
							</div>
						)}

						{popularArticles && popularArticles.length > 0 && (
							<div className={styles.Block}>
								<h5 className="mb-sm">{t("ARTICLES.FILTER.POPULAR.TITLE")}</h5>

								{popularArticles.map((popularArticle) => {
									return <PreprArticleCard key={popularArticle._id} article={popularArticle} small />;
								})}
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};
