import ADVISOR from "./advisor.json";
import ARTICLES from "./articles.json";
import COMPONENTS from "./components.json";
import FORMS from "./forms.json";
import LOGIN from "./login.json";
import REGISTER from "./register.json";
import SHARED from "./shared.json";

const data = {
	translation: {
		COMPONENTS,
		SHARED,
		FORMS,
		LOGIN,
		ADVISOR,
		ARTICLES,
		REGISTER,
	},
};

export default data;
