import {
	IPreprAsset,
	IPreprDragDrop,
	IPreprModel,
	IPreprTag,
	PreprCollectionResponse,
} from "../PreprGraphqlService/PreprGraphqlService.props";

export interface IArticle extends IPreprModel {
	title: string;
	intro: string;
	exclusive: string;
	_read_time: number;
	module: IPreprTag[];
	theme_pni: IPreprTag[];
	keywords_pni: IPreprTag[];
	related: IArticleRelated[];
	download: IPreprAsset[];
	content: IPreprDragDrop;

	activation_title: string;
	activation_intro: string;
	activation_tips: string;
	activation_tips_title: string;
	activation_tips_icon: string;
	activation_tips_links: string;
	activation_steps: string;
	activation_steps_title: string;
	activation_steps_icon: string;
	activation_steps_links: string;
}

export interface IArticleRelated extends IPreprModel {
	title: string;
	intro: string;
	exclusive: boolean;
	theme_pni: IPreprTag[];
	keywords_pni: IPreprTag[];
	module: IPreprTag[];
}

export interface ArticleResponse {
	Artikel: IArticle;
}

export enum ArticleModule {
	PNI = "Professional & Inzetbaarheid",
}

export interface FeaturedArticlesResponse {
	Artikels: PreprCollectionResponse<IArticle>;
}
