import { applySnapshot, flow, getSnapshot, Instance, types } from "mobx-state-tree";

import { CoreRootStore } from "@Core/stores";

import EmployeeStore from "./EmployeeStore/EmployeeStore";
import ExportStore from "./ExportStore/ExportStore";
import FileStore from "./FileStore/FileStore";
import InboxStore from "./InboxStore/InboxStore";

let initialState = {};

const PNIRootStoreModel = types
	.model("OOMStoreModel", {
		InboxStore: InboxStore,
		ExportStore: ExportStore,
		FileStore: FileStore,
		EmployeeStore: EmployeeStore,

		isLoading: false,
		isLoaded: false,
	})
	.actions((self) => ({
		afterCreate() {
			initialState = getSnapshot(self);
		},

		reset() {
			applySnapshot(self, initialState);
		},

		load: flow(function* () {
			if (self.isLoading === false) {
				self.isLoading = true;

				if (CoreRootStore.UserStore.isAuthenticated) {
					if (CoreRootStore.UserStore.hasFilesPermissions) {
						yield self.FileStore.load();
					}

					yield Promise.all([self.InboxStore.load()]);

					self.isLoading = false;
				}

				self.isLoaded = true;
			}
		}),
	}));

const PNIRootStore = PNIRootStoreModel.create({
	FileStore: {},
	ExportStore: {},
	EmployeeStore: {},
	InboxStore: {
		isLoading: false,
		isFinished: false,
	},
});

export interface PNIRootStoreInstance extends Instance<typeof PNIRootStore> {}

export default PNIRootStore;
