import cn from "classnames";
import React from "react";

import {
	PreprRendererAsset,
	PreprRendererQuote,
	PreprRendererSpotify,
	PreprRendererText,
	PreprRendererTextWithFile,
} from "./internal/components";
import styles from "./PreprRenderer.module.scss";
import { PreprRendererProps } from "./PreprRenderer.props";

export const PreprRenderer = ({ content, className }: PreprRendererProps) => {
	if (content && content.length > 0) {
		return (
			<div className={cn(styles.PreprRenderer, className, "container")}>
				{content.map((item) => {
					return (
						<div key={item._id}>
							{item._type === "Text" && <PreprRendererText element={item as any} />}
							{item._type === "SpotifyPlaylist" && <PreprRendererSpotify element={item as any} />}
							{item._type === "Assets" && <PreprRendererAsset element={item as any} />}
							{item._type === "Quote" && <PreprRendererQuote element={item as any} />}
							{item._type === "Text met bestand" && <PreprRendererTextWithFile element={item as any} />}
						</div>
					);
				})}
			</div>
		);
	}

	return null;
};
