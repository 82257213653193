import React, { useState } from "react";
import { useTranslation } from "react-i18next/";

import Form from "@Core/components/Form";
import { Button } from "@Core/components/UI";
import { useCountdown } from "@Core/hooks";
import { AuthService } from "@Core/services";
import Yup from "@Core/validation";

import Props from "./LoginSmsForm.props";
import { LoginSmsFormValues } from "./LoginSmsForm.props";

const LoginSmsForm = ({ initialValues, onSubmit, onSuccess }: Props) => {
	const { t } = useTranslation();
	const [smsToken, setSmsToken] = useState<string>(initialValues.sms_token);
	const [error, setError] = useState<string>();

	const countdown = useCountdown(10);

	const validationSchema = Yup.object().shape({
		code: Yup.string().required(),
	});

	const handleResend = async () => {
		countdown.start();

		try {
			const response = await AuthService.resend({ sms_token: smsToken });
			setSmsToken(response.sms_token);
			setError("");
		} catch (e) {
			setError(t("LOGIN.SMS.ERROR_RESEND"));
		}
	};

	const handleSubmit = async (values: LoginSmsFormValues) => {
		return onSubmit({
			...values,
			sms_token: smsToken,
		});
	};

	return (
		<Form.Form
			identifier="LOGIN_SMS_FORM"
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
			onSuccess={onSuccess}
			submit={t("LOGIN.SMS.CONFIRM")}
		>
			<Form.Item
				id="code"
				name={t("LOGIN.SMS.LABEL_CODE")}
				render={(props) => (
					<>
						<Form.Text {...props} icon="key" />

						<Button
							small
							type="button"
							className="color-support mt-xs"
							disabled={countdown.count !== 0}
							onClick={handleResend}
						>
							{t("LOGIN.SMS.LABEL_RESEND")} {countdown.count > 0 && `(${countdown.count})`}
						</Button>

						{error && <p className="small color-error">{error}</p>}
					</>
				)}
			/>
		</Form.Form>
	);
};

export default LoginSmsForm;
