import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next/";
import { Link } from "react-router-dom";

import { Footer } from "@Core/components/Layouts";

import Header from "../Header/Header";
import styles from "./Page.module.scss";

type Props = {
	children: ReactNode;
	breadcrumbs?: { to?: string; name?: string }[];
};

const Authorized = ({ children, breadcrumbs }: Props) => {
	const { t } = useTranslation();
	const getBreadcrumbs = () => {
		if (breadcrumbs) {
			return breadcrumbs.map((item, key) => {
				if (item.name) {
					if (item.to) {
						return (
							<Link key={key} to={item.to}>
								<i className="far fa-chevron-right"></i>
								{item.name}
							</Link>
						);
					} else {
						return (
							<span key={key}>
								<i className="far fa-chevron-right"></i>
								{item.name}
							</span>
						);
					}
				}

				return null;
			});
		}
	};

	return (
		<div className={styles.Wrapper}>
			<Header />

			<div className={styles.Breadcrumbs}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<Link to="/">{t("DEFAULT.HOME")}</Link>
							{getBreadcrumbs()}
						</div>
					</div>
				</div>
			</div>

			<div className={styles.Body}>{children}</div>

			<Footer />
		</div>
	);
};

export default Authorized;
