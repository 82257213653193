import React from "react";

import { IFieldProps } from "@Core/interfaces";

import styles from "./Number.module.scss";

type Props = {
	id: string;
	name: string;
	field: IFieldProps;

	min?: number;
	max?: number;
	step?: number;
	placeholder?: string;
	icon?: string;
};

const Number = ({ id, name, field, min, max, step, placeholder, icon }: Props) => {
	return (
		<div className={styles.Field}>
			<input
				id={id}
				className={styles.Input}
				type="number"
				min={min}
				max={max}
				step={step ? step : 1}
				{...field}
				placeholder={placeholder}
			/>

			{icon && <i className={`${styles.Icon} far fa-${icon}`}></i>}
		</div>
	);
};

export default Number;
