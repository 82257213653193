import React from "react";
import { useTranslation } from "react-i18next/";

import { Frame } from "@Core/components/UI";

import { SeperatedList } from "@PNI/components/Containers";
import { usePNIStores } from "@PNI/stores";

import { StressorsProps } from "./Stressors.props";

export const Stressors = ({ className }: StressorsProps) => {
	const { t } = useTranslation();
	const { FileStore } = usePNIStores();

	const stressors = FileStore.file?.stressors ?? [];

	return (
		<Frame
			title={t("FILES.STRESSORS.TITLE")}
			icon="fas fa-head-side-brain"
			iconVariant="danger"
			className={className}
			variant={stressors.length ? undefined : "light"}
		>
			<Frame.Body padding="3">
				{stressors.length ? (
					<SeperatedList list={stressors} />
				) : (
					<p className="small mb-0 color-support">{t("FILES.STRESSORS.EMPTY")}</p>
				)}
			</Frame.Body>
		</Frame>
	);
};
