import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Block, Button } from "@Core/components/UI";

import { InboxMessage } from "@PNI/components/Containers";
import { usePNIStores } from "@PNI/stores";

export const DashboardInbox = () => {
	const MESSAGES_PER_PAGE = 4;

	const { InboxStore } = usePNIStores();
	const [page, setPage] = useState(1);
	const { t } = useTranslation();

	const inboxMessages = useMemo(() => {
		const messages = [...InboxStore.messages];

		return messages.splice(0, page * MESSAGES_PER_PAGE);
	}, [page]);

	const handlePage = useCallback(() => {
		setPage((oldPage) => {
			if (page * MESSAGES_PER_PAGE >= InboxStore.messages.length) {
				return 1;
			}

			return oldPage + 1;
		});
	}, [page, MESSAGES_PER_PAGE, InboxStore.messages]);

	return (
		<Block name={t("DASHBOARD.INBOX.TITLE")}>
			<div className="row">
				<div className="col-xl-9">
					<p>{t("DASHBOARD.INBOX.INTRO")}</p>
				</div>
			</div>

			{inboxMessages.length === 0 && <i>{t("DASHBOARD.INBOX.NONE")}</i>}

			{inboxMessages.map((message) => {
				return <InboxMessage key={message.id} message={message} />;
			})}

			{MESSAGES_PER_PAGE < InboxStore.messages.length && (
				<Button primary iconRight="fa-long-arrow-right" className="mt-sm" onClick={handlePage}>
					{page * MESSAGES_PER_PAGE >= InboxStore.messages.length
						? t("DASHBOARD.INBOX.BUTTON_LESS")
						: t("DASHBOARD.INBOX.BUTTON_MORE")}
				</Button>
			)}
		</Block>
	);
};
