import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

import { Modal } from "@Core/components/Layouts";
import { Button } from "@Core/components/UI";
import { useDialog } from "@Core/hooks";

import { GenerateFileForm, GenerateFileFormValues } from "@PNI/forms";
import { EmployeeService } from "@PNI/services";
import { usePNIStores } from "@PNI/stores";

import styles from "./DashboardPageGenerate.module.scss";

export const DashboardPageGenerate = observer(() => {
	const { t } = useTranslation();
	const { isVisible, toggle } = useDialog();
	const history = useHistory();

	const { FileStore } = usePNIStores();

	const onSubmit = async (values: GenerateFileFormValues) => {
		try {
			const response = await FileStore.createFile(values);
			await FileStore.load();

			toggle();

			toast.success(t("FILES.GENERATE.SUCCESS"));
			history.push(`/dossiers/${response.id}`);
		} catch (e) {
			toast.error(t("FILES.GENERATE.ERROR"));
		}
	};

	const onDebug = useCallback(async () => {
		await EmployeeService.debug();

		alert(t("FILES.GENERATE.DEBUG.SUCCESS"));

		window.location.reload();
	}, []);

	return (
		<div>
			<div className="d-flex">
				<Button tertiary className={styles.Button} onClick={toggle}>
					{t("FILES.GENERATE.BUTTON")}
				</Button>

				<Button tertiary className="ml-sm d-none" onClick={onDebug}>
					{t("FILES.GENERATE.DEBUG.BUTTON")}
				</Button>
			</div>

			<Modal title={t("FILES.GENERATE.POPUP_TITLE")} isVisible={isVisible} hide={toggle}>
				<GenerateFileForm onSubmit={onSubmit} />
			</Modal>
		</div>
	);
});
