import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { BaseModel } from "./BaseModel";

export const ContactModel = BaseModel.props({
	function: types.string,
	email: types.string,
	phone_number: types.string,
	first_name: types.string,
	last_name: types.string,
	avatar: types.string,
}).views((self) => ({
	get getName() {
		return `${self.first_name} ${self.last_name}`;
	},
}));

export interface ContactInstance extends Instance<typeof ContactModel> {}
export type ContactSnapshot = SnapshotIn<typeof ContactModel>;
