import { applySnapshot, flow, getSnapshot, Instance, types } from "mobx-state-tree";

import CoreRootStore from "@Core/stores/CoreRootStore";

import ArticleStore from "./ArticleStore/ArticleStore";
import ChallengeStore from "./ChallengeStore/ChallengeStore";
import CourseStore from "./CourseStore/CourseStore";
import FileStore from "./FileStore/FileStore";
import MomentStore from "./MomentStore/MomentStore";
import OnboardingStore from "./OnboardingStore/OnboardingStore";

let initialState = {};

const LNIRootStoreModel = types
	.model("LNIStoreModel", {
		CourseStore: CourseStore,
		OnboardingStore: OnboardingStore,
		ChallengeStore: ChallengeStore,
		ArticleStore: ArticleStore,
		MomentStore: MomentStore,
		FileStore: FileStore,

		isLoading: false,
		isLoaded: false,
	})
	.actions((self) => ({
		afterCreate() {
			initialState = getSnapshot(self);
		},

		reset() {
			applySnapshot(self, initialState);
		},

		load: flow(function* () {
			if (self.isLoading === false) {
				self.isLoading = true;

				if (CoreRootStore.UserStore.isAuthenticated) {
					// Company Specific Components
					yield Promise.all([
						CoreRootStore.UserStore.hasLNIPermissions ? self.CourseStore.load() : null,
						CoreRootStore.UserStore.hasMomentsPermissions ? self.MomentStore.load() : null,
						CoreRootStore.UserStore.hasAOMPermissions ? self.FileStore.load() : null,
					]);

					// Non Company Specific Components
					yield Promise.all([self.OnboardingStore.getStatus(), self.ArticleStore.load()]);

					// Load Challenges & Articles if did onboarding
					if (self.OnboardingStore.progress?.didOnboarding) {
						yield self.ChallengeStore.load();
					}
				}

				self.isLoaded = true;
			}
		}),
	}));

const LNIRootStore = LNIRootStoreModel.create({
	CourseStore: {},
	OnboardingStore: {},
	ChallengeStore: {},
	ArticleStore: {},
	MomentStore: {},
	FileStore: {},
});

export interface LNIRootStoreInstance extends Instance<typeof LNIRootStore> {}

export default LNIRootStore;
