import cn from "classnames";
import { FormikValues } from "formik";
import merge from "lodash.merge";
import { observer } from "mobx-react-lite";
import { addMiddleware } from "mobx-state-tree";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";
import LazyLoad from "react-lazyload";
import { useHistory, useLocation } from "react-router-dom";

import Form from "@Core/components/Form";
import { Modal } from "@Core/components/Layouts";
import { Block, Button } from "@Core/components/UI";
import { UserQuestionnaireStatus, UserQuestionnaireStepType } from "@Core/constants/ENUMS";
import { useDialog } from "@Core/hooks";
import {
	ImageInstance,
	UserQuestionnaireInstance,
	UserQuestionnaireQuestionInstance,
	UserQuestionnairesAdditionalInstance,
	UserQuestionnaireSectionsInstance,
	UserQuestionnaireStepInstance,
} from "@Core/models";
import { useCoreStores } from "@Core/stores";
import { getImage } from "@Core/utilities";
import Yup from "@Core/validation";

import QuestionnaireScore from "../QuestionnaireScore/QuestionnaireScore";
import styles from "./Questionnaire.module.scss";

type QuestionnaireQuestionProps = {
	question: UserQuestionnaireQuestionInstance;
	step: UserQuestionnaireStepInstance;
	disabled?: boolean;
	block?: boolean;
	hide?: boolean;
};

const QuestionnaireQuestion = ({ question, step, disabled, hide, block }: QuestionnaireQuestionProps) => {
	const FormQuestionaires: { [key: string]: any } = {
		multiple: Form.Questionnaire.Multiple,
		single: Form.Questionnaire.Single,
		single_dropdown: Form.Questionnaire.SingleDropdown,
		open: Form.Questionnaire.Open,
		open_short: Form.Questionnaire.OpenShort,
		number: Form.Questionnaire.Number,
		scale: Form.Questionnaire.Scale,
		scale_slider: Form.Questionnaire.ScaleSlider,
		scale_emoji: Form.Questionnaire.ScaleEmoji,
		date: Form.Questionnaire.Date,
		statement: Form.Questionnaire.Statement,
	};

	const FormQuestionaire = FormQuestionaires[question.type]
		? FormQuestionaires[question.type]
		: FormQuestionaires["single"];

	const getQuestion = () => (
		<Form.Questionnaire.Question key={step.id} title={question.title} description={question.text || ""}>
			<Form.Item
				id={`S${step.id}Q${question.id}`}
				render={(props) => <FormQuestionaire disabled={disabled} hide={hide} {...props} question={question} />}
			/>
		</Form.Questionnaire.Question>
	);

	if (block) {
		return (
			<Block className="mb-0" overflow>
				{getQuestion()}
			</Block>
		);
	}

	return getQuestion();
};

interface QuestionnaireContentProps {
	heading: string;
	text: string;
	image?: ImageInstance;
}

const QuestionnaireContent = ({ heading, text, image }: QuestionnaireContentProps) => {
	return (
		<div className={styles.Content}>
			<div>
				<h4>{heading}</h4>
				<div className="HTML" dangerouslySetInnerHTML={{ __html: text }} />
			</div>

			{image && (
				<div className={styles.Image}>
					<LazyLoad>
						<img src={getImage(image.path)} alt={heading} />
					</LazyLoad>
				</div>
			)}
		</div>
	);
};

type QuestionnaireResultsProps = {
	results: UserQuestionnaireStepInstance[];
	block?: boolean;
};

const QuestionnaireResults = observer(({ results, block }: QuestionnaireResultsProps) => {
	const { t } = useTranslation();

	if (results) {
		const data = results.map((step: UserQuestionnaireStepInstance) => {
			if (step.type === UserQuestionnaireStepType.QUESTION && step.question) {
				return (
					<div key={step.id} className={styles.Progress}>
						<QuestionnaireQuestion question={step.question} step={step} disabled />
					</div>
				);
			} else if (step.type === UserQuestionnaireStepType.SCORE && step?.section?.theme_scores) {
				return (
					<div key={step.id} className={styles.Progress}>
						<h4>{t("FORMS.QUESTIONNAIRE.SCORES.TITLE")}</h4>
						<p>{t("FORMS.QUESTIONNAIRE.SCORES.SCORE")}</p>
						<QuestionnaireScore scores={step.section.theme_scores} />
					</div>
				);
			} else if (step.type === UserQuestionnaireStepType.CONTENT && step?.heading && step?.text) {
				return (
					<div key={step.id} className={styles.Progress}>
						<QuestionnaireContent
							heading={step.heading}
							text={step.text}
							image={step?.image || undefined}
						/>
					</div>
				);
			}

			return <></>;
		});

		if (block) {
			return <Block>{data}</Block>;
		}

		return <div>{data}</div>;
	}

	return <></>;
});

type QuestionnaireFormProps = {
	questionnaire: UserQuestionnaireInstance;
	onCompletion?: () => void;
	block?: boolean;
};

const QuestionnaireForm = observer(({ questionnaire, onCompletion, block }: QuestionnaireFormProps) => {
	const { t } = useTranslation();
	const { isVisible, toggle } = useDialog();
	const [isLoading, setLoading] = useState(false);

	const [initialValues, setInitialValues] = useState<FormikValues>({});
	const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));

	// Load and reload question
	const currentStepId = questionnaire.current_step?.id;
	const progressStepId = questionnaire.progressed_steps.length > 0 ? questionnaire.progressed_steps[0]?.id : 0;

	useEffect(() => {
		if (questionnaire.status === UserQuestionnaireStatus.Completed) {
			const values: FormikValues = [];

			questionnaire.progressed_steps.map((step: UserQuestionnaireStepInstance) => {
				return merge(values, step.question ? step.question.getAnswer(step) : {});
			});

			setInitialValues(values);
		} else {
			if (questionnaire.current_step) {
				const question = questionnaire.current_step.question;

				if (question) {
					// Set initial values
					setInitialValues({ ...question.getAnswer(questionnaire.current_step) });

					// Set validation schema for current question
					setValidationSchema(question.getValidation(questionnaire.current_step));
				} else {
					setValidationSchema(Yup.object().shape({}));

					setInitialValues({
						answer: "SKIP",
						score: true,
					});
				}
			}
		}
	}, [
		questionnaire,
		questionnaire.status,
		// questionnaire.current_step?.question?.user_answer,
		currentStepId,
		progressStepId,
	]);

	// Load previous question
	const previousQuestion = async () => {
		if (questionnaire.hasPrevious) {
			await questionnaire.previous();
		}
	};

	// Submit current answer and load next question
	const nextQuestion = async (values: any) => {
		if (questionnaire.hasNext) {
			await questionnaire.next(values);

			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});

			if (questionnaire.status === UserQuestionnaireStatus.PendingCompletion) {
				toggle();
			}
		}
	};

	// Finishes the questionnaire
	const finishQuestionnaire = async () => {
		setLoading(true);
		await questionnaire.finish();
		setLoading(false);

		toggle();
		onCompletion && onCompletion();
	};

	return (
		<div
			className={cn([styles.Questionnaire], {
				[styles.Block]: block,
			})}
		>
			<Form.Form
				identifier="QUESTIONNAIRE_FORM"
				onInitialValues={questionnaire.status !== UserQuestionnaireStatus.Completed}
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values) => nextQuestion(values)}
			>
				{questionnaire.status === UserQuestionnaireStatus.Completed && (
					<QuestionnaireResults results={questionnaire.progressed_steps} block={block} />
				)}

				{questionnaire.status !== UserQuestionnaireStatus.Completed && (
					<>
						{questionnaire.current_step?.type === UserQuestionnaireStepType.QUESTION &&
							questionnaire.current_step.question && (
								<QuestionnaireQuestion
									question={questionnaire.current_step.question}
									step={questionnaire.current_step}
									block={block}
								/>
							)}

						{questionnaire.current_step?.type === UserQuestionnaireStepType.SCORE &&
							questionnaire.current_step.section?.theme_scores && (
								<Block>
									<h4>{t("FORMS.QUESTIONNAIRE.SCORES.TITLE")}</h4>
									<p>{t("FORMS.QUESTIONNAIRE.SCORES.SCORE")}</p>
									<QuestionnaireScore scores={questionnaire.current_step?.section?.theme_scores} />
								</Block>
							)}

						{questionnaire.current_step?.type === UserQuestionnaireStepType.CONTENT &&
							questionnaire.current_step.heading &&
							questionnaire.current_step.text && (
								<Block>
									<QuestionnaireContent
										heading={questionnaire.current_step.heading}
										text={questionnaire.current_step.text}
										image={questionnaire.current_step?.image || undefined}
									/>
								</Block>
							)}

						<div className={cn([styles.Buttons, "btn-row"])}>
							<Button
								disabled={!questionnaire.hasPrevious}
								primary
								iconLeft="fa-long-arrow-left"
								link
								onClick={() => previousQuestion()}
							>
								{t("FORMS.QUESTIONNAIRE.PREVIOUS_QUESTION")}
							</Button>

							<Button
								disabled={!questionnaire.hasNext}
								tertiary
								iconRight="fa-long-arrow-right"
								type="submit"
							>
								{questionnaire.current_step?.next_question_label ||
									t("FORMS.QUESTIONNAIRE.NEXT_QUESTION")}
							</Button>
						</div>

						<Modal title={t("FORMS.QUESTIONNAIRE.POPUP_TITLE")} isVisible={isVisible} hide={toggle}>
							<p>{t("FORMS.QUESTIONNAIRE.POPUP_TEXT")}</p>

							<Button primary onClick={() => finishQuestionnaire()} disabled={isLoading}>
								{t("FORMS.QUESTIONNAIRE.POPUP_BUTTON")}
							</Button>
						</Modal>
					</>
				)}
			</Form.Form>
		</div>
	);
});

type QuestionnaireSectionsProps = {
	questionnaire: UserQuestionnaireInstance;
};

const QuestionnaireSections = observer(({ questionnaire }: QuestionnaireSectionsProps) => {
	return (
		<div className={styles.Sections}>
			<div className={styles.SectionWrapper}>
				{questionnaire.sections.map((section: UserQuestionnaireSectionsInstance) => (
					<div
						key={section.section.id}
						className={cn([styles.Section], {
							[styles.SectionDone]: section.isDone,
							[styles.SectionCurrent]: section.isCurrent,
						})}
					>
						<img src={getImage(section.section?.icon || "")} alt={section.section.title} />
					</div>
				))}
			</div>
		</div>
	);
});

type QuestionnaireExpireProps = {
	questionnaire: UserQuestionnaireInstance;
};

const QuestionnaireExpire = ({ questionnaire }: QuestionnaireExpireProps) => {
	const totalTimeout = 20;

	const { isVisible, toggle, visible } = useDialog();
	const { t } = useTranslation();
	const [timeout, setTimeout] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeout((timeout) => timeout + 1);
		}, 1000 * 60);

		return () => clearInterval(timer);
	}, []);

	useEffect(() => {
		if (timeout === 10 || timeout === 15 || timeout > totalTimeout) {
			visible(true);
		}
	}, [timeout, visible]);

	const onRestart = async () => {
		visible(false);
		setTimeout(0);

		await questionnaire.load();
	};

	addMiddleware(questionnaire, (call, next) => {
		setTimeout(0);
		return next(call);
	});

	if (totalTimeout - timeout >= 0) {
		return (
			<Modal title={t("FILES.QUESTIONNAIRE.EXPIRE.POPUP_TITLE")} isVisible={isVisible}>
				<p>{t("FILES.QUESTIONNAIRE.EXPIRE.POPUP_DESCRIPTION", { count: totalTimeout - timeout })}</p>

				<Button tertiary onClick={toggle}>
					{t("FILES.QUESTIONNAIRE.EXPIRE.POPUP_BUTTON")}
				</Button>
			</Modal>
		);
	} else {
		return (
			<Modal title={t("FILES.QUESTIONNAIRE.EXPIRED.POPUP_TITLE")} isVisible={isVisible}>
				<p>{t("FILES.QUESTIONNAIRE.EXPIRED.POPUP_DESCRIPTION", { count: totalTimeout - timeout })}</p>

				<Button tertiary onClick={onRestart}>
					{t("FILES.QUESTIONNAIRE.EXPIRED.POPUP_BUTTON")}
				</Button>
			</Modal>
		);
	}
};

type QuestionnaireProps = {
	id?: number;
	onCompletion?: () => void;
	block?: boolean;
	expire?: boolean;
};

const Questionnaire = ({ id, onCompletion, block, expire }: QuestionnaireProps) => {
	const { QuestionnaireStore } = useCoreStores();

	useEffect(() => {
		!!id && QuestionnaireStore.load(id);

		return () => {
			!!id && QuestionnaireStore.clear();
		};
	}, [QuestionnaireStore, id]);

	if (QuestionnaireStore.questionnaire && id) {
		return (
			<>
				{expire && QuestionnaireStore.questionnaire.status === UserQuestionnaireStatus.InProgress && (
					<QuestionnaireExpire questionnaire={QuestionnaireStore.questionnaire} />
				)}

				{!QuestionnaireStore.questionnaire.isCompleted &&
					QuestionnaireStore.questionnaire.questionnaire.show_section_progress && (
						<QuestionnaireSections questionnaire={QuestionnaireStore.questionnaire} />
					)}

				<QuestionnaireForm
					questionnaire={QuestionnaireStore.questionnaire}
					onCompletion={onCompletion}
					block={block}
				/>
			</>
		);
	}

	return <></>;
};

export default observer(Questionnaire);
