import React from "react";
import { useTranslation } from "react-i18next";

import { FileGuidelineType } from "@Core/constants/ENUMS";

import styles from "./GuidelinesOverview.module.scss";
import { GuidelinesOverviewProps } from "./GuidelinesOverview.props";

export const GuidelinesOverview = ({ guidelines }: GuidelinesOverviewProps) => {
	const { t } = useTranslation();

	return (
		<div className={styles.GuidelinesOverview}>
			{!guidelines.length && <p className="small mb-0 color-support">{t("FILES.GUIDELINES.EMPTY")}</p>}

			{guidelines.map((guideline) => {
				return (
					<>
						<h6 className="h5">{guideline.title}</h6>

						<ul className={styles.List}>
							{guideline.items.map((item, idx) => {
								return (
									<li key={idx} className={styles.Item}>
										{item.type === FileGuidelineType.LINK && item.link && (
											<a href={item.link} target="_blank" rel="noopener noreferrer">
												{item.title}

												<i className="ml-xs fas fa-external-link-alt" />
											</a>
										)}

										{item.type === FileGuidelineType.PDF && item.link && (
											<a href={item.link} target="_blank" rel="noopener noreferrer">
												{item.title}

												<i className="ml-xs fas fa-file-pdf" />
											</a>
										)}

										{item.type === FileGuidelineType.TEXT && item.title}
									</li>
								);
							})}
						</ul>
					</>
				);
			})}
		</div>
	);
};
