import { flow, Instance, types } from "mobx-state-tree";

import { FileModel } from "@Core/models";
import { FileService } from "@Core/services";

const FileStore = types
	.model({
		files: types.array(FileModel),
		file: types.maybeNull(FileModel),
	})
	.actions((self) => ({
		load: flow(function* () {
			const response = yield FileService.getFiles();
			self.files.clear();

			if (response && response.length > 0) {
				self.files = response;
			}
		}),

		getFile: flow(function* (file: string, reload = false) {
			try {
				if (reload || !self.file || self.file.id !== parseInt(file)) {
					const response = yield FileService.getFile(parseInt(file));

					if (response) {
						self.file = response;
					} else {
						throw new Error("NOT_FOUND");
					}
				}
			} catch (e) {
				throw new Error(e);
			}
		}),
	}));

export interface FileStoreInstance extends Instance<typeof FileStore> {}

export default FileStore;
