import React from "react";
import { useTranslation } from "react-i18next/";
import { toast } from "react-toastify";

import { Frame } from "@Core/components/UI";

import { NotesForm, NotesFormValues } from "@PNI/forms";
import { usePNIStores } from "@PNI/stores";

import { NotesProps } from "./Notes.props";

export const Notes = ({ className }: NotesProps) => {
	const { t } = useTranslation();
	const { FileStore } = usePNIStores();

	const handleSubmit = async (values: NotesFormValues) => {
		const hasNotes = !!FileStore.file?.note;

		try {
			await FileStore.file?.setNote(values.note);

			toast.success(t(hasNotes ? "FILES.NOTES.FORM.EDIT_NOTES.SUCCESS" : "FILES.NOTES.FORM.ADD_NOTES.SUCCESS"));
		} catch {
			toast.error(t(hasNotes ? "FILES.NOTES.FORM.EDIT_NOTES.ERROR" : "FILES.NOTES.FORM.ADD_NOTES.ERROR"));
		}
	};

	return (
		<Frame title={t("FILES.NOTES.TITLE")} className={className}>
			<Frame.Body padding="0">
				<NotesForm initialValues={{ note: FileStore.file?.note ?? "" }} onSubmit={handleSubmit} />
			</Frame.Body>
		</Frame>
	);
};
