import ARTICLES from "./articles.json";
import ASSIGNMENT from "./assignment.json";
import CHALLENGES from "./challenges.json";
import COURSES from "./courses.json";
import DASHBOARD from "./dashboard.json";
import DEFAULT from "./default.json";
import FILES from "./files.json";
import LOGIN from "./login.json";
import MOMENTS from "./moments.json";
import PROFILE from "./profile.json";

const data = {
	translation: {
		DEFAULT,
		LOGIN,
		CHALLENGES,
		COURSES,
		ASSIGNMENT,
		ARTICLES,
		MOMENTS,
		PROFILE,
		FILES,
		DASHBOARD,
	},
};

export default data;
