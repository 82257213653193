import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { FileGuidelineType } from "@Core/constants/ENUMS";

const FileGuidelinesItemModel = types.model("FileGuidelinesItemModel", {
	type: types.enumeration<FileGuidelineType>(Object.values(FileGuidelineType)),
	title: types.string,
	link: types.maybeNull(types.string),
});

export const FileGuidelinesModel = types.model("FileGuidelinesModel", {
	title: types.string,
	items: types.array(FileGuidelinesItemModel),
});

export interface FileGuidelinesInstance extends Instance<typeof FileGuidelinesModel> {}
export type FileGuidelinesSnapshot = SnapshotIn<typeof FileGuidelinesModel>;
