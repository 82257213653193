import cn from "classnames";
import React from "react";

import styles from "./PreprRendererAsset.module.scss";
import { PreprRendererAssetProps } from "./PreprRendererAsset.props";

export const PreprRendererAsset = ({ element }: PreprRendererAssetProps) => {
	return (
		<div className={cn(styles.PreprRendererAsset, "row")}>
			<div className="col-xl-3 hidden-lg-down"></div>

			<div className="col-xl-6 col-lg-9 col-md-12">
				{element.items[0]._type === "Audio" && (
					<audio controls>
						<source src={element.items[0].cdn_files[0].url} type="audio/mpeg" />
					</audio>
				)}

				{element.items[0]._type === "Photo" && <img src={element.items[0].cdn_files[0].url} />}
			</div>
		</div>
	);
};
