import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next/";

import Form from "@Core/components/Form";
import { Button } from "@Core/components/UI";
import { CompanySearchType, FileType } from "@Core/constants/ENUMS";
import { useCache } from "@Core/hooks";
import { IFieldProps } from "@Core/interfaces";
import { CompanyService } from "@Core/services";
import { useCoreStores } from "@Core/stores";
import Yup, { validateEmployeeEmail } from "@Core/validation";

import Props, {
	GenerateFileFormValuesEmployee,
	GenerateFileFormValuesEmployeeSearch,
	GenerateFileFormValuesSupervisor,
} from "./GenerateFileForm.props";

type GenerateFileFormEmployeeSearchProps = {
	submit?: string;
	onSubmit: (values: any) => void;
	onSuccess?: (values: any) => void;
};

export const GenerateFileFormEmployeeSearch = ({
	submit,
	onSubmit,
	onSuccess,
}: GenerateFileFormEmployeeSearchProps) => {
	const { t } = useTranslation();
	const { UserStore } = useCoreStores();

	const [search, setSearch] = useState("");
	const [hasResults, setHasResults] = useState(0);
	const [employess, setEmployees] = useState<Record<string, string>>();

	const searchField: IFieldProps = {
		name: "search",
		value: search,
		onChange: (value: any) => {
			setSearch(value.target.value);
		},
	};

	useEffect(() => {
		const debounce = setTimeout(async () => {
			if (search) {
				const response = await CompanyService.search(
					UserStore.getCompanyId,
					search,
					CompanySearchType.EMPLOYEE,
				);

				if (response && response.length > 0) {
					setEmployees(
						response.reduce((total: Record<string, string>, supervisor: any) => {
							total[supervisor.id] = `${supervisor.name} (${supervisor.email})`;
							return total;
						}, {}) || {},
					);

					setHasResults(2);
				} else {
					setEmployees({});
					setHasResults(1);
				}
			} else {
				setEmployees({});
				setHasResults(0);
			}
		}, 250);

		return () => clearTimeout(debounce);
	}, [search, UserStore.getCompanyId]);

	const validationSchema = Yup.object().shape({
		employee_id: Yup.string().required(),
	});

	return (
		<Form.Form
			identifier="GENERATE_FILE_FORM_SEARCH_EMPLOYEE"
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			submit={submit || t("FILES.GENERATE.FORM.EMPLOYEE_SEARCH.SUBMIT")}
		>
			<Form.Item
				id="search"
				name={t("FILES.GENERATE.FORM.EMPLOYEE_SEARCH.EMPLOYEE")}
				render={() => (
					<Form.Text
						id="search"
						field={searchField}
						icon="search"
						placeholder={t("FILES.GENERATE.FORM.EMPLOYEE_SEARCH.SEARCH")}
					/>
				)}
			/>

			{hasResults === 1 && <strong>{t("FILES.GENERATE.FORM.EMPLOYEE_SEARCH.SEARCH_NONE")}</strong>}

			{hasResults === 2 && employess && (
				<Form.Item
					id="employee_id"
					render={(props) => (
						<Form.Select
							{...props}
							defaultValue=""
							disabled={hasResults !== 2}
							placeholder={t("FILES.GENERATE.FORM.EMPLOYEE_SEARCH.PLACEHOLDER")}
							options={employess ? employess : {}}
						/>
					)}
				/>
			)}
		</Form.Form>
	);
};

type GenerateFileFormEmployeeProps = {
	submit?: string;
	initialValues?: any;
	onSubmit: (values: any) => void;
	onSuccess?: (values: any) => void;
};

export const GenerateFileFormEmployee = ({
	submit,
	initialValues,
	onSubmit,
	onSuccess,
}: GenerateFileFormEmployeeProps) => {
	const { t } = useTranslation();

	const validationSchema = Yup.object().shape({
		email: Yup.string()
			.required()
			.email()
			.test(
				"unique",
				"STRING_UNIQUE_MAIL",
				useCache((value: string) => validateEmployeeEmail(value, initialValues?.email)),
			),

		phone_number: Yup.string().phone().required(),
		first_name: Yup.string().required(),
		last_name: Yup.string().required(),
		date_of_birth: Yup.date().required(),
		zipcode: Yup.string().zipcode().required(),
	});

	return (
		<Form.Form
			identifier="GENERATE_FILE_FORM_EMPLOYEE"
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			submit={submit || t("FILES.GENERATE.FORM.EMPLOYEE.SUBMIT")}
		>
			<Form.Item
				id="email"
				name={t("FILES.GENERATE.FORM.EMPLOYEE.EMAIL")}
				render={(props) => <Form.Text {...props} icon="envelope" />}
			/>

			<Form.Item
				id="phone_number"
				name={t("FILES.GENERATE.FORM.EMPLOYEE.PHONE")}
				render={(props) => <Form.Text {...props} placeholder="0612345678" icon="phone" />}
			/>

			<Form.Item
				id="first_name"
				name={t("FILES.GENERATE.FORM.EMPLOYEE.FIRST_NAME")}
				render={(props) => <Form.Text {...props} icon="user-alt" />}
			/>

			<Form.Item
				id="last_name"
				name={t("FILES.GENERATE.FORM.EMPLOYEE.LAST_NAME")}
				render={(props) => <Form.Text {...props} icon="user-alt" />}
			/>

			<Form.Item
				id="date_of_birth"
				name={t("FILES.GENERATE.FORM.EMPLOYEE.BIRTHDATE")}
				render={(props) => <Form.Date maxDate={new Date()} {...props} />}
			/>

			<Form.Item
				id="zipcode"
				name={t("FILES.GENERATE.FORM.EMPLOYEE.ZIPCODE")}
				render={(props) => <Form.Text {...props} icon="home" />}
			/>
		</Form.Form>
	);
};

type GenerateFileFormSupervisorProps = {
	onSubmit: (values: any) => void;
	onSuccess?: (values: any) => void;
};

export const GenerateFileFormSupervisor = ({ onSubmit, onSuccess }: GenerateFileFormSupervisorProps) => {
	const { t } = useTranslation();
	const { UserStore } = useCoreStores();

	const [search, setSearch] = useState("");
	const [hasResults, setHasResults] = useState(0);
	const [supervisors, setSupervisors] = useState<Record<string, string>>();

	const searchField: IFieldProps = {
		name: "search",
		value: search,
		onChange: (value: any) => {
			setSearch(value.target.value);
		},
	};

	useEffect(() => {
		const debounce = setTimeout(async () => {
			if (search) {
				const response = await CompanyService.search(
					UserStore.getCompanyId,
					search,
					CompanySearchType.SUPERVISOR,
				);

				if (response && response.length > 0) {
					setSupervisors(
						response.reduce((total: Record<string, string>, supervisor: any) => {
							total[supervisor.id] = `${supervisor.name} (${supervisor.email})`;
							return total;
						}, {}) || {},
					);

					setHasResults(2);
				} else {
					setSupervisors({});
					setHasResults(1);
				}
			} else {
				setSupervisors({});
				setHasResults(0);
			}
		}, 250);

		return () => clearTimeout(debounce);
	}, [search, UserStore.getCompanyId]);

	const validationSchema = Yup.object().shape({
		supervisor_id: Yup.string().required(),
	});

	return (
		<Form.Form
			identifier="GENERATE_FILE_FORM_SUPERVISOR"
			validationSchema={validationSchema}
			onSubmit={onSubmit}
			onSuccess={onSuccess}
			submit={t("FILES.GENERATE.FORM.SUPERVISOR.SUBMIT")}
		>
			<Form.Item
				id="search"
				name={t("FILES.GENERATE.FORM.SUPERVISOR.SUPERVISOR")}
				render={() => (
					<Form.Text
						id="search"
						field={searchField}
						icon="search"
						placeholder={t("FILES.GENERATE.FORM.SUPERVISOR.SEARCH")}
					/>
				)}
			/>

			{hasResults === 1 && <strong>{t("FILES.GENERATE.FORM.SUPERVISOR.SEARCH_NONE")}</strong>}

			{hasResults === 2 && supervisors && (
				<Form.Item
					id="supervisor_id"
					render={(props) => (
						<Form.Select
							{...props}
							defaultValue=""
							disabled={hasResults !== 2}
							placeholder={t("FILES.GENERATE.FORM.SUPERVISOR.PLACEHOLDER")}
							options={supervisors ? supervisors : {}}
						/>
					)}
				/>
			)}

			<Form.Item
				id="type"
				name={t("FILES.GENERATE.FORM.SUPERVISOR.TYPE")}
				render={(props) => (
					<Form.Select
						{...props}
						defaultValue=""
						placeholder={t("FILES.GENERATE.FORM.SUPERVISOR.PLACEHOLDER_TYPE")}
						options={{
							// [FileType.PREVENTIVE]: t("FILES.TYPE_PREVENTIVE"),
							[FileType.ABSENCE]: t("FILES.TYPE_ABSENCE"),
						}}
					/>
				)}
			/>
		</Form.Form>
	);
};

export const GenerateFileForm = ({ onSubmit, onSuccess }: Props) => {
	const { t } = useTranslation();
	const [step, setStep] = useState(0);
	const [employee, setEmployee] = useState<GenerateFileFormValuesEmployee>();
	const [employeeId, setEmployeeId] = useState<number>();

	const onSubmitEmployeeSearch = (values: GenerateFileFormValuesEmployeeSearch) => {
		setEmployeeId(values.employee_id);
	};

	const onSuccessEmployeeSearch = () => {
		setStep(2);
	};

	const onSubmitEmployee = (values: GenerateFileFormValuesEmployee) => {
		setEmployee(values);
	};

	const onSuccessEmployee = () => {
		setStep(2);
	};

	const onSubmitSupervisor = (values: GenerateFileFormValuesSupervisor) => {
		employeeId && (values.employee_id = employeeId);
		employee && (values.employee = employee);

		return onSubmit(values);
	};

	const onSuccessSupervisor = () => {
		if (onSuccess) {
			onSuccess();
		}
	};

	return (
		<>
			{step === 0 && (
				<>
					<GenerateFileFormEmployeeSearch
						onSubmit={onSubmitEmployeeSearch}
						onSuccess={onSuccessEmployeeSearch}
					/>

					<hr></hr>

					<Button block primary className="mt-sm" onClick={() => setStep(1)}>
						{t("FILES.GENERATE.MANUAL")}
					</Button>

					<Button block primary className="mt-sm" onClick={() => setStep(2)}>
						{t("FILES.GENERATE.SKIP")}
					</Button>
				</>
			)}

			{step === 1 && <GenerateFileFormEmployee onSubmit={onSubmitEmployee} onSuccess={onSuccessEmployee} />}
			{step === 2 && <GenerateFileFormSupervisor onSubmit={onSubmitSupervisor} onSuccess={onSuccessSupervisor} />}
		</>
	);
};
