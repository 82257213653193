import cn from "classnames";
import React, { ReactNode } from "react";

import Checkbox from "../Checkbox/Checkbox";
import InfoModal from "../InfoModal/InfoModal";
import { StepRowButtons, StepRowTag } from "./internal/components";
import styles from "./StepRow.module.scss";

interface StepRowProps {
	className?: string;
	onToggle?: () => void;
	isChecked?: boolean;
	title: string;
	description?: string | null;
	children?: ReactNode;
	onClick?: () => void;
}

export const StepRowRoot = ({
	className,
	onToggle,
	isChecked,
	title,
	description,
	children,
	onClick,
}: StepRowProps) => {
	return (
		<div className={cn(styles.StepRow, className)}>
			{!!onToggle && <Checkbox className={styles.Checkbox} onToggle={onToggle} isChecked={isChecked} />}

			<div className={styles.Wrapper}>
				<div className={cn(styles.Title, { [styles["Title--button"]]: !!onClick })} onClick={onClick}>
					{title}
				</div>

				{!!description && (
					<InfoModal title={title}>
						<p>{description}</p>
					</InfoModal>
				)}
			</div>

			{children}
		</div>
	);
};

export const StepRow = Object.assign(StepRowRoot, {
	Tag: StepRowTag,
	Buttons: StepRowButtons,
});
