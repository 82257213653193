import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";

import { Block, Circle, Step } from "@Core/components/UI";
import { useCoreStores } from "@Core/stores";

import { AssignmentList } from "@LNI/components/HOC";
import { Page } from "@LNI/components/Layouts";
import { useLNIStores } from "@LNI/stores";

import styles from "./CoursePage.module.scss";

const CoursePage = () => {
	const { t } = useTranslation();
	const { course } = useParams();
	const history = useHistory();

	const { setConfetti } = useCoreStores();
	const { CourseStore } = useLNIStores();

	const breadcrumbs = [{ to: "/", name: t("COURSES.COURSES") }, { name: CourseStore.course?.title }];

	useEffect(() => {
		window.scrollTo(0, 0);
	});

	// Load on page init
	useEffect(() => {
		(async function load() {
			try {
				if (CourseStore.hasCourses) {
					await CourseStore.getCourse(course);
				}
			} catch (err) {
				history.push(`/`);
			}
		})();
	}, [CourseStore, CourseStore.hasCourses, course, history]);

	// Confetti
	useEffect(() => {
		if (CourseStore.course?.progress.total === CourseStore.course?.progress.done) {
			setConfetti(true);
		}
	}, [CourseStore.course, setConfetti]);

	const getCompleted = () => {
		if (CourseStore.course?.progress.total === CourseStore.course?.progress.done) {
			return (
				<div className="row">
					<div className="col-lg-12">
						<Block name="" className={styles.Completed}>
							<Circle secondary dashed x={85} y={45} r={120} />
							<Circle primary x={5} y={35} r={150} />

							<div className="row justify-content-center">
								<div className="col-lg-6 pt-md pb-md">
									<h2>{t("COURSES.CONGRATS")}</h2>

									<p className="mb-0">{CourseStore.course?.congratulation_text}</p>
								</div>
							</div>
						</Block>
					</div>
				</div>
			);
		}

		return <></>;
	};

	if (CourseStore.course) {
		return (
			<Page breadcrumbs={breadcrumbs}>
				<div className="container">
					{CourseStore.course.assignments && (
						<div className="row">
							<div className="col-lg-12">
								<Block name={CourseStore.course?.title}>
									<div className={styles.Steps}>
										<Step
											step={CourseStore.course?.progress.done}
											steps={CourseStore.course?.progress.total}
										/>
									</div>

									{getCompleted()}

									<div className="row">
										<div className="col-lg-8">
											<div className={styles.Introduction}>
												<p>{CourseStore.course?.introduction_text}</p>
											</div>
										</div>
									</div>

									<AssignmentList assignments={CourseStore.course?.assignments} />
								</Block>
							</div>
						</div>
					)}
				</div>
			</Page>
		);
	}

	return <></>;
};

export default observer(CoursePage);
