import cn from "classnames";
import React, { createElement } from "react";

import { getContentAbbreviation } from "../../utils";
import styles from "./PreprRendererText.module.scss";
import { PreprRendererTextProps } from "./PreprRendererText.props";

export const PreprRendererText = ({ element }: PreprRendererTextProps) => {
	return (
		<div className={cn(styles.PreprRendererText, "row")}>
			<div className="col-xl-3 hidden-lg-down"></div>

			<div className="col-xl-6 col-lg-9 col-md-12">
				{element.format && element.format !== "Code" ? (
					createElement(element.format.toLowerCase(), { className: styles.Heading }, element.body)
				) : (
					<div dangerouslySetInnerHTML={{ __html: getContentAbbreviation(element.body) }} />
				)}
			</div>
		</div>
	);
};
