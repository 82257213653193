import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory, useParams } from "react-router-dom";

import { useCoreStores } from "@Core/stores";

import { Page } from "@PNI/components/Layouts";
import { usePNIStores } from "@PNI/stores";

import FilePageWrapper from "../internal/components/FilePageWrapper";
import { Insight } from "./internal/components/Insight/Insight";
import { MirrorQuestions } from "./internal/components/MirrorQuestions/MirrorQuestions";
import { Resources } from "./internal/components/Resources/Resources";
import { Stressors } from "./internal/components/Stressors/Stressors";

const FilePageInsight = () => {
	const { t } = useTranslation();
	const { file } = useParams<{ file: string }>();
	const history = useHistory();

	const { UserStore } = useCoreStores();
	const { FileStore } = usePNIStores();

	const breadcrumbs = [
		{
			name: t("FILES.PAGE_TITLE", {
				id: FileStore.file?.getId,
			}),
		},
	];

	useEffect(() => {
		(async () => {
			try {
				await FileStore.getFile(file, true);
			} catch (e) {
				history.push("");
			}
		})();
	}, [file, FileStore, history]);

	return (
		<Page breadcrumbs={breadcrumbs}>
			{FileStore.file && UserStore.user && (
				<FilePageWrapper>
					<Insight className="mb-md" />

					<div className="row">
						<div className="col-lg-6 mb-md">
							<Resources />
						</div>

						<div className="col-lg-6 mb-md">
							<Stressors />
						</div>
					</div>

					<MirrorQuestions />
				</FilePageWrapper>
			)}
		</Page>
	);
};

export default observer(FilePageInsight);
