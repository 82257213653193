import React from "react";
import { useTranslation } from "react-i18next/";

import { Footer } from "@Core/components/Layouts";
import { Circle, Logo } from "@Core/components/UI";

import styles from "./Maintenance.module.scss";

const Maintenance = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.Wrapper}>
			<Circle primary x={95} y={15} r={500} />
			<Circle secondary dashed x={180} y={70} r={400} />

			<Circle secondary dashed x={-10} y={25} r={350} />
			<Circle primary x={-30} y={60} r={250} />
			<Circle primary dashed x={0} y={90} r={190} />

			<div className={styles.Block}>
				<div className={styles.BlockInner}>
					<div className={styles.Header}>
						<Logo />
					</div>

					<div className={styles.Body}>
						<div className={styles.BodyHeader}>
							<h1>{t("SHARED.MAINTENANCE.TITLE")}</h1>
							<p>{t("SHARED.MAINTENANCE.DESCRIPTION")}</p>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default Maintenance;
