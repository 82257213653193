import axios, { AxiosInstance, AxiosResponse } from "axios";

import { IPreprRestTag } from "./PreprRestService.props";

class PreprRestServiceImplementation {
	private instance: AxiosInstance;

	constructor() {
		this.instance = axios.create({
			baseURL: process.env.REACT_APP_REST_ENDPOINT,
			headers: {
				Authorization: process.env.REACT_APP_REST_TOKEN,
			},
		});

		this.instance.interceptors.response.use(this.onSuccessInterceptor, this.onErrorInterceptor);
	}

	private onSuccessInterceptor = (value: AxiosResponse) => {
		if (value.data.errors && value.data.errors.length > 0) {
			console.log("%cerror", "color:red", "- ", {
				error: value.data.errors,
				request: value,
			});

			throw new Error(value.data.errors.map((error: any) => error.message));
		}

		return Promise.resolve(value);
	};

	private onErrorInterceptor = (value: AxiosResponse) => {
		return Promise.reject(value);
	};

	public getTagGroup = async (id: string): Promise<IPreprRestTag[]> => {
		const response = await this.instance.get("/tag_groups/" + id, {
			params: {
				fields: "tags",
			},
		});

		if (response.data && response.data.tags && response.data.tags.items) {
			return response.data.tags.items;
		}

		return [];
	};
}

export const PreprRestService = new PreprRestServiceImplementation();
