import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { UserQuestionnaireStepModel } from "./UserQuestionnaireStepModel";

export const UserQuestionnaireProgressModel = types.model("UserQuestionnaireStepModel", {
	id: types.number,
	step: UserQuestionnaireStepModel,
});

export interface UserQuestionnaireProgressInstance extends Instance<typeof UserQuestionnaireProgressModel> {}
export type UserQuestionnaireProgressSnapshot = SnapshotIn<typeof UserQuestionnaireProgressModel>;
