import React from "react";

import styles from "./SeperatedList.module.scss";
import { SeperatedListProps } from "./SeperatedList.props";

export const SeperatedList = ({ list }: SeperatedListProps) => {
	return (
		<div className={styles.SeperatedList}>
			{list.map((resource, idx) => (
				<h5 key={idx} className={styles.Item}>
					{resource}
				</h5>
			))}
		</div>
	);
};
