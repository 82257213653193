import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import cn from "classnames";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { IFieldProps } from "@Core/interfaces";

import { MenuBar } from "./internal/components";
import styles from "./RichText.module.scss";

interface RichTextProps {
	id: string;
	field: IFieldProps;
	placeholder?: string;
	className?: string;
	isDisabled?: boolean;
}

const RichText = ({ id, field, className, placeholder, isDisabled }: RichTextProps) => {
	const { setFieldValue } = useFormikContext();
	const { t } = useTranslation();

	const editor = useEditor({
		extensions: [
			StarterKit,
			Underline,
			Link.configure({
				openOnClick: false,
			}),
			Placeholder.configure({
				placeholder: placeholder ?? t("COMPONENTS.RICH_TEXT.PLACEHOLDER.CONTENT"),
			}),
		],
		editable: false,
		content: field.value,
	});

	useEffect(() => {
		if (editor) {
			editor.on("update", () => {
				setFieldValue(field.name, editor.getHTML());
			});

			editor.setOptions({ editable: !isDisabled });
		}
	}, [isDisabled]);

	return (
		<div id={id} className={cn(styles.RichText, className)}>
			{!isDisabled && (
				<>
					<MenuBar editor={editor} />

					<hr />
				</>
			)}

			{isDisabled && !field.value ? (
				<p className={styles.Fallback}>{placeholder ?? t("COMPONENTS.RICH_TEXT.PLACEHOLDER.CONTENT")}</p>
			) : (
				<EditorContent editor={editor} className={cn(styles.Content, { [styles.isDisabled]: isDisabled })} />
			)}
		</div>
	);
};

export default RichText;
