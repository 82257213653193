import axios from "@Core/axios";
import { AppError } from "@Core/constants/ENUMS";
import { RegisterFormValues, ResendInviteFormValues } from "@Core/forms";
import { CompanySnapshot, UserSnapshot } from "@Core/models";
import BaseService from "@Core/services/BaseService";

export interface ValidateTokenResponse {
	is_valid: boolean;
	reason?: AppError;
	payload?: {
		company?: CompanySnapshot;
		email: string;
	};
}

class RegistrationService extends BaseService<UserSnapshot> {
	validateToken = async (token: string): Promise<ValidateTokenResponse> => {
		const response = await axios.post(`${this.endpoint}/validate/token`, {
			token: token,
		});

		return response.data;
	};

	registerComplete = async (data: RegisterFormValues): Promise<ValidateTokenResponse> => {
		const response = await axios.post(`${this.endpoint}/complete`, data);

		return response.data;
	};

	resendInvite = async (data: ResendInviteFormValues): Promise<void> => {
		await axios.post(`${this.endpoint}/resend`, data);
	};
}

export default new RegistrationService("registration");
