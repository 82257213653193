import React, { useCallback, useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import { Platform } from "@Core/constants/ENUMS";
import NoCompanyPage from "@Core/pages/NoCompany/NoCompanyPage";
import { useCoreStores } from "@Core/stores";

import { getPrivateRoutes as getPrivateLNIRoutes } from "@LNI/routes/PrivateRoutes";
import { getPrivateRoutes as getPrivateOOMRoutes } from "@OOM/routes/PrivateRoutes";
import { getPrivateRoutes as getPrivatePNIRoutes } from "@PNI/routes/PrivateRoutes";

const PrivateRoutes = () => {
	const history = useHistory();
	const { redirect, setRedirect, UserStore, getPlatform } = useCoreStores();

	const permissions = {
		hasLNIPermissions: UserStore.hasLNIPermissions,
		hasAdvisorPermissions: UserStore.hasAdvisorPermissions,
		hasMomentsPermissions: UserStore.hasMomentsPermissions,
		hasAOMPermissions: UserStore.hasAOMPermissions,

		role: UserStore.user?.account_type,
	};

	const getRoutes = useCallback(() => {
		switch (getPlatform) {
			case Platform.LNI:
				return getPrivateLNIRoutes(permissions);
			case Platform.OOM:
				return getPrivateOOMRoutes(permissions);
			case Platform.PNI:
				return getPrivatePNIRoutes(permissions);
		}
	}, [getPlatform, permissions]);

	useEffect(() => {
		if (redirect) {
			history.push(`/${redirect}`);
			setRedirect("");
		}
	}, [redirect, setRedirect, history]);

	return (
		<Switch>
			{UserStore.hasCompany ? getRoutes() : <Route key="NCP" path="/" exact component={NoCompanyPage} />}

			<Redirect to="/" />
		</Switch>
	);
};

export default PrivateRoutes;
