import cn from "classnames";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import { Platform } from "@Core/constants/ENUMS";
import { useCoreStores } from "@Core/stores";

import styles from "./Button.module.scss";

export interface ButtonProps {
	to?: string;
	href?: string;
	iconLeft?: "long-arrow-right" | "long-arrow-left" | "check" | string;
	iconRight?: "long-arrow-right" | "long-arrow-left" | "check" | string;
	disabled?: boolean;
	link?: boolean;
	hover?: boolean;
	block?: boolean;
	small?: boolean;
	dark?: boolean;
	primary?: boolean;
	secondary?: boolean;
	tertiary?: boolean;
	quaternary?: boolean;
	danger?: boolean;
	underline?: boolean;
	icon?: boolean;
	className?: string;
	children: ReactNode;
	onClick?: () => void;
	type?: "button" | "submit" | "reset" | undefined;
}

export const Button = ({
	to,
	href,
	iconLeft,
	iconRight,
	disabled = false,
	icon,
	block,
	small,
	link,
	hover,
	primary,
	secondary,
	tertiary,
	quaternary,
	danger,
	underline,
	className,
	children,
	type = "button",
	onClick,
	...props
}: ButtonProps) => {
	const Core = useCoreStores();

	const classes = cn([className, styles.Button], {
		[styles.Primary]: !link && primary,
		[styles.Icon]: icon,
		[styles.Secondary]: !link && secondary,
		[styles.TertiaryLNI]: !link && tertiary && Core.getPlatform === Platform.LNI,
		[styles.TertiaryAOM]:
			!link && tertiary && (Core.getPlatform === Platform.OOM || Core.getPlatform === Platform.PNI),
		[styles.Quaternary]: !link && quaternary,
		[styles.Danger]: !link && danger,
		[styles.LinkPrimary]: link && primary,
		[styles.LinkSecondary]: link && secondary,
		[styles.LinkTertiaryLNI]: link && tertiary && Core.getPlatform === Platform.LNI,
		[styles.LinkTertiaryAOM]:
			link && tertiary && (Core.getPlatform === Platform.OOM || Core.getPlatform === Platform.PNI),
		[styles.LinkQuaternary]: link && quaternary,
		[styles.LinkDanger]: link && danger,
		[styles.LinkPrimaryHover]: link && primary && hover,
		[styles.LinkSecondaryHover]: link && secondary && hover,
		[styles.LinkTertiaryHover]: link && tertiary && hover,
		[styles.LinkQuaternaryHover]: link && quaternary && hover,
		[styles.LinkDangerHover]: link && danger && hover,
		[styles.Large]: true,
		[styles.Block]: block,
		[styles.IconLeft]: iconLeft,
		[styles.IconRight]: iconRight,
		[styles.Small]: small,
		[styles.Underline]: link && underline,
	});

	const getContent = () => (
		<>
			{iconLeft && <i className={`far ${iconLeft}`} />}
			{children}
			{iconRight && <i className={`far ${iconRight}`} />}
		</>
	);

	if (to && !disabled) {
		return (
			<Link to={to} className={classes} {...props}>
				{getContent()}
			</Link>
		);
	}

	if (href && !disabled) {
		return (
			<a href={href} target="_blank" rel="noreferrer noopener" className={classes} {...props}>
				{getContent()}
			</a>
		);
	}

	return (
		<button
			className={classes}
			type={type}
			disabled={disabled}
			onClick={(e) => {
				onClick && e.stopPropagation();
				onClick && onClick();
			}}
			{...props}
		>
			{getContent()}
		</button>
	);
};
