import axios from "@Core/axios";
import { ArticleInstance, ArticleSnapshot, TagSnapshot } from "@Core/models";
import { Prepr } from "@Core/utilities";

import BaseService from "./BaseService";

export enum TAG_GROUP {
	THEMES = "THEMES",
	SUBJECTS = "SUBJECTS",
}

class ArticleService extends BaseService<void> {
	getArticles = async (): Promise<ArticleSnapshot[] | null> => {
		const response = await axios.get(`${this.endpoint}`);

		let articles = [];
		if (response?.data?.articles?.length > 0) {
			articles = response.data.articles.map((article: any) => Prepr.format(article));
		}

		let articles_recommended = [];
		if (response?.data?.recommended_articles?.length > 0) {
			articles_recommended = response.data.recommended_articles.map((article_recommendation: any) => {
				return Prepr.format(article_recommendation.article, article_recommendation);
			});
		}

		return articles_recommended.concat(articles);
	};

	searchArticles = async (values: any): Promise<ArticleInstance[] | null> => {
		const data = {
			searchPhrase: values.searchPhrase || "",
			tags: [...(values.tags || [])],
		};

		if (values.theme) {
			data.tags.push(values.theme);
		}

		const response = await axios.post(`${this.endpoint}/search`, data);

		if (response?.data?.items?.length > 0) {
			const formatted = response.data.items.map((article: any) => Prepr.format(article));
			return formatted;
		}

		return null;
	};

	recommendArticle = async (articleId: string, fileId: number, message?: string): Promise<any | null> => {
		const data = {
			prepr_article_id: articleId,
			aom_file_id: fileId,
			message: message,
		};

		await axios.post(`${this.endpoint}/recommend`, data);
	};

	getTags = async (): Promise<TagSnapshot[] | null> => {
		const response = await axios.get(`${this.endpoint}/tags`);

		if (response?.data?.tags?.length > 0) {
			return response.data.tags;
		}

		return null;
	};

	getTagGroup = async (group: TAG_GROUP = TAG_GROUP.SUBJECTS): Promise<TagSnapshot[] | null> => {
		const response = await axios.get(`${this.endpoint}/tag_groups`, {
			params: {
				type: group,
			},
		});

		if (response.data.length > 0) {
			return response.data;
		}

		return null;
	};

	setTags = async (ids: string[]): Promise<void> => {
		await axios.post(`${this.endpoint}/tags`, { ids: ids.join(",") });
	};
}

export default new ArticleService("articles");
