import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Profile, Segment } from "./internal/components";
import styles from "./RiskProfileMatrix.module.scss";
import { RiskProfileMatrixProps } from "./RiskProfileMatrix.props";

export const RiskProfileMatrix = ({ domains, characteristics, className }: RiskProfileMatrixProps) => {
	const { t } = useTranslation();

	const values = domains || {
		health: { score: 5, questions: [] },
		work: { score: 5, questions: [] },
		motivation: { score: 5, questions: [] },
		private: { score: 5, questions: [] },
	};

	return (
		<div className={cn(styles.RiskProfileMatrix, className)}>
			{Object.entries(values).map(([key, group]) => {
				return <Segment key={key} title={t(`FILES.DOMAINS.${key.toUpperCase()}`)} {...group} />;
			})}

			<div className={styles.RiskProfileMatrix__Center}>
				<Profile characteristics={characteristics} />
			</div>

			<div className={styles.RiskProfileMatrix__LineX} />
			<div className={styles.RiskProfileMatrix__LineY} />
		</div>
	);
};
