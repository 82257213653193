import { flow, Instance, types } from "mobx-state-tree";

import { AccountType } from "@Core/constants/ENUMS";
import { CompanyInstance, CompanyModel, UserModel } from "@Core/models";
import { UserService } from "@Core/services";

const UserStore = types
	.model({
		user: types.maybe(UserModel),
		company: types.maybe(types.reference(CompanyModel)),

		hasCompany: false,
		isFirstLogin: false,
		isAuthenticated: false,
	})
	.actions((self) => ({
		load: flow(function* () {
			const response = yield UserService.me();

			if (response) {
				self.isAuthenticated = true;
				self.user = response;

				if (self.user) {
					const item = parseInt("" + localStorage.getItem("REACT_APP_COMPANY"));
					const foundById = self.user.companies.find((company: CompanyInstance) => company.id === item);

					if (foundById) {
						self.company = foundById;
						self.hasCompany = true;
					} else if (self.user?.companies.length > 0) {
						self.company = self.user?.companies[0];
						self.hasCompany = true;
					}
				}
			}
		}),

		update(values: any) {
			if (self.user) {
				self.user.first_name = values.first_name;
				self.user.last_name = values.last_name;
				self.user.phone_number = values.phone_number;
				self.user.bio = values.bio;
			}
		},

		login(firstLogin: boolean) {
			self.isFirstLogin = firstLogin;
			self.isAuthenticated = true;
		},

		logout() {
			self.isAuthenticated = false;
		},

		setCompany(companyId: number | string) {
			self.company = self.user?.companies.find(
				(company: CompanyInstance) => company.id === parseInt("" + companyId),
			) as CompanyInstance;

			localStorage.setItem("REACT_APP_COMPANY", "" + companyId);
		},

		hasFirstLogin() {
			self.isFirstLogin = false;
		},
	}))
	.views((self) => ({
		get getName() {
			return `${self.user?.first_name} ${self.user?.last_name}`;
		},

		get getCompanyId() {
			return self.company?.id || 0;
		},

		get hasLNIPermissions() {
			return self.user?.companies[0]?.permissions?.lni || false;
		},

		get hasAdvisorPermissions() {
			return self.user?.companies[0]?.permissions?.lni_settings?.advisor_enabled || false;
		},

		get isAccountManager() {
			return self.user?.account_type === AccountType.AccountManager;
		},

		get isHR() {
			return self.user?.account_type === AccountType.HR;
		},

		get hasMomentsPermissions() {
			return self.user?.companies[0]?.permissions?.lni_settings?.moments_enabled || false;
		},

		get hasAOMPermissions() {
			return self.user?.companies[0]?.permissions?.aom || false;
		},

		get hasFilesPermissions() {
			if (self.user && self.company?.permissions?.aom) {
				return [AccountType.HR, AccountType.CompanyDoctor, AccountType.Pob].includes(self.user?.account_type);
			}

			return false;
		},

		get hasOmissionExportPermissions() {
			if (self.user) {
				return [AccountType.AccountManager].includes(self.user?.account_type);
			}

			return false;
		},

		get hasOmissionPermissions() {
			if (self.user && self.company?.permissions?.pni && self.company?.permissions?.pni_settings?.vos_enabled) {
				return [AccountType.CompanyDoctor, AccountType.Pob].includes(self.user?.account_type);
			}

			return false;
		},

		get hasArticlesPermissions() {
			if (self.user) {
				return [AccountType.CompanyDoctor, AccountType.HR, AccountType.Pob].includes(self.user?.account_type);
			}

			return false;
		},

		get hasInboxPermissions() {
			if (self.user) {
				return [
					AccountType.CompanyDoctor,
					AccountType.HR,
					AccountType.Pob,
					AccountType.AccountManager,
				].includes(self.user?.account_type);
			}

			return false;
		},

		get hasContactsPermissions() {
			if (self.user && self.company?.contacts && self.company?.contacts.length > 0) {
				return [
					AccountType.CompanyDoctor,
					AccountType.HR,
					AccountType.Pob,
					AccountType.AccountManager,
				].includes(self.user?.account_type);
			}

			return false;
		},
	}));

export interface UserStoreInstance extends Instance<typeof UserStore> {}

export default UserStore;
