import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { UserQuestionnaireStatus } from "@Core/constants/ENUMS";

import { BaseModel } from "../BaseModel";
import { QuestionnaireModel } from "../QuestionnaireModel";
import { UserQuestionnaireModel } from "../UserQuestionnaire/UserQuestionnaireModel";

export const FileQuestionnairesModel = BaseModel.props({
	user_questionnaire_id: types.maybeNull(types.number),
	aom_file_id: types.number,
	mail_id: types.maybeNull(types.string),
	mail_received: types.boolean,
	status: types.maybeNull(types.enumeration<UserQuestionnaireStatus>(Object.values(UserQuestionnaireStatus))),
	questionnaire: types.maybeNull(QuestionnaireModel),
	user_questionnaire: types.maybeNull(UserQuestionnaireModel),
}).views((self) => ({
	get isCompleted(): boolean {
		return self.status === UserQuestionnaireStatus.Completed;
	},
}));

export interface FileQuestionnairesInstance extends Instance<typeof FileQuestionnairesModel> {}
export type FileQuestionnairesSnapshot = SnapshotIn<typeof FileQuestionnairesModel>;
