import { Instance, SnapshotIn, types } from "mobx-state-tree";

export const UserQuestionnaireAnswerModel = types.model("UserQuestionnaireAnswerModel", {
	id: types.union(types.string, types.number),
	text: types.string,
	open_input: false,
	selected: false,
	group: types.maybeNull(types.string),
	user_answer: types.maybeNull(types.string),
});

export interface UserQuestionnaireAnswerInstance extends Instance<typeof UserQuestionnaireAnswerModel> {}
export type UserQuestionnaireAnswerSnapshot = SnapshotIn<typeof UserQuestionnaireAnswerModel>;
