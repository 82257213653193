import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next/";

import { Block, Button, CardRow } from "@Core/components/UI";

import { ChallengeInstance } from "@LNI/models";

import styles from "./ChallengeList.module.scss";

type Props = {
	challenges: ChallengeInstance[];
};

const ChallengeList = ({ challenges }: Props) => {
	const { t } = useTranslation();
	const getChallenges = () => {
		return (
			<>
				{challenges.map((challenge: ChallengeInstance, key) => (
					<CardRow
						small
						key={key}
						icon={challenge.icon}
						type={t("CHALLENGES.CHALLENGE")}
						title={challenge.title}
						step={{ done: challenge.progress.done, total: challenge.progress.total }}
						to={`/uitdagingen/${challenge.id}`}
						finished={challenge.progress.total === challenge.progress.done}
					/>
				))}

				<Button primary to="/mijn-profiel" className="mt-sm">
					{t("CHALLENGES.CHANGE_CHALLENGE")}
				</Button>
			</>
		);
	};

	const getPlaceholder = () => {
		return (
			<>
				<div className={styles.Content}>
					<div className={styles.Challenges}>
						{Array.from(Array(4), (e, i) => {
							return (
								<CardRow
									small
									disabled
									key={i}
									icon="fa-question"
									type="Uitdaging"
									title="Lorem ipsum dolor sit amet"
									step={{ done: Math.floor(Math.random() * Math.floor(4)), total: 4 }}
								/>
							);
						})}
					</div>

					<div className={styles.Complete}>
						<div className={styles.CompleteContent}>
							<h4>{t("CHALLENGES.DISCOVER")}</h4>

							<p>{t("CHALLENGES.DISCOVER_DESCRIPTION")}</p>

							<Button primary to="/uitdagingen">
								{t("CHALLENGES.GO_TO_PROFILE")}
							</Button>
						</div>
					</div>
				</div>
			</>
		);
	};

	return (
		<Block name={t("CHALLENGES.MY_CHALLENGES")}>{challenges.length > 0 ? getChallenges() : getPlaceholder()}</Block>
	);
};

export default observer(ChallengeList);
