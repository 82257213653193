import { useEffect } from "react";
import { mst } from "reactotron-mst";
import Reactotron from "reactotron-react-js";

export const useReactron = (): void => {
	useEffect(() => {
		if (process.env.NODE_ENV === "development") {
			Reactotron.configure().use(mst()).connect();
			console.log("Reactotron Configured");
		}
	}, []);
};

export default useReactron;
