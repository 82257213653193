import cn from "classnames";
import React from "react";
import ReactPlayer from "react-player";

import { Button, Icon } from "@Core/components/UI";

import styles from "./Popup.module.scss";

type Props = {
	title: string;
	video: string;
	visible: boolean;
	onClose?: () => void;
};

const Popup = ({ title, video, visible, onClose }: Props) => {
	return (
		<div
			className={cn([styles.Popup], {
				[styles.PopupVisible]: visible,
			})}
		>
			<div className={styles.Body}>
				<div className={styles.Header}>
					<Icon icon="far fa-video" />

					<h5>{title}</h5>

					<Button onClick={onClose} className={styles.Close}>
						<i className="far fa-times"></i>
					</Button>
				</div>
				<div className={styles.Content}>
					<ReactPlayer
						className="react-player"
						url={video}
						width="100%"
						height="100%"
						playing={visible}
						controls={false}
					/>
				</div>
			</div>

			<div className={styles.Background} onClick={onClose}></div>
		</div>
	);
};

export default Popup;
