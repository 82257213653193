import cn from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next/";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { Modal } from "@Core/components/Layouts";
import { Button, StackList } from "@Core/components/UI";
import { useDialog } from "@Core/hooks";
import { useCoreStores } from "@Core/stores";

import { GenerateFileFormEmployee, GenerateFileFormEmployeeSearch } from "@PNI/forms";
import { usePNIStores } from "@PNI/stores";

import styles from "./FilePageHeader.module.scss";

interface FilePageHeaderProps {
	title?: string;
	description?: string | null;
	close?: boolean;
}

interface CloseFileModalProps {
	isVisible: boolean;
	toggle: () => void;
}

const CloseFileModal = ({ isVisible, toggle }: CloseFileModalProps) => {
	const { t } = useTranslation();
	const { UserStore } = useCoreStores();
	const { FileStore } = usePNIStores();

	const handleSubmit = async () => {
		try {
			await FileStore.file?.close();
			await Promise.all([UserStore.load(), FileStore.load()]);

			toggle();

			toast.success(t("FILES.MODALS.CLOSE_FILE.SUCCESS"));
		} catch (e) {
			toast.error(t("FILES.MODALS.CLOSE_FILE.ERROR"));
		}
	};

	return (
		<Modal title={t("FILES.MODALS.CLOSE_FILE.POPUP_TITLE")} isVisible={isVisible} hide={toggle}>
			<p>{t("FILES.MODALS.CLOSE_FILE.POPUP_TEXT")}</p>

			<Button primary onClick={handleSubmit}>
				{t("FILES.MODALS.CLOSE_FILE.POPUP_BUTTON")}
			</Button>
		</Modal>
	);
};

interface CloseOmissionModalProps {
	isVisible: boolean;
	toggle: () => void;
}

const CloseOmissionModal = ({ isVisible, toggle }: CloseOmissionModalProps) => {
	const { t } = useTranslation();
	const { UserStore } = useCoreStores();
	const { FileStore } = usePNIStores();

	const handleSubmit = async () => {
		try {
			await FileStore.file?.close();
			await Promise.all([UserStore.load(), FileStore.load()]);

			toggle();

			toast.success(t("FILES.MODALS.CLOSE_OMISSION.SUCCESS"));
		} catch (e) {
			toast.error(t("FILES.MODALS.CLOSE_OMISSION.ERROR"));
		}
	};

	return (
		<Modal title={t("FILES.MODALS.CLOSE_OMISSION.POPUP_TITLE")} isVisible={isVisible} hide={toggle}>
			<p>{t("FILES.MODALS.CLOSE_OMISSION.POPUP_TEXT")}</p>

			<Button primary onClick={handleSubmit}>
				{t("FILES.MODALS.CLOSE_OMISSION.POPUP_BUTTON")}
			</Button>
		</Modal>
	);
};

interface EmployeeModalProps {
	isVisible: boolean;
	toggle: () => void;
}

const EmployeeModal = ({ isVisible, toggle }: EmployeeModalProps) => {
	const { t } = useTranslation();
	const { UserStore } = useCoreStores();
	const { FileStore } = usePNIStores();
	const [manual, setManual] = useState(false);

	if (FileStore.file && FileStore.file.employee) {
		const handleSubmit = async (values: any) => {
			try {
				await FileStore.updateEmployee(values);
				await Promise.all([UserStore.load(), FileStore.load()]);

				toggle();

				toast.success(t("FILES.MODALS.EDIT_EMPLOYEE.SUCCESS"));
			} catch (e) {
				toast.error(t("FILES.MODALS.EDIT_EMPLOYEE.ERROR"));
			}
		};

		return (
			<Modal title={t("FILES.MODALS.EDIT_EMPLOYEE.POPUP_TITLE")} isVisible={isVisible} hide={toggle}>
				<GenerateFileFormEmployee
					submit={t("FILES.MODALS.EDIT_EMPLOYEE.SUBMIT")}
					initialValues={FileStore.file.employee}
					onSubmit={handleSubmit}
				/>
			</Modal>
		);
	} else {
		const handleSubmit = async (values: any) => {
			try {
				await FileStore.connectEmployee(values.employee_id ? values : { employee: values });
				await Promise.all([UserStore.load(), FileStore.load()]);

				toggle();

				toast.success(t("FILES.MODALS.ADD_EMPLOYEE.SUCCESS"));
			} catch (e) {
				toast.error(t("FILES.MODALS.ADD_EMPLOYEE.ERROR"));
			}
		};

		return (
			<Modal title={t("FILES.MODALS.ADD_EMPLOYEE.POPUP_TITLE")} isVisible={isVisible} hide={toggle}>
				{!manual ? (
					<>
						<GenerateFileFormEmployeeSearch
							submit={t("FILES.MODALS.EDIT_EMPLOYEE.SUBMIT")}
							onSubmit={handleSubmit}
						/>

						<Button block primary className="mt-sm" onClick={() => setManual(true)}>
							{t("FILES.GENERATE.MANUAL")}
						</Button>
					</>
				) : (
					<GenerateFileFormEmployee submit={t("FILES.MODALS.EDIT_EMPLOYEE.SUBMIT")} onSubmit={handleSubmit} />
				)}
			</Modal>
		);
	}
};

const FilePageHeader = ({ title, description, close }: FilePageHeaderProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const dialogCloseFile = useDialog();
	const dialogCloseOmission = useDialog();
	const dialogEmployee = useDialog();
	const { FileStore } = usePNIStores();

	if (FileStore.file) {
		return (
			<div className={cn(styles.FilePageHeader, "container")}>
				<div>
					<h2 className={styles.FilePageHeader__Title}>
						<small>{t("FILES.HEADER.FILE_NUMBER")}</small>#{FileStore.file.getId}
					</h2>

					{(!!title || !!description) && (
						<div className={styles.FilePageHeader__Content}>
							{!!title && <strong className="d-block">{title}</strong>}
							{!!description && <p className="mb-0" dangerouslySetInnerHTML={{ __html: description }} />}
						</div>
					)}

					<StackList style={{ float: "left" }}>
						<StackList.Item title={t("FILES.HEADER.EMPLOYEE")} icon="user" onClick={dialogEmployee.toggle}>
							{FileStore.file.employee?.getName || t("FILES.MODALS.ADD_EMPLOYEE.BUTTON")}
						</StackList.Item>

						<StackList.Item title={t("FILES.HEADER.SUPERVISOR")} icon="user">
							{FileStore.file.supervisor.getName}
						</StackList.Item>

						<StackList.Item title={t("FILES.HEADER.DURATION")} icon="user">
							{t("SHARED.DAYS", { count: FileStore.file.duration })}
						</StackList.Item>
					</StackList>
				</div>

				{close && (
					<div className={styles.FilePageHeader__Buttons}>
						<div className="d-flex flex-wrap">
							<Button
								disabled={FileStore.file.isOmissionClosed}
								danger
								link
								underline
								onClick={dialogCloseOmission.toggle}
							>
								{t(
									`FILES.HEADER.BUTTONS.${
										FileStore.file.isOmissionClosed ? "OMISSION_DONE" : "OMISSION"
									}`,
								)}
							</Button>

							<Button
								disabled={FileStore.file.isClosed}
								iconLeft="fa-times"
								danger
								onClick={dialogCloseFile.toggle}
							>
								{t(`FILES.HEADER.BUTTONS.${FileStore.file.isClosed ? "CLOSE_DONE" : "CLOSE"}`)}
							</Button>
						</div>

						<Button
							disabled={FileStore.file.isClosed}
							iconLeft={FileStore.file?.vos?.isCompleted ? "fa-eye" : "fa-plus"}
							tertiary
							onClick={async () => {
								const id = await FileStore.getOmission(FileStore.file?.id || 0);
								history.push(
									`/dossiers/${FileStore.file?.id}/vragenlijst/${id || FileStore.file?.vos?.id}`,
								);
							}}
						>
							{FileStore.file?.vos?.isCompleted
								? t("FILES.HEADER.BUTTONS.VOS_DONE")
								: t("FILES.HEADER.BUTTONS.VOS")}
						</Button>

						{FileStore.file.isClosed === false && <CloseFileModal {...dialogCloseFile} />}
						{FileStore.file.isOmissionClosed === false && <CloseOmissionModal {...dialogCloseOmission} />}
					</div>
				)}

				<EmployeeModal {...dialogEmployee} />
			</div>
		);
	}

	return <></>;
};

export default observer(FilePageHeader);
