import { Instance, SnapshotIn, types } from "mobx-state-tree";

import { ActivationElementModel } from "./ActivationElementModel";

export const ActivationModel = types.model({
	title: types.maybeNull(types.string),
	intro: types.maybeNull(types.string),
	tips: types.maybeNull(ActivationElementModel),
	steps: types.maybeNull(ActivationElementModel),
});

export interface ActivationInstance extends Instance<typeof ActivationModel> {}
export type ActivationSnapshot = SnapshotIn<typeof ActivationModel>;
