import React from "react";
import { Route } from "react-router-dom";

import {
	ChallengeAssignmentPage,
	ChallengePage,
	CourseAssignmentPage,
	CoursePage,
	DashboardPage,
	FilePage,
	FilesPage,
	IntakePage,
	MomentPage,
	ProfilePage,
} from "@LNI/pages";

export const getPrivateRoutes = (permissions: any) => {
	const routes = [
		<Route key="DP" path="/" exact component={DashboardPage} />,

		<Route key="IP" path="/uitdagingen" exact component={IntakePage} />,
		<Route key="CP" path="/uitdagingen/:challenge" exact component={ChallengePage} />,
		<Route key="CAP" path="/uitdagingen/:challenge/:assignment" exact component={ChallengeAssignmentPage} />,

		<Route key="PP" path="/mijn-profiel" exact component={ProfilePage} />,
	];

	if (permissions.hasLNIPermissions) {
		routes.push(<Route key="CP" path="/cursussen/:course" exact component={CoursePage} />);
		routes.push(<Route key="CAP" path="/cursussen/:course/:assignment" exact component={CourseAssignmentPage} />);
	}

	if (permissions.hasMomentsPermissions) {
		routes.push(<Route key="MPM" path="/momenten/:moment" exact component={MomentPage} />);
	}

	if (permissions.hasAOMPermissions) {
		routes.push(<Route key="DP" path="/dossiers/" exact component={FilesPage} />);
		routes.push(<Route key="DIP" path="/dossiers/:file/:questionnaire" exact component={FilePage} />);
	}

	return routes;
};
