import cn from "classnames";
import React, { useState } from "react";

import { IFieldProps } from "@Core/interfaces";

import styles from "./Password.module.scss";

type Props = {
	id: string;
	name: string;
	field: IFieldProps;

	placeholder?: string;
};

const Password = ({ id, name, field, placeholder }: Props) => {
	const [show, setShow] = useState(false);

	const toggleShow = () => {
		setShow(!show);
	};

	return (
		<div className={styles.Field}>
			<input
				id={id}
				className={styles.Input}
				type={show ? "text" : "password"}
				{...field}
				autoComplete="new-password"
				placeholder={placeholder}
			/>

			<button type="button" className={styles.Icon} onClick={toggleShow}>
				<i
					className={cn(["far"], {
						"fa-lock": !show,
						"fa-lock-open": show,
					})}
				></i>
			</button>
		</div>
	);
};

export default Password;
