import { useState } from "react";

export const useDialog = () => {
	const [isVisible, setIsVisible] = useState(false);

	function toggle() {
		setIsVisible((old) => !old);
	}

	function visible(value: boolean) {
		setIsVisible(value);
	}

	return {
		visible,
		isVisible,
		toggle,
	};
};

export default useDialog;
