export const getContentAbbreviation = (content: string) => {
	const shortcodeRegex = /\[info([^\]]*)\]([\s\S]*?)\[\/info\]/g;
	const shortcodeNameRegex = /name=\"([\s\S]*?)\"/g;

	return content?.replace(shortcodeRegex, (match: any, index: any, body: any) => {
		if (index.match(shortcodeNameRegex)) {
			const title = shortcodeNameRegex.exec(index);

			if (title && title[1]) {
				return `
                <abbr>
                    <span>
                        ${title[1]}
                        <span>i</span>
                    </span>

                    <span>
                        <span>
                            <span>i</span>
                            ${title[1].charAt(0).toUpperCase() + title[1].slice(1)}
                        </span>

                        <span>${body}</span>
                    </span>
                </abbr>
            `;
			}

			return match;
		}
	});
};
