import { flow, Instance, types } from "mobx-state-tree";

import { CoreRootStore } from "@Core/stores";

import { MessageModel } from "@PNI/models";
import { InboxService } from "@PNI/services";

const InboxStore = types
	.model({
		messages: types.array(MessageModel),
		isLoading: false,
		isFinished: false,
	})
	.actions((self) => {
		const actions: { [key: string]: any } = {
			load: flow(function* () {
				self.messages.replace([]);

				const companyId = CoreRootStore.UserStore.getCompanyId;

				if (companyId) {
					const response = yield InboxService.getMessages();

					if (response && response.length > 0) {
						self.messages = response;
					}

					self.isFinished = true;
				}
			}),
		};

		return actions;
	});

export interface InboxStoreInstance extends Instance<typeof InboxStore> {}

export default InboxStore;
