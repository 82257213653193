import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { Block, Circle, Share, Tag } from "@Core/components/UI";

import styles from "./ArticlesDetailHeader.module.scss";
import { ArticlesDetailHeaderProps } from "./ArticlesDetailHeader.props";

export const ArticlesDetailHeader = ({ article, className }: ArticlesDetailHeaderProps) => {
	const { t } = useTranslation();

	return (
		<div className={cn(styles.ArticlesDetailHeader, className)}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<Block primaryLight className={styles.Block}>
							<Circle primary x={2} y={60} r={160} />
							<Circle tertiary dark opacity={0.15} dashed x={100} y={25} r={105} />

							<div className="row">
								<div className="col-xl-3 col-lg-1" />

								<div className="col-xl-9 col-lg-11 col-md-12">
									<h1 className={styles.Title}>{article.title}</h1>
									<p className={cn(styles.Intro, "intro")}>{article.intro}</p>

									<div className={styles.Footer}>
										<div className={styles.Time}>
											<i className="far fa-clock" />

											{t("ARTICLES.ARTICLE.HEADER.TIME", {
												count: article._read_time,
											})}
										</div>

										<div className={styles.Tags}>
											{article?.keywords_pni.map((keyword) => (
												<Tag key={keyword._id} name={keyword.body} />
											))}
										</div>

										<div className={styles.Share}>
											<Share title={article.title} intro={article.intro} />
										</div>
									</div>
								</div>
							</div>
						</Block>
					</div>
				</div>
			</div>
		</div>
	);
};
