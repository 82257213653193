import { observer } from "mobx-react-lite";
import React, { ReactElement, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next/";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import image_deprecated from "@Core/assets/images/illustrations/deprecated.jpg";
import image_question from "@Core/assets/images/illustrations/question.jpg";
import image_question_exclamation from "@Core/assets/images/illustrations/question_exclamation.jpg";
import image_security from "@Core/assets/images/illustrations/security.jpg";
import { Advisor, Questionnaire, QuestionnaireCompleted, Video } from "@Core/components/Components";
import Form from "@Core/components/Form";
import { Footer, Modal } from "@Core/components/Layouts";
import { Block, Button, Step } from "@Core/components/UI";
import { useDialog } from "@Core/hooks";
import { QuestionnaireTokenService } from "@Core/services";
import { TOKEN_STATUS } from "@Core/services/QuestionnaireTokenService";
import { parseHTML } from "@Core/utilities";
import Yup from "@Core/validation";

import { Header } from "@PNI/components/Layouts";
import { EmployeeService } from "@PNI/services";
import { usePNIStores } from "@PNI/stores";

import styles from "./EmployeePage.module.scss";

interface EmployeePageIntroductionStepInterface {
	title: string;
	description: string | ReactElement;
	videoUrl?: string;
	imageUri?: string;
}

type EmployeePageIntroductionStep = {
	title: string;
	description: string | ReactElement;
	imageUri?: string;
	videoUrl?: string;
	children?: any;
};

const EmployeePageIntroductionStep = ({
	title,
	description,
	imageUri,
	videoUrl,
	children,
}: EmployeePageIntroductionStep) => {
	return (
		<div className={styles.Step}>
			{videoUrl && <Video url={videoUrl} />}

			{imageUri && (
				<div className={styles.Image}>
					<LazyLoad>
						<img src={imageUri} alt={title} />
					</LazyLoad>
				</div>
			)}

			<h3>{title}</h3>
			{typeof description === "string" ? <p dangerouslySetInnerHTML={{ __html: description }}></p> : description}

			{children && children}
		</div>
	);
};

const EmployeePageIntroduction = observer(() => {
	const { EmployeeStore } = usePNIStores();

	const { t } = useTranslation();
	const [step, setStep] = useState(0);
	const history = useHistory();

	const [steps, setSteps] = useState<EmployeePageIntroductionStepInterface[]>([]);
	const [status, setStatus] = useState<TOKEN_STATUS>();
	const [token, setToken] = useState("");
	const [linkRequested, setLinkRequested] = useState(false);

	const validationSchema = Yup.object().shape({
		birthdate: Yup.date().required(),
		zipcode: Yup.string().zipcode().required(),
	});

	// Check if token is valid, if so store token to the store
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const currentToken = params.get("token");

		if (currentToken) {
			(async () => {
				await QuestionnaireTokenService.validateToken(currentToken)
					.then((response: any) => {
						setStatus(response.status);

						// Set token when response status is valid
						if (response.status === TOKEN_STATUS.TOKEN_STATUS_VALID) {
							setToken(currentToken);
						}
					})
					.catch(() => {
						history.push("/");
					});
			})();
		}
	}, [history]);

	// Set steps if there is a valid token within the store
	useEffect(() => {
		const a: EmployeePageIntroductionStepInterface[] = [];

		a.push({
			imageUri: image_question,
			title: t("EMPLOYEE.INTRODUCTION.STEP_1.TITLE"),
			description: t("EMPLOYEE.INTRODUCTION.STEP_1.DESCRIPTION"),
		});

		a.push({
			imageUri: image_question_exclamation,
			title: t("EMPLOYEE.INTRODUCTION.STEP_2.TITLE"),
			description: t("EMPLOYEE.INTRODUCTION.STEP_2.DESCRIPTION"),
		});

		a.push({
			// videoUrl: "https://www.youtube.com/watch?v=ysz5S6PUM-U",
			imageUri: image_question,
			title: t("EMPLOYEE.INTRODUCTION.STEP_3.TITLE"),
			description: t("EMPLOYEE.INTRODUCTION.STEP_3.DESCRIPTION"),
		});

		a.push({
			imageUri: image_security,
			title: t("EMPLOYEE.INTRODUCTION.STEP_4.TITLE"),
			description: t("EMPLOYEE.INTRODUCTION.STEP_4.DESCRIPTION"),
		});

		a.push({
			imageUri: image_deprecated,
			title: t("EMPLOYEE.INTRODUCTION.STEP_5.TITLE"),
			description: (
				<Trans
					i18nKey="EMPLOYEE.INTRODUCTION.STEP_5.DESCRIPTION"
					components={{ button: <a onClick={console.log} /> }}
				/>
			),
		});

		setSteps(a);
	}, [EmployeeStore.token, t]);

	// Check if token, birthdate and zipcode is a valid combination, if so store the returned x-token in the store
	const onSubmit = async (values: any) => {
		if (status === TOKEN_STATUS.TOKEN_STATUS_VALID) {
			await EmployeeStore.login(token, values.zipcode, values.birthdate);
		}
	};

	// If succefully stored x-token load questionnaire and the introduction steps
	const onSuccess = async () => {
		nextStep();
	};

	// Request new link if the token is expired
	const onLinkRequest = async () => {
		const params = new URLSearchParams(window.location.search);
		const currentToken = params.get("token");

		if (currentToken) {
			try {
				setLinkRequested(true);
				toast.success(t("EMPLOYEE.INTRODUCTION.DEPRATECTED.REQUESTED"));

				await EmployeeService.resend(currentToken);
			} catch (e) {
				setLinkRequested(false);
			}
		}
	};

	const previousStep = () => {
		const old = step;
		setStep(old - 1);
	};

	const nextStep = async () => {
		const old = step;
		setStep(old + 1);

		if (step === steps.length - 1) {
			await EmployeeStore.load();
		}
	};

	const getStep = (step: number) => {
		if (steps[step]) {
			return (
				<>
					<EmployeePageIntroductionStep
						title={steps[step].title}
						description={steps[step].description}
						imageUri={steps[step].imageUri ? steps[step].imageUri : undefined}
						videoUrl={steps[step].videoUrl ? steps[step].videoUrl : undefined}
					>
						{step === 0 && (
							<div className={styles.Form}>
								<Form.Form
									identifier="CHALLENGES_FORM"
									validationSchema={validationSchema}
									onSubmit={onSubmit}
									onSuccess={onSuccess}
									submit={t("EMPLOYEE.INTRODUCTION.SUBMIT")}
								>
									<Form.Item
										id="birthdate"
										render={(props) => (
											<Form.Date
												maxDate={new Date()}
												placeholder={t("EMPLOYEE.INTRODUCTION.BIRTHDATE")}
												{...props}
											/>
										)}
									/>

									<Form.Item
										id="zipcode"
										render={(props) => (
											<Form.Text
												icon="home"
												placeholder={t("EMPLOYEE.INTRODUCTION.ZIPCODE")}
												{...props}
											/>
										)}
									/>
								</Form.Form>
							</div>
						)}
					</EmployeePageIntroductionStep>

					{step !== 0 && (
						<div className={styles.Navigation}>
							<Button
								primary
								link
								disabled={step <= 1}
								hover
								iconLeft="fa-long-arrow-left"
								onClick={() => previousStep()}
							>
								{t("SHARED.PREVIOUS")}
							</Button>

							<Button
								primary
								link={step !== steps.length - 1}
								hover
								iconRight="fa-long-arrow-right"
								onClick={() => nextStep()}
							>
								{step !== steps.length - 1 && <span>{t("SHARED.NEXT")}</span>}
								{step === steps.length - 1 && <span>{t("EMPLOYEE.INTRODUCTION.PROCEED")}</span>}
							</Button>
						</div>
					)}
				</>
			);
		}
	};

	const getDeprecated = () => {
		return (
			<EmployeePageIntroductionStep
				title={t("EMPLOYEE.INTRODUCTION.DEPRATECTED.TITLE")}
				description={t("EMPLOYEE.INTRODUCTION.DEPRATECTED.DESCRIPTION")}
				imageUri={image_deprecated}
			>
				<Button primary onClick={onLinkRequest} disabled={linkRequested}>
					{t("EMPLOYEE.INTRODUCTION.DEPRATECTED.BUTTON")}
				</Button>
			</EmployeePageIntroductionStep>
		);
	};

	const getAlreadyCompleted = () => {
		return (
			<EmployeePageIntroductionStep
				title={t("EMPLOYEE.INTRODUCTION.COMPLETED.TITLE")}
				description={t("EMPLOYEE.INTRODUCTION.COMPLETED.DESCRIPTION")}
				imageUri={image_question_exclamation}
			/>
		);
	};

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-lg-8">
					<Block name={t("EMPLOYEE.INTRODUCTION.TITLE")} overflow center className={styles.Introduction}>
						{status === TOKEN_STATUS.TOKEN_STATUS_EXPIRED && getDeprecated()}
						{status === TOKEN_STATUS.TOKEN_STATUS_PROCESSED && getAlreadyCompleted()}
						{status === TOKEN_STATUS.TOKEN_STATUS_VALID && (
							<>
								<Step className={styles.Progress} step={step + 1} steps={steps.length} dots hide />
								{getStep(step)}
							</>
						)}
					</Block>
				</div>
			</div>
		</div>
	);
});

const EmployeePageQuestionnaire = observer(() => {
	const { t } = useTranslation();
	const { EmployeeStore } = usePNIStores();
	const [isCompleted, setIsCompleted] = useState(false);
	const { isVisible, toggle } = useDialog();

	const onCompletion = () => {
		setIsCompleted(true);
		toast.success(t("EMPLOYEE.FILE.SUCCESS"));

		QuestionnaireTokenService.logout();
	};

	if (EmployeeStore.user) {
		return (
			<div className="container">
				<div className="row">
					<div className="col-lg-8">
						<div className="mt-md mb-md">
							<h2 className="color-primary">{t("EMPLOYEE.FILE.TITLE")}</h2>

							{parseHTML(EmployeeStore.user.user_questionnaire?.questionnaire?.information)}

							{EmployeeStore.user.pob && (
								<>
									<Button primary link small iconRight="fa-long-arrow-right" onClick={toggle}>
										{t("EMPLOYEE.FILE.INFORMATION_CONTACT")}
									</Button>

									<Modal
										title={t("EMPLOYEE.FILE.INFORMATION_CONTACT")}
										isVisible={isVisible}
										hide={toggle}
									>
										<Advisor card advisor={EmployeeStore.user.pob} />
									</Modal>
								</>
							)}
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-8">
						{isCompleted && (
							<>
								<QuestionnaireCompleted title={t("EMPLOYEE.FILE.COMPLETED.INFORMATION")} />

								<div className={styles.IntroductionDescription}>
									{t("EMPLOYEE.FILE.COMPLETED.INFORMATION_DESCRIPTION")}{" "}
									<a onClick={toggle}>{t("EMPLOYEE.FILE.COMPLETED.INFORMATION_POB")}</a>
								</div>
							</>
						)}

						<Questionnaire
							id={EmployeeStore.user.user_questionnaire.id}
							onCompletion={() => onCompletion()}
							block
							expire
						/>
					</div>
				</div>
			</div>
		);
	}

	return <></>;
});

const EmployeePage = () => {
	const { EmployeeStore } = usePNIStores();
	const [hasLoaded, setHasLoaded] = useState(true);

	useEffect(() => {
		const cleanup = () => {
			QuestionnaireTokenService.logout();
		};

		window.addEventListener("beforeunload", cleanup);

		return () => {
			cleanup();
			window.removeEventListener("beforeunload", cleanup);
		};
	}, []);

	useEffect(() => {
		// Check if a token is already set, if so check if token is correct and load user data...
		if (QuestionnaireTokenService.getToken() && !EmployeeStore.user) {
			setHasLoaded(false);

			(async () => {
				try {
					await EmployeeStore.load();
					setHasLoaded(true);
				} catch (e) {
					QuestionnaireTokenService.logout();
					setHasLoaded(true);
				}
			})();
		}
	}, [EmployeeStore, EmployeeStore.user]);

	return (
		<div className={styles.Wrapper}>
			<Header />

			{hasLoaded && (
				<>
					{EmployeeStore.user && <EmployeePageQuestionnaire />}
					{!EmployeeStore.user && <EmployeePageIntroduction />}
				</>
			)}

			<Footer />
		</div>
	);
};

export default observer(EmployeePage);
